import React, { useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';

type ProtectedRouteProps = {
    children?: React.ReactNode;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!token) {
        navigate('/login');
    }

    return children;
};
