import { ContentText } from '@fortum/elemental-ui';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment/moment';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Map } from 'src/api/model/Map';
import { Graph } from 'src/api/model/Graph';

import styles from './NetworkStatusGoogleMap.module.scss';

type NetworkStatusGoogleMapProps = {
    selectedMap: 'network' | 'dsr';
    mapData: Map;
    graph: Graph;
};

export const NetworkStatusGoogleMap: FunctionComponent<NetworkStatusGoogleMapProps> = (
    props: NetworkStatusGoogleMapProps,
) => {
    const { mapData } = props;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC6lGncfKUecfNRYVO6UDtd_5-N8iDV_5c', // TODO api key to change
    });

    const getDataTime = () => {
        const timeFromM = moment(mapData.dateTimeRange.from);
        const timeToM = moment(mapData.dateTimeRange.to);
        const timeFrom = timeFromM.isValid() ? timeFromM.format('HH:mm') : '--:--';
        const timeTo = timeToM.isValid() ? timeToM.format('HH:mm') : '--:--';
        return timeFrom === timeTo ? timeTo : `między ${timeFrom} a ${timeTo}`;
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <div className={styles.NetworkStatusGoogleMap__container}>
                <GMap {...props} />
            </div>
            <ContentText className={styles.dataTime}>{`Dane z godziny ${getDataTime()}`}</ContentText>
        </>
    );
};

const GMap: FunctionComponent<NetworkStatusGoogleMapProps> = (props: NetworkStatusGoogleMapProps) => {
    const { mapData } = props;
    const mapObject = useRef<GoogleMap>(null);
    const [active, setActive] = useState([false, false, false, false, false, false, false]);
    const [zoom, setZoom] = useState(12);
    // const networkCoordinates = [
    //     { lat: 50.85, lng: 19.05 },
    //     { lat: 50.84, lng: 19.0555 },
    //     { lat: 50.83, lng: 19.1 },
    //     { lat: 50.79, lng: 19.15 },
    // ];

    const markers = [
        { substationId: 908173, position: { lat: 50.8370114887217, lng: 19.11265360037996 } },
        { substationId: 908001, position: { lat: 50.83848766214435, lng: 19.139184413873082 } },
        { substationId: 908296, position: { lat: 50.81156419945653, lng: 19.110017775443836 } },
        { substationId: 908573, position: { lat: 50.82558819249526, lng: 19.080225540858862 } },
        { substationId: 908842, position: { lat: 50.77840690140358, lng: 19.148178709292328 } },
        { substationId: 908380, position: { lat: 50.80656589943624, lng: 19.111297083187022 } },
        { substationId: 908461, position: { lat: 50.79917830088134, lng: 19.10679744270775 } },
    ];

    const handleOpenInfoWindow = (index: number) => {
        const activeCopy = [...active];
        activeCopy[index] = true;
        setActive(activeCopy);
    };

    const handleCloseInfoWindow = (index: number) => {
        const activeCopy = [...active];
        activeCopy[index] = false;
        setActive(activeCopy);
    };

    const handleOnLoad = () => {
        setActive([true, false, true, true, true, false, true]);
    };

    const handleOnZoomChange = () => {
        const currentZoom = mapObject.current?.state.map?.getZoom();
        if (currentZoom === 14 && zoom === 13) {
            setActive([true, true, true, true, true, true, true]);
        } else if (currentZoom === 13 && zoom === 14) {
            setActive([true, false, true, true, true, false, true]);
        }

        if (currentZoom) {
            setZoom(currentZoom);
        }
    };

    const renderInfoWindow = (substationId: number, index: number) => {
        const numberFormating = new Intl.NumberFormat('pl-PL', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        const pointData = mapData.dashboardMapData.find((point) => parseInt(point.substationId) === substationId);
        const readouts = pointData?.readouts.map((val, readoutIndex, array) => {
            if (!array.every((readout) => readout.value === null) && readoutIndex < 2) {
                const val2 = array[readoutIndex + 2];
                return (
                    <div key={`${index}-${!readoutIndex ? 'zas' : 'pow'}-`}>
                        <span
                            className={
                                !readoutIndex ? styles.NetworkStatusMapReadoutPzas : styles.NetworkStatusMapReadoutPpow
                            }
                        >{`${val.value ? numberFormating.format(Math.round(val.value * 10) / 10) : '--'} ${
                            val.unit
                        } `}</span>
                        <span
                            className={
                                !readoutIndex ? styles.NetworkStatusMapReadoutTzas : styles.NetworkStatusMapReadoutTpow
                            }
                        >{`${val2.value ? numberFormating.format(Math.round(val2.value * 10) / 10) : '--'} ${
                            val2.unit
                        }`}</span>
                    </div>
                );
            }
            return null;
        });
        if (readouts && !readouts.every((readout) => readout === null) && active[index]) {
            return (
                <InfoWindow onCloseClick={() => handleCloseInfoWindow(index)}>
                    <div>{readouts}</div>
                </InfoWindow>
            );
        }
        return null;
    };

    return (
        <GoogleMap
            ref={mapObject}
            options={{
                fullscreenControl: true,
                mapTypeControl: false,
                restriction: {
                    latLngBounds: {
                        north: 50.903071,
                        south: 50.711755,
                        west: 18.941238,
                        east: 19.261483,
                    },
                    strictBounds: false,
                },
                streetViewControl: false,
                styles: [
                    {
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e0f4e5',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.icon',
                        stylers: [
                            {
                                visibility: 'off',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#616161',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [
                            {
                                color: '#f5f5f5',
                            },
                        ],
                    },
                    {
                        featureType: 'administrative.land_parcel',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#bdbdbd',
                            },
                        ],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#eeeeee',
                            },
                        ],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#757575',
                            },
                        ],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e5e5e5',
                            },
                        ],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                        ],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#757575',
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#dadada',
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#616161',
                            },
                        ],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                    {
                        featureType: 'transit.line',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e5e5e5',
                            },
                        ],
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#eeeeee',
                            },
                        ],
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#c9c9c9',
                            },
                        ],
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                ],
            }}
            zoom={12}
            center={{ lat: 50.805, lng: 19.1 }}
            mapContainerStyle={{ width: '100%', height: 456 }}
            mapTypeId={google.maps.MapTypeId.ROADMAP}
            onZoomChanged={handleOnZoomChange}
        >
            {/*<Polyline path={networkCoordinates} options={{ strokeColor: '#38796B', strokeWeight: 1 }} />*/}
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.position}
                    icon={{
                        path: 'M 0, 6 a 6,6 0 1,1 12,0 a 6,6 0 1,1 -12,0',
                        fillColor: '#B83E4D',
                        fillOpacity: 1,
                        strokeWeight: 0,
                    }}
                    onClick={() => handleOpenInfoWindow(index)}
                    onLoad={() => handleOnLoad()}
                >
                    {renderInfoWindow(marker.substationId, index)}
                </Marker>
            ))}
        </GoogleMap>
    );
};
