import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Toast } from './Toast.types';

const initialState: Toast = {
    toastText: null,
    toastLinkCopy: null,
    toastLinkTarget: null,
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        handleToast(state, action: PayloadAction<Toast>) {
            state.toastText = action.payload.toastText;
            state.toastLinkCopy = action.payload.toastLinkCopy;
            state.toastLinkTarget = action.payload.toastLinkTarget;
        },
        clearToast(state) {
            state.toastText = null;
            state.toastLinkCopy = null;
            state.toastLinkTarget = null;
        },
    },
});

export const { handleToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;
