import { createSlice } from '@reduxjs/toolkit';

import { fetchProcedureById } from './ProcedureDetailsModal.thunk';
import { ProcedureDetails } from './ProcedureDetailsModal.types';

const initialState: ProcedureDetails = {
    details: null,
    loading: false,
};

const procedureDetailsSlice = createSlice({
    name: 'procedureDetails',
    initialState,
    reducers: {
        clearDetails(state) {
            state.loading = false;
            state.details = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProcedureById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProcedureById.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.details = action.payload;
            }
        });
    },
});

export const { clearDetails } = procedureDetailsSlice.actions;
export default procedureDetailsSlice.reducer;
