import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/providers/store';
import { useNavigate } from 'react-router-dom';
import { logoutPath } from 'src/auth';
import { clearAuthToken } from 'src/providers/auth';
import { clearUserInfo } from 'src/providers/user';

export const LoginPage = () => {
    return <div>login page</div>;
};

export const LogoutPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    console.log(window.location.pathname);
    console.log(logoutPath)

    useEffect(() => {
        if (window.location.pathname === logoutPath) {
            console.log('Logging out');
            dispatch(clearAuthToken());
            dispatch(clearUserInfo());
            localStorage.clear();
            navigate('/unauthorized');
        }
    }, []);

    return <div>logout page</div>;
}
