import { createSlice } from '@reduxjs/toolkit';

import { ProceduresCatalog, GroupNames, Foreca } from './ProceduresManagement.types';
import { fetchProceduresCatalog, fetchGroupNames, fetchForeca } from './ProceduresManagement.thunk';

const proceduresCatalogInitialState: ProceduresCatalog = {
    procedureCatalogList: [],
    loading: false,
};

const groupNamesInitialState: GroupNames = {
    groupNamesList: [],
    loading: false,
};

const forecaInitialState: Foreca = {
    forecaList: [],
    loading: false,
};

const proceduresCatalogSlice = createSlice({
    name: 'proceduresCatalog',
    initialState: proceduresCatalogInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProceduresCatalog.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProceduresCatalog.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.procedureCatalogList = action.payload.catalogProcedures;
            }
        });
    },
});

const groupNamesSlice = createSlice({
    name: 'groupNames',
    initialState: groupNamesInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGroupNames.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchGroupNames.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.groupNamesList = action.payload;
            }
        });
    },
});

const forecaSlice = createSlice({
    name: 'foreca',
    initialState: forecaInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchForeca.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchForeca.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.forecaList = action.payload;
            }
        });
    },
});

const proceduresCatalogReducer = proceduresCatalogSlice.reducer;
const groupNamesReducer = groupNamesSlice.reducer;
const forecaReducer = forecaSlice.reducer;

export { proceduresCatalogReducer, groupNamesReducer, forecaReducer };
