import makeRequest from 'src/api/axiosCore';

import {
    Procedure,
    ProcedureBody,
    ProcedureCatalogBody,
    ProcedureCatalogUpdateBody,
    UpdateResponseBody,
} from './model/Procedure';
import { Graph, ForecaParam } from './model/Graph';
import { Map } from './model/Map';
import { HeatProduction, HeatProductionPlanUpdateBody } from './model/HeatProduction';
import { ProceduresResponse, ProceduresCatalogResponse } from './model/ProceduresResponse';
import { Weather } from './model/Weather';
import { UserInfo } from './model/UserInfo';

export const getProcedures = (startDate?: string): Promise<ProceduresResponse> =>
    makeRequest({
        method: 'get',
        url: '/procedures',
        params: startDate ? { startDate } : undefined,
    });

export const getProcedureById = (id?: number): Promise<Procedure> =>
    makeRequest({
        method: 'get',
        url: `/procedures/${id}`,
    });

export const acceptProcedure = (id: number): Promise<void> =>
    makeRequest({
        method: 'post',
        url: `/procedures/${id}/action/accept`,
        params: { id },
    });

export const rejectProcedure = (id: number, message: string): Promise<void> =>
    makeRequest({
        method: 'post',
        url: `/procedures/${id}/action/reject`,
        params: { id },
        data: {
            actionMessage: message,
        },
    });

export const terminateProcedure = (id: number, message: string): Promise<void> =>
    makeRequest({
        method: 'post',
        url: `/procedures/${id}/action/terminate`,
        params: { id },
        data: {
            actionMessage: message,
        },
    });

export const createProcedure = (body: ProcedureBody): Promise<UpdateResponseBody> =>
    makeRequest({
        method: 'post',
        url: 'procedures',
        data: body,
    });

export const updateProcedure = (id: number, body: ProcedureBody): Promise<UpdateResponseBody> =>
    makeRequest({
        method: 'put',
        url: `/procedures/${id}`,
        data: body,
    });

export const getProcedureCatalog = (): Promise<ProceduresCatalogResponse> =>
    makeRequest({
        method: 'get',
        url: '/procedure-catalog',
    });

export const createCatalogProcedure = (body: ProcedureCatalogBody): Promise<UpdateResponseBody> =>
    makeRequest({
        method: 'post',
        url: '/procedure-catalog',
        data: body,
    });

export const updateCatalogProcedure = (id: number, body: ProcedureCatalogUpdateBody): Promise<UpdateResponseBody> =>
    makeRequest({
        method: 'put',
        url: `/procedure-catalog/${id}`,
        data: body,
    });

export const getGroupNames = (): Promise<string[]> =>
    makeRequest({
        method: 'get',
        url: '/group-name/dsr',
    });

export const getDashboardGraph = (): Promise<Graph> =>
    makeRequest({
        method: 'get',
        url: '/dashboard/graph',
    });

export const getDashboardMap = (): Promise<Map> =>
    makeRequest({
        method: 'get',
        url: '/dashboard/map',
    });

export const getForeca = (): Promise<ForecaParam[]> =>
    makeRequest({
        method: 'get',
        url: '/external-parameters/foreca',
    });

export const getHeatProduction = (startDate?: string): Promise<HeatProduction[]> =>
    makeRequest({
        method: 'get',
        url: '/heat-production',
        params: startDate ? { startDate } : undefined,
    });

export const downloadHeatProduction = (startDate: string): Promise<BlobPart> =>
    makeRequest({
        method: 'get',
        url: '/heat-production/export',
        params: { startDate },
        responseType: 'arraybuffer',
    });

export const getWeather = (startDate?: string): Promise<Weather[]> =>
    makeRequest({
        method: 'get',
        url: '/weather',
        params: startDate ? { startDate } : undefined,
    });

export const downloadWeather = (startDate: string): Promise<BlobPart> =>
    makeRequest({
        method: 'get',
        url: '/weather/export',
        params: { startDate },
        responseType: 'arraybuffer',
    });

export const updateHeatProductionPlanValue = (body: HeatProductionPlanUpdateBody[]): Promise<void> =>
    makeRequest({
        method: 'post',
        url: '/heat-production/plan',
        data: body,
    });

export const updateHeatProductionSupplyPressure = (body: HeatProductionPlanUpdateBody): Promise<void> =>
    makeRequest({
        method: 'post',
        url: '/heat-production/supply-pressure',
        data: body,
    });

export const updateHeatProductionReturnPressure = (body: HeatProductionPlanUpdateBody): Promise<void> =>
    makeRequest({
        method: 'post',
        url: '/heat-production/return-pressure',
        data: body,
    });

export const getUserInfo = (): Promise<UserInfo> =>
    makeRequest({
        method: 'get',
        url: '/user/info',
    });
