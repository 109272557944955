import React, { FunctionComponent } from 'react';
import { ConnectDropTarget, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { IconCross, IconEdit, IconSave } from '@fortum/elemental-ui';

import { handleProcedureAdd } from 'src/containers/ProceduresManagement/ProceduresManagement.thunk';
import { AppDispatch } from 'src/providers/store';

import { Procedures } from 'src/containers/ProceduresList/ProceduresList.types';
import { Foreca } from 'src/containers/ProceduresManagement/ProceduresManagement.types';
import { DropItem } from 'src/components/DSRInputTable/DSRInputTable.types';
import { Procedure, ProcedureBody } from 'src/api/model/Procedure';
import { ForecaParam, ForecaParamValue } from 'src/api/model/Graph';
import { ForecaIcon } from 'src/components/ForecaIcon/ForecaIcon';

import styles from './DSRInputTable.module.scss';

type DSRInputTableProps = {
    date: string;
    procedures: Procedures;
    foreca: Foreca;
    tempProcedure: ProcedureBody;
    handleEdit: (id: number) => void;
    onTempProcedureRemove: () => void;
};

type CollectedDropProps = {
    isOver: boolean;
    canDrop: boolean;
};

function timeToY(hourHeight: number, time?: string): number {
    const ratio = hourHeight / (60 * 60 * 1000);
    const momentTime = time ? moment(time, 'YYYY-MM-DD HH:mm') : moment();
    return (momentTime.toDate().getTime() - moment().startOf('day').toDate().getTime()) * ratio;
}

function procedureStyleClass(procedure: Procedure) {
    switch (procedure.status) {
        case 'PLANNED':
            return styles.DSROverviewTableGraphProcedureItemPlanned;
        case 'COMPLETED':
            return styles.DSROverviewTableGraphProcedureItemCompleted;
        case 'RUNNING':
            return styles.DSROverviewTableGraphProcedureItemToBeRunning;
        case 'TO_BE_ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemToBeAccepted;
        case 'ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemAccepted;
        case 'ERROR':
            return styles.DSROverviewTableGraphProcedureItemError;
        case 'REJECTED':
            return styles.DSROverviewTableGraphProcedureItemRejected;
        case 'TERMINATED':
            return styles.DSROverviewTableGraphProcedureItemTerminated;
        default:
            return styles.DSROverviewTableGraphProcedureItemUndefined;
    }
}

function dateToY(hourHeight: number) {
    const ratio = hourHeight / (60 * 60 * 1000);
    return (moment().toDate().getTime() - moment().startOf('day').toDate().getTime()) * ratio + 48;
}

export const DSRInputTable: FunctionComponent<DSRInputTableProps> = ({
    date,
    procedures,
    foreca,
    handleEdit,
    tempProcedure,
    onTempProcedureRemove,
}: DSRInputTableProps) => {
    const drop: ConnectDropTarget[] = [];
    const prop: CollectedDropProps[] = [];
    const tempProceduresManagement: JSX.Element[] = [];
    const proceduresManagement: JSX.Element[] = [];
    const width = 1026;
    const height = 624;
    const textOverId: string[] = [];
    const tempProcedureG = Object.keys(tempProcedure).length ? tempProcedure : null;
    const dispatch = useDispatch<AppDispatch>();
    const tempProcedureW = tempProcedureG ? [{ ...tempProcedureG, id: -1, status: 'PLANNED' }] : [];
    [...Array(24)].forEach((n, i) => {
        const d = useDrop(
            () => ({
                accept: 'procedure',
                drop: () => ({ startHour: i, date: moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') }),
                canDrop: (item: DropItem) => {
                    const currentDaySelected = moment(date, 'DD.MM.YYYY').isSame(moment(), 'day');
                    const manualSelectedDayProcedures = procedures.proceduresList.filter((procedure) => {
                        const proceduresFromTwoDays = currentDaySelected
                            ? procedure.startDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') ||
                              procedure.startDate === moment(date, 'DD.MM.YYYY').add(1, 'day').format('YYYY-MM-DD')
                            : procedure.startDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
                        return (
                            procedure.procedureType !== 'FRED' &&
                            procedure.status !== 'TERMINATED' &&
                            procedure.status !== 'REJECTED' &&
                            procedure.status !== 'ERROR' &&
                            proceduresFromTwoDays &&
                            ((procedure.status !== 'PLANNED' && procedure.status !== 'TO_BE_ACCEPTED') ||
                                ((procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                                    moment().isBefore(
                                        moment(
                                            `${procedure.startDate} ${procedure.startTime}`,
                                            'YYYY-MM-DD HH:mm',
                                        ).subtract(1, 'hours'),
                                    )))
                        );
                    });
                    const durationFromMidnight = moment.duration(moment().diff(moment().startOf('day')));
                    if (manualSelectedDayProcedures.length) {
                        return manualSelectedDayProcedures.every((procedure) => {
                            return (
                                i !== 0 &&
                                (moment(date, 'DD.MM.YYYY')
                                    .set({ hour: i + item.procedureDuration + 1, minute: 0, second: 0, millisecond: 0 })
                                    .isSameOrBefore(moment(`${procedure.startDate} ${procedure.startTime}`)) ||
                                    (moment(date, 'DD.MM.YYYY')
                                        .set({ hour: i - 1, minute: 0, second: 0, millisecond: 0 })
                                        .isSameOrAfter(moment(`${procedure.endDate} ${procedure.endTime}`)) &&
                                        (item.procedureDuration <= 23.75 - i || item.procedureType === 'EMERGENCY'))) &&
                                (!currentDaySelected ||
                                    (currentDaySelected && Math.ceil(durationFromMidnight.asHours()) < i))
                            );
                        });
                    }
                    return (
                        i !== 0 &&
                        (item.procedureDuration <= 23.75 - i || item.procedureType === 'EMERGENCY') &&
                        (!currentDaySelected || (currentDaySelected && Math.ceil(durationFromMidnight.asHours()) < i))
                    );
                },
                collect: (monitor) => ({
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                }),
            }),
            [date, procedures],
        );
        prop.push(d[0]);
        drop.push(d[1]);
    });

    const removeTemporaryProcedure = () => {
        onTempProcedureRemove();
    };

    const saveProcedure = () => {
        onTempProcedureRemove();
        dispatch(handleProcedureAdd(tempProcedure));
    };

    const renderProcedures = (
        procedures: Procedure[] | ProcedureBody[],
        xPos: number,
        width: number,
        manual: boolean,
    ) => {
        // eslint-disable-next-line react/prop-types
        return procedures
            .filter(
                (procedure: Procedure | ProcedureBody) =>
                    ('status' in procedure &&
                        procedure.status !== 'PLANNED' &&
                        procedure.status !== 'TO_BE_ACCEPTED') ||
                    ('status' in procedure &&
                        (procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                        moment().isBefore(
                            moment(`${procedure.startDate} ${procedure.startTime}`, 'YYYY-MM-DD HH:mm').subtract(
                                1,
                                'hours',
                            ),
                        )) ||
                    (!(status in procedure) &&
                        moment().isBefore(
                            moment(`${procedure.startDate} ${procedure.startTime}`, 'YYYY-MM-DD HH:mm').subtract(
                                1,
                                'hours',
                            ),
                        )),
            )
            .map((procedure: Procedure | ProcedureBody, index: number) => {
                const startTemp = moment(`${procedure.startDate} ${procedure.startTime}`, 'YYYY-MM-DD HH:mm');
                const startOfSelectedDay = moment(`${date} 00:00:00`, 'DD.MM.YYYY HH:mm:ss');
                const start = startTemp.isBefore(startOfSelectedDay) ? startOfSelectedDay : startTemp;
                const endOfStartDay = start.clone().endOf('day').add(1, 'millisecond');
                const endTemp = moment(`${procedure.endDate} ${procedure.endTime}`, 'YYYY-MM-DD HH:mm');
                const end = endTemp.isAfter(endOfStartDay) ? endOfStartDay : endTemp;
                const y = start.hour() * 24 + 48;
                const duration = moment.duration(end.diff(start)).asHours();
                const height = duration * 24;
                const groupLength = procedure.groups.length;
                const lastGroupItems = procedure.groups[groupLength - 1].items;
                let firstChangeY: number;
                const editableProcedure =
                    manual &&
                    (('status' in procedure && procedure.status !== 'TO_BE_ACCEPTED') || !('status' in procedure)) &&
                    (moment().isBefore(
                        moment(`${procedure.startDate} ${procedure.startTime}`, 'YYYY-MM-DD HH:mm').subtract(
                            1,
                            'hours',
                        ),
                    ) ||
                        ('status' in procedure &&
                            procedure.status === 'RUNNING' &&
                            procedure.groups.every((group) =>
                                group.items
                                    .filter((item) => item.controlCode === 0)
                                    .every(
                                        (item) =>
                                            item.status === 'COMPLETED' &&
                                            moment().isBefore(
                                                moment(`${item.endDate} ${item.endTime}`, 'YYYY-MM-DD HH:mm').subtract(
                                                    1,
                                                    'hours',
                                                ),
                                            ),
                                    ),
                            )) ||
                        ('status' in procedure &&
                            procedure.status === 'COMPLETED' &&
                            procedure.procedureType === 'EMERGENCY' &&
                            procedure.groups.every((group) =>
                                group.items.every((item) =>
                                    moment().isBefore(
                                        moment(`${item.endDate} ${item.endTime}`, 'YYYY-MM-DD HH:mm').subtract(
                                            1,
                                            'hours',
                                        ),
                                    ),
                                ),
                            )));
                const editableTempProcedure = editableProcedure && !('procedureChanges' in procedure);
                const editableManualProcedure = editableProcedure && 'procedureChanges' in procedure;
                const completedItems =
                    'procedureChanges' in procedure &&
                    procedure.groups
                        .flatMap((group) => group.items)
                        .filter((item) => item.status === 'COMPLETED')
                        .map((item) => {
                            const start = timeToY(24, `${item.startDate} ${item.startTime}`) + 48;
                            const end = timeToY(24, `${item.endDate} ${item.endTime}`) + 48;
                            const height = end - start;
                            return {
                                start,
                                height,
                            };
                        });
                if (editableTempProcedure) {
                    tempProceduresManagement.push(
                        <g key={`temp-management-${index}`}>
                            <rect x={xPos + width - 78} y={y - 22} width={78} height={22} fill="#999999" />
                            <foreignObject x={xPos + width - 78 + 3} y={y - 22 + 1} width={24} height={22}>
                                <IconSave size={20} />
                            </foreignObject>
                            <foreignObject x={xPos + width - 54 + 3} y={y - 22 + 1} width={24} height={22}>
                                <IconEdit size={20} />
                            </foreignObject>
                            <foreignObject x={xPos + width - 30 + 3} y={y - 22 + 1} width={24} height={22}>
                                <IconCross size={20} />
                            </foreignObject>
                            <rect
                                x={xPos + width - 78 + 3}
                                y={y - 22 + 1}
                                width={24}
                                height={22}
                                id="tempSave"
                                fill="transparent"
                                onClick={saveProcedure}
                            />
                            <rect
                                x={xPos + width - 54 + 3}
                                y={y - 22 + 1}
                                width={24}
                                height={22}
                                id="tempEdit"
                                fill="transparent"
                                onClick={() => handleEdit(-1)}
                            />
                            <rect
                                x={xPos + width - 30 + 3}
                                y={y - 22 + 1}
                                width={24}
                                height={22}
                                id="tempRemove"
                                fill="transparent"
                                onClick={removeTemporaryProcedure}
                            />
                        </g>,
                    );
                }
                if (editableManualProcedure) {
                    proceduresManagement.push(
                        <g key={`management-${index}-${procedure.procedureType}`}>
                            <rect x={xPos + width - 30} y={y - 22} width={30} height={22} fill="#999999" />
                            <foreignObject
                                x={xPos + width - 30 + 3}
                                y={y - 22 + 1}
                                width={24}
                                height={22}
                                onClick={() => {
                                    handleEdit('id' in procedure ? procedure.id : -1);
                                }}
                            >
                                <IconEdit size={20} />
                            </foreignObject>
                        </g>,
                    );
                }
                return (
                    <g key={`Procedure${'id' in procedure ? procedure.id : -1}`}>
                        <rect
                            x={xPos}
                            y={y}
                            width={width}
                            height={height}
                            className={
                                'id' in procedure
                                    ? procedureStyleClass(procedure)
                                    : styles.DSROverviewTableGraphProcedureItemPlanned
                            }
                        ></rect>
                        {procedure.procedureType === 'RESET' && (
                            <text
                                className={styles.DSRDetailsTableGraphItemControlCode}
                                id={`controlCode${manual ? 'M' : 'F'}${index}reset`}
                                x={xPos + 110}
                                y={y + height / 2}
                                dominantBaseline="middle"
                                textAnchor="middle"
                            >
                                {'DSR 0'}
                            </text>
                        )}
                        {completedItems &&
                            completedItems.map((item, index) => (
                                <rect
                                    x={xPos}
                                    y={item.start}
                                    width={width}
                                    height={item.height}
                                    className={styles.DSROverviewTableGraphProcedureItemCompleted}
                                    key={`${'id' in procedure && procedure.id}-${index}`}
                                ></rect>
                            ))}
                        {'procedureChanges' in procedure &&
                            procedure.procedureChanges.map((change, changeIndex) => {
                                const changeStartTemp = moment(
                                    `${change.startDate} ${change.startTime}`,
                                    'YYYY-MM-DD HH:mm',
                                );
                                const changeStart = changeStartTemp.isBefore(startOfSelectedDay)
                                    ? startOfSelectedDay
                                    : changeStartTemp;
                                const changeEndTemp = moment(`${change.endDate} ${change.endTime}`, 'YYYY-MM-DD HH:mm');
                                const changeEnd = changeEndTemp.isAfter(endOfStartDay) ? endOfStartDay : changeEndTemp;
                                const changeY = changeStart.hour() * 24 + 48;
                                const changeDuration = moment.duration(changeEnd.diff(changeStart)).asHours();
                                const changeHeight = changeDuration * 24;
                                if (changeIndex === 0) firstChangeY = changeY + changeHeight / 2;
                                textOverId.push(`controlCode${manual ? 'M' : 'F'}${index}${changeIndex}`);
                                if (procedure.procedureChanges.length === 2 && changeIndex === 1) {
                                    textOverId.push(`controlCodeDSR0${manual ? 'M' : 'F'}${index}`);
                                }
                                return (
                                    <React.Fragment key={`ProcedureChange${procedure.id}-${changeIndex}`}>
                                        <rect
                                            className={
                                                change.controlCode > 0
                                                    ? styles.DSROverviewTableGraphProcedureChangePositive
                                                    : styles.DSROverviewTableGraphProcedureChangeNegative
                                            }
                                            x={xPos}
                                            y={changeY}
                                            width={24}
                                            height={changeHeight}
                                        />
                                        <text
                                            className={styles.DSRDetailsTableGraphItemControlCode}
                                            id={`controlCode${manual ? 'M' : 'F'}${index}${changeIndex}`}
                                            x={xPos + 110}
                                            y={changeY + changeHeight / 2}
                                            dominantBaseline="middle"
                                            textAnchor="middle"
                                        >
                                            {`DSR ${
                                                change.controlCode > 0 ? `+${change.controlCode}` : change.controlCode
                                            }`}
                                        </text>
                                        {procedure.procedureChanges.length === 2 && changeIndex === 1 && (
                                            <>
                                                <text
                                                    className={styles.DSRDetailsTableGraphItemControlCode}
                                                    id={`controlCodeDSR0${manual ? 'M' : 'F'}${index}`}
                                                    x={xPos + 110}
                                                    y={firstChangeY + (changeY + changeHeight / 2 - firstChangeY) / 2}
                                                    dominantBaseline="middle"
                                                    textAnchor="middle"
                                                >
                                                    {'DSR 0'}
                                                </text>
                                                {procedure.historyMessages.some(
                                                    (message) => message.messageType === 'ERROR',
                                                ) && (
                                                    <use
                                                        xlinkHref="#DSRInputTableGraphErrorSymbol"
                                                        x={xPos + 70}
                                                        y={
                                                            firstChangeY +
                                                            (changeY + changeHeight / 2 - firstChangeY) / 2 -
                                                            8
                                                        }
                                                        className={styles.DSRInputTableErrorIcon}
                                                    >
                                                        <title>
                                                            {procedure.historyMessages.map((message, index) => {
                                                                return (
                                                                    <React.Fragment key={`${procedure.id}-${index}`}>
                                                                        {message.messageType === 'ERROR' && (
                                                                            <>
                                                                                {message.text}
                                                                                {'\n'}
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </title>
                                                    </use>
                                                )}
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        {!('procedureChanges' in procedure) &&
                            procedure.groups[0].items.map((item, itemIndex) => {
                                const changeStartTemp = moment(
                                    `${item.startDate} ${item.startTime}`,
                                    'YYYY-MM-DD HH:mm',
                                );
                                const changeStart = changeStartTemp.isBefore(startOfSelectedDay)
                                    ? startOfSelectedDay
                                    : changeStartTemp;
                                const changeEndTemp = moment(
                                    `${lastGroupItems[itemIndex].endDate} ${lastGroupItems[itemIndex].endTime}`,
                                    'YYYY-MM-DD HH:mm',
                                );
                                const changeEnd = changeEndTemp.isAfter(endOfStartDay) ? endOfStartDay : changeEndTemp;
                                const changeY = changeStart.hour() * 24 + 48;
                                const changeDuration = moment.duration(changeEnd.diff(changeStart)).asHours();
                                const changeHeight = changeDuration * 24;
                                return (
                                    <React.Fragment key={`ProcedureChange${-1}-${itemIndex}`}>
                                        {item.controlCode !== 0 && (
                                            <rect
                                                className={
                                                    item.controlCode > 0
                                                        ? styles.DSROverviewTableGraphProcedureChangePositive
                                                        : styles.DSROverviewTableGraphProcedureChangeNegative
                                                }
                                                x={xPos}
                                                y={changeY}
                                                width={24}
                                                height={changeHeight}
                                            />
                                        )}
                                        <text
                                            className={styles.DSRDetailsTableGraphItemControlCode}
                                            id={`controlCode${manual ? 'M' : 'F'}${index}${itemIndex}`}
                                            x={xPos + 110}
                                            y={changeY + changeHeight / 2}
                                            dominantBaseline="middle"
                                            textAnchor="middle"
                                        >
                                            {`DSR ${item.controlCode > 0 ? `+${item.controlCode}` : item.controlCode}`}
                                        </text>
                                    </React.Fragment>
                                );
                            })}
                    </g>
                );
            });
    };

    const renderFREDProcedures = () => {
        // eslint-disable-next-line react/prop-types
        const fredSelectedDayProcedures = procedures.proceduresList.filter((item) => {
            return (
                item.procedureType === 'FRED' &&
                item.status !== 'REJECTED' &&
                item.startDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
            );
        });

        return renderProcedures(fredSelectedDayProcedures, 406, 220, true);
    };

    const renderManualProcedures = () => {
        // eslint-disable-next-line react/prop-types
        const manualSelectedDayProcedures = procedures.proceduresList.filter((item) => {
            return (
                item.procedureType !== 'FRED' &&
                item.status !== 'REJECTED' &&
                item.status !== 'TERMINATED' &&
                (item.startDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') ||
                    (item.procedureType === 'EMERGENCY' &&
                        item.endDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')))
            );
        });

        return renderProcedures(manualSelectedDayProcedures, 186, 220, true);
    };

    const renderTempProcedure = () => {
        if (tempProcedureW) {
            const filteredTempProcedure = tempProcedureW.filter((item) => {
                return (
                    item.startDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') ||
                    (item.procedureType === 'EMERGENCY' &&
                        item.endDate === moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'))
                );
            });
            return renderProcedures(filteredTempProcedure, 186, 220, true);
        }
        return null;
    };

    const renderTime = () => {
        const currentDateY = dateToY(24);
        return (
            <g>
                <line
                    className={styles.DSROverviewTableGraphTime}
                    x1={46}
                    y1={currentDateY}
                    x2={1026}
                    y2={currentDateY}
                />
                <rect
                    className={styles.DSROverviewTableGraphTimeBubble}
                    x={0}
                    y={currentDateY - 0.5 * 22}
                    width={38}
                    height={21}
                    rx={4}
                    ry={4}
                />
                <text
                    className={styles.DSROverviewTableGraphTimeText}
                    x={19}
                    y={currentDateY}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {moment().format('HH:mm')}
                </text>
            </g>
        );
    };

    const renderDropZone = () => {
        return [...Array(24)].map((n, i) => {
            const y = i * 23 + i + 48;
            const hourFrom = i > 9 ? i : `0${i}`;
            const hourTo = i + 1 > 9 ? (i === 23 ? '00' : i + 1) : `0${i + 1}`;
            const isActive = prop[i].canDrop && prop[i].isOver;
            let fill = 'transparent';
            if (isActive) {
                fill = '#F2F2F2';
            }
            return (
                <React.Fragment key={`DropZone${i}`}>
                    <rect style={{ fill }} x={46} y={y + 1} width={980} height={23} />
                    <rect
                        style={{ fill: 'rgba(242, 242, 242, .5)' }}
                        ref={drop[i]}
                        x={186}
                        y={y + 1}
                        width={220}
                        height={23}
                    />
                    <text
                        x={116}
                        y={y + 12}
                        className={styles.DSRInputTableGraphHour}
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {`${hourFrom}:00-${hourTo}:00`}
                    </text>
                </React.Fragment>
            );
        });
    };

    const renderLines = () => {
        return [...Array(24)].map((n, i) => {
            const y = i * 23 + i + 48;
            const lineY = y + 24;
            return (
                <line
                    key={`dRowLine${i}`}
                    className={styles.DSRInputTableGraphHorizontalLine}
                    x1={46}
                    y1={lineY}
                    x2={1026}
                    y2={lineY}
                />
            );
        });
    };

    const renderForecaData = () => {
        return foreca.forecaList.map((param: ForecaParam, paramIndex) => {
            const x = 696;
            const y = 60;
            return param.values
                .filter((value) => moment(value.dateTime).isSame(moment(date, 'DD.MM.YYYY'), 'day'))
                .map((value: ForecaParamValue, index) => {
                    return (
                        <React.Fragment key={`${param.parameterName}-text-${index}`}>
                            {!paramIndex && value.symbol && (
                                <foreignObject
                                    x={x - 33 + 135 * paramIndex}
                                    y={y - 10 + 24 * index}
                                    width={20}
                                    height={20}
                                    data-symbol={value.symbol}
                                >
                                    <ForecaIcon symbol={value.symbol} />
                                </foreignObject>
                            )}
                            <text
                                className={styles.DSRInputTableGraphHour}
                                x={x + 135 * paramIndex}
                                y={y + 24 * index}
                                dominantBaseline="middle"
                                textAnchor={paramIndex || !value.symbol ? 'middle' : 'start'}
                            >
                                {value.value} {param.unit}
                            </text>
                        </React.Fragment>
                    );
                });
        });
    };

    return (
        <svg className={styles.ProcedureGraph} viewBox={`0 0 ${width} ${height}`} height={height}>
            <symbol id="DSRInputTableGraphErrorSymbol" width="14" height="14" viewBox="-1 -1 16 16">
                <circle cx="7" cy="7" r="8" fill="#FFFFFF" />
                <circle cx="7" cy="7" r="7" fill="#C9C9C9" />
                <path
                    d="M6.96883 0.332031C3.31016 0.332031 0.333496 3.3227 0.333496 6.9987C0.333496 10.6747 3.32416 13.6654 7.00016 13.6654C10.6762 13.6654 13.6668 10.6747 13.6668 6.9987C13.6668 3.3227 10.6622 0.332031 6.96883 0.332031ZM7.66683 10.332H6.3335V8.9987H7.66683V10.332ZM7.66683 7.66536H6.3335V3.66536H7.66683V7.66536Z"
                    fill="#B83E4D"
                />
            </symbol>
            <rect className={styles.DSRInputTableGraphHeadBackground} x={46} y={0} width={980} height={48} />
            <text
                className={styles.DSRInputTableGraphLabel}
                x={116}
                y={24}
                dominantBaseline="middle"
                textAnchor="middle"
            >
                Godzina
            </text>
            <text
                className={styles.DSRInputTableGraphLabel}
                x={296}
                y={24}
                dominantBaseline="middle"
                textAnchor="middle"
            >
                Procedura DSR
            </text>
            <text className={styles.DSRInputTableGraphLabel} y={21}>
                <tspan x={516} textAnchor="middle" dy={0}>
                    {'Procedura DSR'}
                </tspan>
                <tspan x={516} textAnchor="middle" dy={'1.2em'}>
                    {'(FRED)'}
                </tspan>
            </text>
            <text className={styles.DSRInputTableGraphLabel} y={21}>
                <tspan x={696} textAnchor="middle" dy={0}>
                    {'Temperatura powietrza'}
                </tspan>
                <tspan x={696} textAnchor="middle" dy={'1.2em'}>
                    {'prognozowana'}
                </tspan>
            </text>
            <text
                className={styles.DSRInputTableGraphLabel}
                x={831}
                y={24}
                dominantBaseline="middle"
                textAnchor="middle"
            >
                Prędkość wiatru
            </text>
            <text
                className={styles.DSRInputTableGraphLabel}
                x={961}
                y={24}
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {`Wysokość opadu`}
            </text>
            <g id="renderTempProcedure">{renderTempProcedure()}</g>
            <g id="renderDropZone">{renderDropZone()}</g>
            <g id="renderFREDProcedures">{renderFREDProcedures()}</g>
            <g id="renderManualProcedures">{renderManualProcedures()}</g>
            <g id="renderLines">{renderLines()}</g>
            <g id="renderForecaData">{renderForecaData()}</g>
            {tempProceduresManagement.map((value) => value)}
            {proceduresManagement.map((value) => value)}
            {textOverId.map((id, index) => (
                <use key={`use${index}`} xlinkHref={`#${id}`} />
            ))}
            {moment(date, 'DD.MM.YYYY').day() === moment().day() && renderTime()}
        </svg>
    );
};
