import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { colors, Popover, PopoverControl, spacing, usePopover } from '@fortum/elemental-ui';

import { AppDispatch } from 'src/providers/store';
import { ForecaIcon } from 'src/components/ForecaIcon/ForecaIcon';
import { VariationIcon } from 'src/components/VariationIcon/VariationIcon';
import { ReactComponent as CheckIcon } from 'src/assets/checkIcon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/infoIcon.svg';
import { HeatProduction } from 'src/api/model/HeatProduction';
import { Procedure } from 'src/api/model/Procedure';
import {
    handleHeatProductionPlanValueUpdate,
    handleHeatProductionSupplyPressureUpdate,
    handleHeatProductionReturnPressureUpdate,
} from 'src/containers/HeatProductionPlansContainer/HeatProductionPlansContainer.thunk';

import { UserInfo, UserInfoState } from 'src/providers/user';

import styles from './HeatPlanTable.module.scss';

type EditableFieldProps = {
    children: string;
    unit: string;
    selected: boolean;
    editable?: boolean;
    edited?: boolean;
    handleMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
    handleEditSelected?: (value: string) => void;
    handleChange?: (value: string) => void;
};

type PressureFieldProps = {
    children: string;
    unit: string;
    editable?: boolean;
    edited: boolean;
    handleChange?: (value: string) => void;
};

type HeatPlanTableProps = {
    data: HeatProduction[];
};

const procedureStyleClass = (procedure: Procedure) => {
    switch (procedure.status) {
        case 'PLANNED':
            return styles.HPTProcedurePlanned;
        case 'COMPLETED':
            return styles.HPTProcedureCompleted;
        case 'RUNNING':
            return styles.HPTProcedureToBeRunning;
        case 'TO_BE_ACCEPTED':
            return styles.HPTProcedureToBeAccepted;
        case 'ACCEPTED':
            return styles.HPTProcedureAccepted;
        case 'ERROR':
            return styles.HPTProcedureError;
        case 'REJECTED':
            return styles.HPTProcedureRejected;
        case 'TERMINATED':
            return styles.HPTProcedureTerminated;
        default:
            return styles.HPTProcedureUndefined;
    }
};

const formatNumber = (number: number, fractionDigits: number) => {
    return new Intl.NumberFormat('pl-PL', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number);
};

const EditableField: FunctionComponent<EditableFieldProps> = ({
    children,
    unit,
    selected = false,
    editable = true,
    edited = false,
    handleChange,
    handleMouseDown,
    handleEditSelected,
}: EditableFieldProps) => {
    const [value, setValue] = useState<string>(children);

    useEffect(() => {
        setValue(children);
    }, [children]);

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const val = e.target.innerText;
        const reg = /^(\d*(,))?\d+$/;
        if (reg.test(val)) {
            if (val !== value) {
                handleEditSelected && handleEditSelected(val);
                handleChange && handleChange(val.replace(',', '.'));
            }
            setValue(`${val}${unit ? ` ${unit}` : ''}`);
        } else {
            e.target.innerText = value ? `${value}${unit ? ` ${unit}` : ''}` : '';
            setValue(e.target.innerText);
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        const val = unit ? e.target.innerText.replace(` ${unit}`, '') : e.target.innerText;
        setValue(val);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
        }
    };

    return (
        <>
            {editable ? (
                <div
                    className={`${styles.HPTDivContentEditable} ${selected ? styles.HPTDivSelected : ''} ${
                        edited ? styles.HPTDivEdited : ''
                    }`}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onMouseDown={handleMouseDown}
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                >
                    {value}
                </div>
            ) : (
                <div className={`${styles.HPTDivContentEditable} ${edited ? styles.HPTDivEdited : ''}`}>{value}</div>
            )}
        </>
    );
};

const PressureField: FunctionComponent<PressureFieldProps> = ({
    children,
    unit,
    editable,
    edited,
    handleChange,
}: PressureFieldProps) => {
    return (
        <EditableField unit={unit} editable={editable} edited={edited} handleChange={handleChange} selected={false}>
            {children}
        </EditableField>
    );
};

const HeatPlanTable: FunctionComponent<HeatPlanTableProps> = ({ data }: HeatPlanTableProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector<{ userInfo: UserInfoState }, UserInfo | null>((state) => state.userInfo.user);
    const hasCredential: boolean | null = user && (user.roles.includes('DYSPOZYTOR') || user.roles.includes('DIR'));
    const editedTempArray: string[] = [];
    const popOverArray: (PopoverControl | undefined)[] = [];
    data.forEach((day: HeatProduction) => {
        [...Array(24)].forEach((_n, index) => {
            const supTempPlanValueObj = day.supplyTemperaturePlan.values.find(
                (el) => moment(el.dateTime).hour() === index,
            );

            editedTempArray.push(
                supTempPlanValueObj && supTempPlanValueObj.value ? supTempPlanValueObj.value.toString() : '',
            );

            popOverArray.push(
                supTempPlanValueObj && supTempPlanValueObj.history && supTempPlanValueObj.history.length > 1
                    ? usePopover()
                    : undefined,
            );
        });
    });
    const [editedTemp, setEditedTemp] = useState<string[]>(editedTempArray);
    const [selected, setSelected] = useState<number[]>([]);
    const [ctrlDown, setCtrlDown] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('keyup', handleCtrlUp);
        window.addEventListener('keydown', handleCtrlDown);

        return () => {
            window.removeEventListener('keyup', handleCtrlUp);
            window.removeEventListener('keydown', handleCtrlDown);
        };
    }, []);

    const handleCtrlDown = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
            setCtrlDown(true);
        }
    };

    const handleCtrlUp = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
            setCtrlDown(false);
        }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>, position: number) => {
        if (e.currentTarget !== document.activeElement) {
            if (ctrlDown) {
                e.preventDefault();
                if (!document.activeElement?.className.includes('HeatPlanTable_HPTDivContentEditable')) {
                    e.currentTarget.focus();
                    if (!selected.includes(position)) {
                        setSelected([...selected, position]);
                    }
                } else {
                    if (selected.includes(position)) {
                        setSelected(selected.filter((val) => val !== position));
                    } else {
                        setSelected([...selected, position]);
                    }
                }
            } else {
                setSelected([position]);
            }
        }
    };

    const handleEditSelected = (value: string, day: string) => {
        const nextEditedTemp = editedTemp.map((currentValue, index) => {
            if (selected.includes(index)) {
                return value;
            } else {
                return currentValue;
            }
        });
        setSelected([]);
        setEditedTemp(nextEditedTemp);
        const body = selected.map((hour) => ({
            dateTime: moment(day)
                .add(hour % 24, 'hour')
                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            value: parseInt(value),
        }));
        dispatch(handleHeatProductionPlanValueUpdate({ body }));
    };

    const renderTime = () => {
        const time = moment();
        const hour = time.hours() + time.minutes() / 60;
        return (
            <div className={styles.HPTCurrentTimeContainer}>
                <div className={styles.HPTTimeLine} style={{ top: 24 * hour }}></div>
                <div className={styles.HPTTimeBox} style={{ top: 24 * hour - 10 }}>
                    {time.format('HH:mm')}
                </div>
                <div className={styles.HPTTimeBoxArrow} style={{ top: 24 * hour - 4 }}></div>
            </div>
        );
    };

    const createRenderData = (startDate: moment.Moment, endDate: moment.Moment, index: number) => {
        const indexDate = moment(data[0].date).add(index, 'hour');
        if (startDate.isSameOrBefore(indexDate) && endDate.isAfter(indexDate)) {
            const hoursToEnd = endDate.diff(indexDate, 'hour');
            const hoursToStart = indexDate.diff(startDate, 'hour') + 1;
            return {
                itemStyle:
                    hoursToEnd < 1
                        ? { height: `${hoursToEnd * 100}%` }
                        : hoursToStart < 1
                        ? { height: `${hoursToStart * 100}%`, bottom: 0 }
                        : { height: '100%' },
                wrapperStyle: hoursToStart < 1 ? { alignItems: 'flex-end' } : undefined,
            };
        }
        return null;
    };

    const bbb = (value: number, dateTime: string) => {
        const body = {
            dateTime,
            value,
        };
        dispatch(handleHeatProductionSupplyPressureUpdate({ body }));
    };

    const ccc = (value: number, dateTime: string) => {
        const body = {
            dateTime,
            value,
        };
        dispatch(handleHeatProductionReturnPressureUpdate({ body }));
    };

    const renderDSR = (procedures: Procedure[], index: number) => {
        return procedures
            .filter(
                (procedure) =>
                    (procedure.status !== 'PLANNED' &&
                        procedure.status !== 'TO_BE_ACCEPTED' &&
                        procedure.status !== 'TERMINATED' &&
                        procedure.status !== 'REJECTED') ||
                    ((procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                        moment().isBefore(
                            moment(`${procedure.startDate} ${procedure.startTime}`).subtract(1, 'hours'),
                        )),
            )
            .map((procedure) => {
                const completedItems = procedure.groups
                    .flatMap((group) => group.items)
                    .filter((item) => item.status === 'COMPLETED' && item.controlCode !== 0)
                    .map((item) => {
                        return {
                            start: moment(`${item.startDate} ${item.startTime}`),
                            end: moment(`${item.endDate} ${item.endTime}`),
                        };
                    });
                return procedure.procedureChanges.map((item) => {
                    const data = createRenderData(
                        moment(`${item.startDate} ${item.startTime}`),
                        moment(`${item.endDate} ${item.endTime}`),
                        index,
                    );
                    const completedItemsData = completedItems
                        .filter((item) => createRenderData(item.start, item.end, index) !== null)
                        .map((item) => createRenderData(item.start, item.end, index));
                    if (data) {
                        return (
                            <div
                                key={`${procedure.id}_${index}`}
                                className={styles.HPTProcedure}
                                style={data.wrapperStyle}
                            >
                                <div
                                    className={
                                        item.controlCode > 0 ? styles.HPTProcedurePlus : styles.HPTProcedureMinus
                                    }
                                    style={data.itemStyle}
                                />
                                <div
                                    className={`${styles.HPTProcedureHour} ${procedureStyleClass(procedure)}`}
                                    style={data.itemStyle}
                                >
                                    {completedItemsData.map((item, itemIndex) => (
                                        <div
                                            key={`${procedure.id}_${index}-${itemIndex}`}
                                            className={styles.HPTProcedureCompletedItem}
                                            style={item?.itemStyle}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    }
                    return null;
                });
            });
    };

    return (
        <div className={styles.HPT_container}>
            <table className={styles.HPT}>
                <thead>
                    <tr>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTCover}></div>
                            Godzina
                        </th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>PLAN</div>
                            <div>Temperatura powietrza prognozowana</div>
                            <div className={styles.HPTHeadAddDesc}>(Foreca)</div>
                        </th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>PLAN</div>
                            <div>Temperatura na zasilaniu do sieci DH</div>
                            <div className={styles.HPTHeadAddDesc}>(tab. regulacyjna)</div>
                        </th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>PLAN</div>
                            <div>Temperatura na zasilaniu do sieci DH</div>
                            <div className={styles.HPTHeadAddDesc}>(plan)</div>
                        </th>
                        <th className={styles.HPTColSmall}>Procedura DSR</th>
                        <th className={styles.HPTColSmall}>
                            <div>Procedura DSR</div>
                            <div className={styles.HPTHeadAddDesc}>(FRED)</div>
                        </th>
                        <th className={styles.HPTColSmall}>Praca kotłów szczytowych</th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>REALIZACJA</div>
                            <div>Temperatura powietrza zmierzona</div>
                            <div className={styles.HPTHeadAddDesc}>(Foreca)</div>
                        </th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>REALIZACJA</div>
                            <div>Temperatura na zasilaniu do sieci DH</div>
                            <div className={styles.HPTHeadAddDesc}>(CZE.20NDA10CT2)</div>
                        </th>
                        <th className={styles.HPTColMedium}>
                            <div className={styles.HPTHeadSource}>REALIZACJA</div>
                            <div>Temperatura na zasilaniu do sieci DH - odchylenie</div>
                        </th>
                        <th>Ciśnienie na zasileniu sieci DH</th>
                        <th>Ciśnienie na powrocie sieci DH</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((day: HeatProduction, dayIndex: number) => {
                        return (
                            <React.Fragment key={day.date}>
                                <tr className={styles.HPTDayHead}>
                                    <td>{moment(day.date).format('DD.MM.YYYY')}</td>
                                    <td>
                                        {day.forecaTemperatureForec.average
                                            ? `średnia ${(
                                                  Math.round(day.forecaTemperatureForec.average * 10) / 10
                                              ).toLocaleString('pl-PL')} ${day.forecaTemperatureForec.unit}`
                                            : ''}
                                    </td>
                                    <td>
                                        {day.supplyTemperatureForec.average
                                            ? `średnia ${Math.round(day.supplyTemperatureForec.average)} ${
                                                  day.supplyTemperatureForec.unit
                                              }`
                                            : ''}
                                    </td>
                                    <td>
                                        {day.supplyTemperaturePlan.average
                                            ? `średnia ${Math.round(day.supplyTemperaturePlan.average)} ${
                                                  day.supplyTemperaturePlan.unit
                                              }`
                                            : ''}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {day.forecaTemperatureActual.average
                                            ? `średnia ${(
                                                  Math.round(day.forecaTemperatureActual.average * 10) / 10
                                              ).toLocaleString('pl-PL')} ${day.forecaTemperatureActual.unit}`
                                            : ''}
                                    </td>
                                    <td>
                                        {day.supplyTemperatureActual.average
                                            ? `średnia ${Math.round(day.supplyTemperatureActual.average)} ${
                                                  day.supplyTemperatureActual.unit
                                              }`
                                            : ''}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                day.deviation.averageMarked ? styles.HPTVariationContainerMarked : ''
                                            }
                                        >
                                            {day.deviation.average
                                                ? `średnia ${(
                                                      Math.round(day.deviation.average * 10) / 10
                                                  ).toLocaleString('pl-PL')} ${day.deviation.unit}`
                                                : ''}
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {[...Array(24)].map((_n, index) => {
                                    const symbol =
                                        day.forecaTemperatureForec.values[index].symbol !== undefined
                                            ? day.forecaTemperatureForec.values[index].symbol
                                            : null;
                                    const tempForecValue = day.forecaTemperatureForec.values[index].value;
                                    const supTempForecValue = day.supplyTemperatureForec.values[index].value;
                                    const heatOnlyBoilerValueObj = day.heatOnlyBoiler.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const heatOnlyBoilerValue = heatOnlyBoilerValueObj
                                        ? heatOnlyBoilerValueObj.value
                                        : null;
                                    const tempActValueObj = day.forecaTemperatureActual.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const tempActValue = tempActValueObj ? tempActValueObj.value : null;
                                    const tempActCopied = tempActValueObj ? tempActValueObj.copied : null;
                                    const supTempActValueObj = day.supplyTemperatureActual.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const supTempActValue = supTempActValueObj ? supTempActValueObj.value : null;
                                    const supTempActCopied = supTempActValueObj ? supTempActValueObj.copied : null;
                                    const supTempPlanValueObj = day.supplyTemperaturePlan.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const deviationValueObj = day.deviation.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const deviationValue = deviationValueObj ? deviationValueObj.value : null;
                                    const deviationMarked = deviationValueObj ? deviationValueObj.marked : null;
                                    const deviationCopied = deviationValueObj ? deviationValueObj.copied : null;
                                    const supplyPressureValueObj = day.supplyPressure.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const supplyPressureValue = supplyPressureValueObj
                                        ? supplyPressureValueObj.value
                                        : null;
                                    const returnPressureValueObj = day.returnPressure.values.find(
                                        (el) => moment(el.dateTime).hour() === index,
                                    );
                                    const returnPressureValue = returnPressureValueObj
                                        ? returnPressureValueObj.value
                                        : null;
                                    const editIndex = dayIndex * 24 + index;
                                    const popOver = popOverArray[editIndex];
                                    const editable = hasCredential
                                        ? moment(day.date).add(index, 'hour').isAfter(moment())
                                        : false;
                                    return (
                                        <tr key={`${day.date}_${index}`}>
                                            <td>
                                                {dayIndex === 0 &&
                                                    index === 0 &&
                                                    moment(day.date).isSame(moment(), 'day') &&
                                                    renderTime()}
                                                {`${index}:00 - ${index + 1}:00`}
                                            </td>
                                            <td>
                                                <div className={styles.HPTWheatherContainer}>
                                                    {symbol && <ForecaIcon symbol={symbol} />}
                                                    <div>
                                                        {tempForecValue
                                                            ? `${formatNumber(tempForecValue, 1)} ${
                                                                  day.forecaTemperatureForec.unit
                                                              }`
                                                            : ''}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {supTempForecValue
                                                    ? `${supTempForecValue} ${day.supplyTemperatureForec.unit}`
                                                    : ''}
                                            </td>
                                            <td>
                                                <div style={{ position: 'relative' }}>
                                                    {popOver && (
                                                        <>
                                                            <div
                                                                className={styles.HPTTooltipIcon}
                                                                onMouseOver={popOver.handleOpen}
                                                                onMouseOut={popOver.handleClose}
                                                            >
                                                                <InfoIcon />
                                                            </div>
                                                            <Popover
                                                                backgroundColor={colors.snowWhite}
                                                                opened={popOver.open}
                                                                anchor={popOver.anchor}
                                                                anchorPos="bottom"
                                                                padding={spacing.xxxs}
                                                            >
                                                                <table className={styles.HPTPopoverTable}>
                                                                    <tbody>
                                                                        {supTempPlanValueObj?.history?.map(
                                                                            (
                                                                                historyItem,
                                                                                historyIndex,
                                                                                historyArray,
                                                                            ) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={`${historyItem.id}`}
                                                                                        className={
                                                                                            historyIndex + 1 ===
                                                                                            historyArray.length
                                                                                                ? styles.last
                                                                                                : undefined
                                                                                        }
                                                                                    >
                                                                                        <td>
                                                                                            {moment(
                                                                                                historyItem.dateTime,
                                                                                                'DD-MM-YYYY HH:mm',
                                                                                            ).format(
                                                                                                'DD.MM.YYYY HH:mm',
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div
                                                                                                className={
                                                                                                    styles.HPTWheatherContainer
                                                                                                }
                                                                                            >
                                                                                                {historyItem.symbol && (
                                                                                                    <ForecaIcon
                                                                                                        symbol={
                                                                                                            historyItem.symbol
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                                <div>{`${formatNumber(
                                                                                                    historyItem.forecaTemperature,
                                                                                                    1,
                                                                                                )} ${
                                                                                                    historyItem.unit
                                                                                                }`}</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{`${Math.round(
                                                                                            historyItem.value,
                                                                                        )} ${historyItem.unit}`}</td>
                                                                                        <td>{historyItem.author}</td>
                                                                                    </tr>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </Popover>
                                                        </>
                                                    )}
                                                    <EditableField
                                                        selected={selected.includes(editIndex)}
                                                        editable={editable}
                                                        unit={
                                                            day.supplyTemperaturePlan.unit
                                                                ? day.supplyTemperaturePlan.unit
                                                                : ''
                                                        }
                                                        handleMouseDown={(e) => handleMouseDown(e, editIndex)}
                                                        handleEditSelected={(value) =>
                                                            handleEditSelected(value, day.date)
                                                        }
                                                    >
                                                        {editedTemp[editIndex] && day.supplyTemperaturePlan.unit
                                                            ? `${editedTemp[editIndex]} ${day.supplyTemperaturePlan.unit}`
                                                            : ''}
                                                    </EditableField>
                                                </div>
                                            </td>
                                            <td>
                                                {renderDSR(
                                                    day.procedureList.procedures.filter(
                                                        (procedure) => procedure.procedureType !== 'FRED',
                                                    ),
                                                    editIndex,
                                                )}
                                            </td>
                                            <td>
                                                {renderDSR(
                                                    day.procedureList.procedures.filter(
                                                        (procedure) => procedure.procedureType === 'FRED',
                                                    ),
                                                    editIndex,
                                                )}
                                            </td>
                                            <td>
                                                {heatOnlyBoilerValue !== null ? (
                                                    heatOnlyBoilerValue === 1 ? (
                                                        <CheckIcon />
                                                    ) : (
                                                        '-'
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                <div className={tempActCopied ? styles.HPTCopiedValue : ''}>
                                                    {tempActValue
                                                        ? `${formatNumber(tempActValue, 1)} ${
                                                              day.forecaTemperatureActual.unit
                                                          }`
                                                        : ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={supTempActCopied ? styles.HPTCopiedValue : ''}>
                                                    {supTempActValue
                                                        ? `${Math.round(supTempActValue)} ${
                                                              day.supplyTemperatureActual.unit
                                                          }`
                                                        : ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className={`${styles.HPTVariationContainer} ${
                                                        deviationMarked ? styles.HPTVariationContainerMarked : ''
                                                    } ${deviationCopied ? styles.HPTCopiedValue : ''}`}
                                                >
                                                    <div>
                                                        {deviationValue !== null
                                                            ? `${Math.round(deviationValue)} ${day.deviation.unit}`
                                                            : ''}
                                                    </div>
                                                    {deviationValue ? (
                                                        <VariationIcon value={Math.round(deviationValue)} />
                                                    ) : null}
                                                </div>
                                            </td>
                                            <td>
                                                <PressureField
                                                    unit={day.supplyPressure.unit ? day.supplyPressure.unit : ''}
                                                    editable={editable}
                                                    edited={
                                                        supplyPressureValueObj
                                                            ? !!supplyPressureValueObj.booleanValue
                                                            : false
                                                    }
                                                    handleChange={(value) =>
                                                        bbb(
                                                            parseFloat(value),
                                                            moment(day.date)
                                                                .add(index, 'hour')
                                                                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
                                                        )
                                                    }
                                                >
                                                    {supplyPressureValue
                                                        ? `${formatNumber(supplyPressureValue, 2)} ${
                                                              day.supplyPressure.unit
                                                          }`
                                                        : ''}
                                                </PressureField>
                                            </td>
                                            <td>
                                                <PressureField
                                                    unit={day.returnPressure.unit ? day.returnPressure.unit : ''}
                                                    editable={editable}
                                                    edited={
                                                        returnPressureValueObj
                                                            ? !!returnPressureValueObj.booleanValue
                                                            : false
                                                    }
                                                    handleChange={(value) =>
                                                        ccc(
                                                            parseFloat(value),
                                                            moment(day.date)
                                                                .add(index, 'hour')
                                                                .format('YYYY-MM-DDTHH:mm:ss[Z]'),
                                                        )
                                                    }
                                                >
                                                    {returnPressureValue
                                                        ? `${formatNumber(returnPressureValue, 2)} ${
                                                              day.returnPressure.unit
                                                          }`
                                                        : ''}
                                                </PressureField>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default HeatPlanTable;
