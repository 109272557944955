import { createSlice } from '@reduxjs/toolkit';

import { Procedures } from './ProceduresList.types';
import { fetchProcedures } from './ProceduresList.thunk';

const initialState: Procedures = {
    proceduresList: [],
    loading: false,
};

const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProcedures.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProcedures.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.proceduresList = action.payload.procedures;
            }
        });
    },
});

export default proceduresSlice.reducer;
