import React, { FunctionComponent } from 'react';
import { Box, Breadcrumb, Col, Grid, Row, spacing } from '@fortum/elemental-ui';
import { Footer } from 'src/components/Footer/Footer';
import { Toast } from 'src/components/Toast/Toast';
import WeatherContainer from 'src/containers/WeatherContainer/WeatherContainer';
import Header from 'src/components/Header/Header';

const Weather: FunctionComponent = () => {
    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Header activePage={'Pogoda'} />
                <Grid flex="1 0 auto">
                    <Row marginTop={spacing.xxs} marginBottom={spacing.xxxs}>
                        <Col l={12}>
                            <Breadcrumb
                                aria-label="Nawigacja"
                                items={[{ title: 'Pulpit', params: { href: '/' } }, { title: 'Pogoda' }]}
                            />
                        </Col>
                    </Row>
                    <WeatherContainer />
                </Grid>
                <Footer />
            </Box>
            <Toast />
        </>
    );
};

export default Weather;
