import React, {useContext, useEffect} from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/providers/store';
import { setAuthToken } from 'src/providers/auth';
import { fetchUserInfo } from 'src/providers/user';

export const AuthorizationPage: React.FC = () => {
    const { token, loginInProgress } = useContext(AuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (token) {
            dispatch(setAuthToken(token));
            dispatch(fetchUserInfo());
            navigate('/');
        } else if (!loginInProgress && !token) {
            navigate('/unauthorized');
        }
    }, [token, loginInProgress]);

    return (
        <div>{loginInProgress ? <div>Logging in...</div> : token ? <div>Logged in</div> : <div>Unauthorized</div>}</div>
    );
};
