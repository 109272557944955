import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './providers/store';
import './styles/main.scss';
import { Router } from 'src/services/router/Router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
                <Router />
        </Provider>
    </React.StrictMode>,
);
