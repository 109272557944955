import { createSlice } from '@reduxjs/toolkit';

import { HeatProductionData } from './HeatProductionPlansContainer.types';
import { fetchHeatProduction } from './HeatProductionPlansContainer.thunk';

const initialState: HeatProductionData = {
    heatProduction: null,
    loading: false,
};

const heatProductionSlice = createSlice({
    name: 'heatProduction',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHeatProduction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchHeatProduction.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.heatProduction = action.payload;
            }
        });
    },
});

export default heatProductionSlice.reducer;
