import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IconProps } from '@fortum/elemental-ui';
import { ReactComponent as Export } from 'src/assets/export.svg';
import { ReactComponent as Archive } from 'src/assets/archive.svg';
import { ReactComponent as Slider } from 'src/assets/slider.svg';
import { ReactComponent as ChevronsLeft } from 'src/assets/chevrons-left.svg';
import { ReactComponent as ChevronsRight } from 'src/assets/chevrons-right.svg';
import { ReactComponent as Logout } from 'src/assets/log-out.svg';

import styles from './CustomIcon.module.scss';
export const CustomIcon: FunctionComponent<IconProps> = (props: PropsWithChildren<IconProps>) => {
    return <span className={`${styles.container} ${props.className ? props.className : ''}`}>{props.children}</span>;
};

export const ExportIcon: FunctionComponent = () => {
    return (
        <CustomIcon>
            <Export />
        </CustomIcon>
    );
};

export const ArchiveIcon: FunctionComponent = () => {
    return (
        <CustomIcon>
            <Archive />
        </CustomIcon>
    );
};

export const SliderIcon: FunctionComponent = () => {
    return (
        <CustomIcon>
            <Slider />
        </CustomIcon>
    );
};

export const ChevronsLeftIcon: FunctionComponent = () => {
    return (
        <CustomIcon>
            <ChevronsLeft />
        </CustomIcon>
    );
};

export const ChevronsRightIcon: FunctionComponent = () => {
    return (
        <CustomIcon>
            <ChevronsRight />
        </CustomIcon>
    );
};

export const LogoutIcon: FunctionComponent<IconProps> = (props: PropsWithChildren<IconProps>) => {
    return (
        <CustomIcon className={props.className}>
            <Logout />
        </CustomIcon>
    );
};
