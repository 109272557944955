import { ContentText, colors } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styles from './LegendItem.module.scss';
import { LegendItemProps, ILegendItemProperties } from './LegendItem.types';

export const LegendItem: FunctionComponent<LegendItemProps> = ({ type }: LegendItemProps) => {
    const [properties, setProperties] = useState<ILegendItemProperties>({
        label: 'Zaplanowany',
        color: '',
    });

    useEffect(() => {
        !properties.color && selectData();
    });

    const selectData = () => {
        switch (type) {
            case 'PLANNED':
                setProperties({
                    label: 'Zaplanowany',
                    color: colors.aureolinYellow,
                });
                break;
            case 'IN_ACCEPTATION':
                setProperties({
                    label: 'W akceptacji dyspozytora',
                    color: colors.stoicalOrange,
                });
                break;
            case 'ACCEPTED':
                setProperties({
                    label: 'Zatwierdzony',
                    color: colors.fogGrey,
                });
                break;
            case 'REJECTED':
                setProperties({
                    label: 'Odrzucony',
                    color: colors.etherealPurple,
                });
                break;
            case 'TERMINATED':
                setProperties({
                    label: 'Zatrzymany',
                    color: colors.fogGrey,
                });
                break;
            case 'ERROR':
                setProperties({
                    label: 'Błąd',
                    color: colors.fogGrey,
                });
                break;
            case 'DONE':
                setProperties({
                    label: 'Wykonany',
                    color: colors.optimisticGreen,
                });
                break;
            case 'DSR-':
                setProperties({
                    label: 'DSR -',
                    color: colors.cushyBlue,
                });
                break;
            case 'DSR+':
                setProperties({
                    label: 'DSR +',
                    color: colors.energeticRedOrange,
                });
        }
    };

    const renderColorBox = () =>
        type !== 'DSR+' && type !== 'DSR-' ? (
            <div className={styles.colorBox} style={{ backgroundColor: properties.color }} />
        ) : (
            <div className={styles.scenarioColorBox} style={{ backgroundColor: properties.color }}>
                <div className={styles.scenarioColorBoxInner} />
            </div>
        );

    return (
        <div className={styles.container}>
            {renderColorBox()}
            <ContentText className={styles.label}>{properties.label}</ContentText>
        </div>
    );
};
