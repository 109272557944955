import { createSlice } from '@reduxjs/toolkit';

import { WeatherData } from './WeatherContainer.types';
import { fetchWeather } from './WeatherContainer.thunk';

const initialState: WeatherData = {
    weather: null,
    loading: false,
};

const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchWeather.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchWeather.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.weather = action.payload;
            }
        });
    },
});

export default weatherSlice.reducer;
