import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    getHeatProduction,
    downloadHeatProduction,
    updateHeatProductionPlanValue,
    updateHeatProductionSupplyPressure,
    updateHeatProductionReturnPressure,
} from 'src/api/requests';
import { logger } from 'src/utils';
import { HeatProduction, HeatProductionPlanUpdateBody } from 'src/api/model/HeatProduction';

const fetchHeatProduction = createAsyncThunk(
    'heatProduction/get',
    async (date?: Date): Promise<HeatProduction[] | undefined> => {
        try {
            logger.log('[TODO] try to getHeatProduction');
            return await getHeatProduction(date ? moment(date).format('YYYY-MM-DD') : undefined);
        } catch {
            // handle error
            logger.error('[TODO] getHeatProduction failed');
        }
    },
);

const exportHeatProduction = createAsyncThunk(
    'heatProduction/download',
    async (date: Date): Promise<void | undefined> => {
        try {
            logger.log('[TODO] try to downloadHeatProduction');
            await downloadHeatProduction(moment(date).format('YYYY-MM-DD')).then((response: BlobPart) => {
                const blob = new Blob([response], { type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64' });
                const fileURL = window.URL.createObjectURL(blob);
                const alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `heat-production_${moment().format('YYYY-MM-DD_HH_mm')}.xlsx`;
                alink.click();
            });
        } catch {
            // handle error
            logger.error('[TODO] downloadHeatProduction failed');
        }
    },
);

const handleHeatProductionPlanValueUpdate = createAsyncThunk(
    'heatProduction/update',
    async (arg: { body: HeatProductionPlanUpdateBody[] }, { dispatch }) => {
        try {
            return await updateHeatProductionPlanValue(arg.body).then(() => {
                dispatch(fetchHeatProduction());
            });
        } catch {
            console.error('[TODO] heatProductionPlanValueUpdate failed');
        }
    },
);

const handleHeatProductionSupplyPressureUpdate = createAsyncThunk(
    'heatProduction/supplyPressureUpdate',
    async (arg: { body: HeatProductionPlanUpdateBody }, { dispatch }) => {
        try {
            return await updateHeatProductionSupplyPressure(arg.body).then(() => {
                dispatch(fetchHeatProduction());
            });
        } catch {
            console.error('[TODO] heatProductionSupplyPressureUpdate failed');
        }
    },
);

const handleHeatProductionReturnPressureUpdate = createAsyncThunk(
    'heatProduction/supplyPressureUpdate',
    async (arg: { body: HeatProductionPlanUpdateBody }, { dispatch }) => {
        try {
            return await updateHeatProductionReturnPressure(arg.body).then(() => {
                dispatch(fetchHeatProduction());
            });
        } catch {
            console.error('[TODO] heatProductionReturnPressureUpdate failed');
        }
    },
);

export {
    fetchHeatProduction,
    exportHeatProduction,
    handleHeatProductionPlanValueUpdate,
    handleHeatProductionSupplyPressureUpdate,
    handleHeatProductionReturnPressureUpdate,
};
