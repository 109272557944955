import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    colors,
    ContentText,
    Gap,
    Heading,
    IconButton,
    IconChevronLeft,
    IconChevronRight,
    IconHidePassword,
    IconShowPassword,
    InputField,
    Modal,
    RadioButtonGroup,
    Select,
    spacing,
    useEventTargetValue,
} from '@fortum/elemental-ui';
import moment from 'moment';

import { GroupNames, GroupNamesState } from 'src/containers/ProceduresManagement/ProceduresManagement.types';
import { AppDispatch } from 'src/providers/store';

import {
    fetchGroupNames,
    handleProcedureAdd,
    handleProcedureCatalogAdd,
    handleProcedureUpdate,
    handleProcedureCatalogUpdate,
} from 'src/containers/ProceduresManagement/ProceduresManagement.thunk';

import { Item, ItemBody } from 'src/api/model/Item';

import { handleToast } from 'src/components/Toast/Toast.slice';

import { ConfigureProcedureModalProps } from './ConfigureProcedureModal.types';

import styles from './ConfigureProcedureModal.module.scss';

type GroupParamsType = {
    id: number;
    controlCodeAbs: number;
    visibility: boolean;
    name: string;
};

const usePrevious = <T,>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const addAllItems = (
    items: Item[] | undefined,
    zeroDurationTime: number,
    durationTime: number,
    controlCode: number,
) => {
    if (!items) {
        return [];
    }

    const itemsCopy = [...addFirstItem(items, durationTime, false, controlCode)];
    if (itemsCopy.length === 1) {
        const end = moment(`${itemsCopy[0].endDate} ${itemsCopy[0].endTime}`);
        [...Array(2)].forEach((n, index) => {
            const duration = index === 0 ? zeroDurationTime : durationTime;
            const startDate = end.format('YYYY-MM-DD');
            const startTime = end.format('HH:mm');
            end.add(duration, 'hour');
            itemsCopy.push({
                id: null,
                controlCode: index === 0 ? 0 : -itemsCopy[0].controlCode,
                status: itemsCopy[0].status,
                startDate,
                startTime,
                endDate: end.format('YYYY-MM-DD'),
                endTime: end.format('HH:mm'),
            });
        });
    }

    return itemsCopy;
};

const addFirstItem = (items: Item[] | undefined, durationTime: number, reset: boolean, controlCode: number): Item[] => {
    if (!items || items.length === 0) {
        return [];
    }

    const end = moment(`${items[0].startDate} ${items[0].startTime}`);
    end.add(durationTime, 'hour');

    const item: Item = {
        id: null,
        status: items[0].status,
        controlCode: reset ? 0 : controlCode,
        startDate: items[0].startDate,
        startTime: items[0].startTime,
        endDate: end.format('YYYY-MM-DD'),
        endTime: end.format('HH:mm'),
    };

    return [item];
};

const addAllBodyItems = (
    items: ItemBody[] | undefined,
    zeroDurationTime: number,
    durationTime: number,
    controlCode: number,
) => {
    if (!items) {
        return [];
    }

    const itemsCopy = [...addFirstBodyItem(items, durationTime, false, controlCode)];
    if (itemsCopy.length === 1) {
        const end = moment(`${itemsCopy[0].endDate} ${itemsCopy[0].endTime}`);
        [...Array(2)].forEach((n, index) => {
            const duration = index === 0 ? zeroDurationTime : durationTime;
            const startDate = end.format('YYYY-MM-DD');
            const startTime = end.format('HH:mm');
            end.add(duration, 'hour');
            itemsCopy.push({
                controlCode: index === 0 ? 0 : -itemsCopy[0].controlCode,
                startDate,
                startTime,
                endDate: end.format('YYYY-MM-DD'),
                endTime: end.format('HH:mm'),
            });
        });
    }

    return itemsCopy;
};

const addFirstBodyItem = (
    items: ItemBody[] | undefined,
    durationTime: number,
    reset: boolean,
    controlCode: number,
): ItemBody[] => {
    if (!items || items.length === 0) {
        return [];
    }

    const end = moment(`${items[0].startDate} ${items[0].startTime}`);
    end.add(durationTime, 'hour');

    const item: ItemBody = {
        controlCode: reset ? 0 : controlCode,
        startDate: items[0].startDate,
        startTime: items[0].startTime,
        endDate: end.format('YYYY-MM-DD'),
        endTime: end.format('HH:mm'),
    };

    return [item];
};

export const ConfigureProcedureModal: FunctionComponent<ConfigureProcedureModalProps> = ({
    onClose,
    action,
    id,
    proceduresCatalog,
    procedures,
    tempProcedure,
    onSaveTemp,
}: ConfigureProcedureModalProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const groupNamesState = useSelector<GroupNamesState>((state) => state.groupNames) as GroupNames;
    const prevGroupNamesState = usePrevious(groupNamesState);
    const procedureManualFound = procedures && procedures.find((item) => item.id === id);
    const isRunning =
        procedureManualFound &&
        procedureManualFound.procedureType !== 'EMERGENCY' &&
        procedureManualFound.status === 'RUNNING';
    const isRunningEmergency =
        procedureManualFound &&
        procedureManualFound.procedureType === 'EMERGENCY' &&
        procedureManualFound.status === 'COMPLETED';
    const editedManualProcedure =
        action === 'edit' &&
        ((tempProcedure && {
            name: tempProcedure.name,
            procedureType: tempProcedure.procedureType,
            catalogGroups: [...tempProcedure.groups]
                .sort(
                    (a, b) =>
                        moment(a.items[0].startTime, 'HH:mm').unix() - moment(b.items[0].startTime, 'HH:mm').unix(),
                )
                .map((group, index) => ({
                    id: index,
                    name: group.name,
                    timeDelay: moment
                        .duration(
                            moment(`${group.items[0].startDate} ${group.items[0].startTime}`, 'YYYY-MM-DD HH:mm').diff(
                                moment(
                                    `${tempProcedure.groups[0].items[0].startDate} ${tempProcedure.groups[0].items[0].startTime}`,
                                    'YYYY-MM-DD HH:mm',
                                ),
                            ),
                        )
                        .asMinutes(),
                    catalogGroupItems: group.items.map((item, iIndex) => ({
                        id: iIndex,
                        controlCode: item.controlCode,
                        duration: moment
                            .duration(
                                moment(`${item.endDate} ${item.endTime}`, 'YYYY-MM-DD HH:mm').diff(
                                    moment(`${item.startDate} ${item.startTime}`, 'YYYY-MM-DD HH:mm'),
                                ),
                            )
                            .asHours(),
                    })),
                })),
        }) ||
            (procedureManualFound
                ? {
                      id: procedureManualFound.id,
                      name: procedureManualFound.name,
                      procedureType: procedureManualFound.procedureType,
                      catalogGroups: [...procedureManualFound.groups]
                          .sort(
                              (a, b) =>
                                  moment(a.items[0].startTime, 'HH:mm').unix() -
                                  moment(b.items[0].startTime, 'HH:mm').unix(),
                          )
                          .map((group) => ({
                              id: group.id,
                              name: group.name,
                              timeDelay: moment
                                  .duration(
                                      moment(group.items[0].startTime, 'HH:mm').diff(
                                          moment(procedureManualFound.groups[0].items[0].startTime, 'HH:mm'),
                                      ),
                                  )
                                  .asMinutes(),
                              catalogGroupItems: group.items.map((item) => ({
                                  id: item.id,
                                  controlCode: item.controlCode,
                                  duration: moment
                                      .duration(
                                          moment(`${item.endDate} ${item.endTime}`, 'YYYY-MM-DD HH:mm').diff(
                                              moment(`${item.startDate} ${item.startTime}`, 'YYYY-MM-DD HH:mm'),
                                          ),
                                      )
                                      .asHours(),
                              })),
                          })),
                  }
                : undefined));

    const catalogProcedureFound = proceduresCatalog && proceduresCatalog.find((item) => item.id === id);
    const editedCatalogProcedure =
        action === 'edit' && catalogProcedureFound
            ? {
                  ...catalogProcedureFound,
                  catalogGroups: [...catalogProcedureFound.catalogGroups],
              }
            : undefined;
    const editedProcedure = editedManualProcedure
        ? editedManualProcedure
        : editedCatalogProcedure
        ? editedCatalogProcedure
        : undefined;
    const [procedureName, setProcedureName] = useState(editedProcedure ? editedProcedure.name : '');
    const [groupsParams, setGroupsParams] = useState<GroupParamsType[]>([]);

    const [durationTime, handleDurationTime] = useEventTargetValue(
        editedProcedure && editedProcedure.catalogGroups[0].catalogGroupItems[0]
            ? editedProcedure.procedureType !== 'RESET'
                ? editedProcedure.catalogGroups[0].catalogGroupItems[0].duration
                : 2
            : 2,
    );
    const [zeroDurationTime, handleZeroDurationTime] = useEventTargetValue(
        editedProcedure && editedProcedure.procedureType !== 'EMERGENCY'
            ? editedProcedure.procedureType !== 'RESET'
                ? editedProcedure.catalogGroups[0].catalogGroupItems[1].duration
                : 0.25
            : 2,
    );
    const [groupDelay, handleGroupDelayChange] = useEventTargetValue(
        editedProcedure && editedProcedure.catalogGroups[1] ? editedProcedure.catalogGroups[1].timeDelay : 15,
    );
    const [startHour, handleStartHourChange] = useEventTargetValue(
        procedureManualFound
            ? moment(procedureManualFound.startTime, 'HH:ss').hour()
            : tempProcedure
            ? moment(tempProcedure.startTime, 'HH:ss').hour()
            : 0,
    );
    const [startOption, handleStartOptionChange] = useEventTargetValue(
        editedProcedure
            ? editedProcedure.catalogGroups[0].catalogGroupItems[0].controlCode > 0
                ? 'DSR +'
                : 'DSR -'
            : 'DSR +',
    );
    const [procedureType, handleProcedureTypeChange] = useEventTargetValue(
        editedProcedure
            ? editedProcedure.procedureType?.toLowerCase() !== 'fred'
                ? editedProcedure.procedureType?.toLowerCase()
                : 'manual'
            : 'manual',
    );

    const changeProcedureType = (ev: { target: { value: string } }) => {
        handleProcedureTypeChange(ev);
        if (ev.target.value === 'reset') {
            handleZeroDurationTime({
                target: { value: 0.25 },
            });
        }
        if (procedureType === 'reset') {
            handleZeroDurationTime({
                target: {
                    value:
                        editedProcedure && editedProcedure.procedureType !== 'EMERGENCY'
                            ? editedProcedure.procedureType !== 'RESET'
                                ? editedProcedure.catalogGroups[0].catalogGroupItems[1].duration
                                : 2
                            : 2,
                },
            });
        }
    };

    useEffect(() => {
        dispatch(fetchGroupNames());
    }, []);

    useEffect(() => {
        if (!groupNamesState.loading && prevGroupNamesState && prevGroupNamesState.loading) {
            const groupNames = groupNamesState.groupNamesList;

            let orderedNames = [...groupNames];

            if (editedProcedure) {
                const names: string[] = [];

                editedProcedure.catalogGroups.forEach((group) => {
                    names.push(group.name);
                });

                orderedNames = names.concat(groupNames).filter((name, index, array) => array.indexOf(name) === index);
            }

            const groupsParamsInit = orderedNames.map((name, index) => {
                const group = editedProcedure && editedProcedure.catalogGroups.find((group) => group.name === name);
                group && (tempId += group.id);
                return {
                    id: group ? group.id : tempId * (index + 1),
                    controlCodeAbs:
                        group && group.catalogGroupItems[0].controlCode
                            ? Math.abs(group.catalogGroupItems[0].controlCode)
                            : 1,
                    visibility: editedProcedure ? !!group : true,
                    name,
                };
            });

            setGroupsParams(groupsParamsInit);
        }
    }, [groupNamesState]);

    let tempId = 1;
    const width = procedures || tempProcedure ? 1000 : 800;
    const totalDuration =
        (procedureType === 'emergency'
            ? durationTime
            : procedureType === 'reset'
            ? zeroDurationTime
            : durationTime * 2 + zeroDurationTime) +
        (3 * groupDelay) / 60;
    const hours = Math.ceil(procedureType === 'reset' ? totalDuration + 1 : totalDuration);
    const height = hours * 24;

    const durationItemsManual = [...Array(8)].map((n, i) => ({
        name: `${i + 1} godzin${i < 4 ? (i === 0 ? 'a' : 'y') : ''}`,
        value: i + 1,
    }));

    const durationItemsEmergency = [...Array(24)].map((n, i) => ({
        name: `${i + 1} godzin${i < 4 ? (i === 0 ? 'a' : 'y') : ''}`,
        value: i + 1,
    }));

    const [durationItems, setDurationItems] = useState(
        procedureType === 'emergency' ? durationItemsEmergency : durationItemsManual,
    );

    const zeroDurationItems = [...Array(20)].map((n, i) => ({
        name: `${i + 1} godzin${i < 4 ? (i === 0 ? 'a' : 'y') : ''}`,
        value: i + 1,
    }));

    const mItems = [...Array(2)].map((n, i) => ({
        name: `${(i + 1) * 15} minut`,
        value: (i + 1) * 15,
    }));

    const startHoursManual = [...Array(20)].map((n, i) => ({
        name: `${i + 1}:00`,
        value: i + 1,
    }));

    const startHoursEmergency = [...Array(23)].map((n, i) => ({
        name: `${i + 1}:00`,
        value: i + 1,
    }));

    const [startHours, setStartHours] = useState(
        procedureType === 'emergency' ? startHoursEmergency : startHoursManual,
    );

    useEffect(() => {
        setDurationItems(procedureType === 'emergency' ? durationItemsEmergency : durationItemsManual);
        setStartHours(procedureType === 'emergency' ? startHoursEmergency : startHoursManual);
    }, [procedureType]);

    const renderLines = () => {
        return [...Array(hours)].map((n, i) => {
            const y = i * 23 + i;
            const lineY = y + 24;
            let hour = procedureManualFound || tempProcedure ? startHour + i : i;
            hour = hour % 24;
            const hourFrom = hour > 9 ? hour : `0${hour}`;
            const hourTo = hour + 1 > 9 ? (hour === 23 ? '00' : hour + 1) : `0${hour + 1}`;
            return (
                <React.Fragment key={`renderLines${i}`}>
                    <text
                        x={65}
                        y={y + 13}
                        className={styles.DSRInputTableGraphHour}
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {`${hourFrom}:00-${hourTo}:00`}
                    </text>
                    <line className={styles.DSRInputTableGraphHorizontalLine} x1={0} y1={lineY} x2={width} y2={lineY} />
                </React.Fragment>
            );
        });
    };

    const renderProcedure = (xPos: number, width: number, groupIndex: number, groupId: number) => {
        const y = ((groupIndex * groupDelay) / 60) * 24;
        const y2 = (durationTime + zeroDurationTime) * 24 + y;
        const height = (procedureType === 'reset' ? zeroDurationTime : durationTime) * 24;
        const changeWidth = 24;
        const groupParam = groupsParams.find((el) => el.id === groupId);
        if (groupParam) {
            return (
                <React.Fragment key={`procedure${groupId}`}>
                    <g>
                        <rect
                            x={xPos}
                            y={y}
                            width={width}
                            height={height}
                            className={styles.DSRConfigureTableGraphProcedureItem}
                        />
                        {procedureType !== 'reset' && groupParam.visibility && (
                            <rect
                                x={xPos}
                                y={y}
                                width={changeWidth}
                                height={height}
                                className={
                                    startOption === 'DSR +'
                                        ? styles.DSRConfigureTableGraphProcedureChangePositive
                                        : styles.DSRConfigureTableGraphProcedureChangeNegative
                                }
                            />
                        )}
                    </g>
                    {procedureType !== 'emergency' && procedureType !== 'reset' && (
                        <g>
                            <rect
                                x={xPos}
                                y={y2}
                                width={width}
                                height={height}
                                className={styles.DSRConfigureTableGraphProcedureItem}
                            />
                            {groupParam.visibility && (
                                <rect
                                    x={xPos}
                                    y={y2}
                                    width={changeWidth}
                                    height={height}
                                    className={
                                        startOption === 'DSR +'
                                            ? styles.DSRConfigureTableGraphProcedureChangeNegative
                                            : styles.DSRConfigureTableGraphProcedureChangePositive
                                    }
                                />
                            )}
                        </g>
                    )}
                </React.Fragment>
            );
        }
        return null;
    };

    const renderOverProcedure = (xPos: number, width: number, groupIndex: number, groupId: number) => {
        const y = ((groupIndex * groupDelay) / 60) * 24;
        const y2 = (durationTime + zeroDurationTime) * 24 + y;
        const height = (procedureType === 'reset' ? zeroDurationTime : durationTime) * 24;
        const x = xPos + width - 30 - 2;
        const textY = y + height / 2;
        const groupParam = groupsParams.find((el) => el.id === groupId);
        if (groupParam) {
            return (
                <React.Fragment key={`overProcedure${groupId}`}>
                    <g>
                        {!isRunning && !isRunningEmergency && (
                            <g>
                                <rect x={x} y={y + 2} width={30} height={22} fill="#999999" rx={5} />
                                <foreignObject
                                    x={x + 3}
                                    y={y + 1}
                                    width={24}
                                    height={22}
                                    onClick={() => handleGroupVisibilty(groupId)}
                                >
                                    {groupParam.visibility ? <IconHidePassword /> : <IconShowPassword />}
                                </foreignObject>
                            </g>
                        )}
                        {groupParam.visibility && (
                            <text
                                className={styles.DSRConfigureTableGraphItemControlCode}
                                x={xPos + width / 2}
                                y={textY < 6 ? 6 : textY}
                                dominantBaseline="middle"
                                textAnchor="middle"
                            >
                                {procedureType !== 'reset' ? `${startOption}${groupParam.controlCodeAbs}` : 'DSR 0'}
                            </text>
                        )}
                    </g>
                    {procedureType !== 'emergency' && procedureType !== 'reset' && (
                        <g>
                            {!isRunning && !isRunningEmergency && (
                                <g>
                                    <rect x={x} y={y2 + 2} width={30} height={22} fill="#999999" rx={5} />
                                    <foreignObject
                                        x={x + 3}
                                        y={y2 + 1}
                                        width={24}
                                        height={22}
                                        onClick={() => handleGroupVisibilty(groupId)}
                                    >
                                        {groupParam.visibility ? <IconHidePassword /> : <IconShowPassword />}
                                    </foreignObject>
                                </g>
                            )}
                            {groupParam.visibility && (
                                <text
                                    className={styles.DSRConfigureTableGraphItemControlCode}
                                    x={xPos + width / 2}
                                    y={y2 + height / 2}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {`${startOption === 'DSR +' ? 'DSR -' : 'DSR +'}${groupParam.controlCodeAbs}`}
                                </text>
                            )}
                        </g>
                    )}
                </React.Fragment>
            );
        }
        return null;
    };

    const createItems = (
        initTime: moment.Moment,
        group: GroupParamsType,
        index: number,
        item: Item | ItemBody,
        itemIndex: number,
        time: { lastEndTime: moment.Moment },
        inTemplateItem = false,
    ) => {
        const startTime = initTime.clone().add(groupDelay * index, 'minutes');
        const endTime = startTime
            .clone()
            .add(itemIndex === 1 || procedureType === 'reset' ? zeroDurationTime : durationTime, 'hours');
        const newItem = {
            id: 'id' in item ? (inTemplateItem ? group.id : item.id) : null,
            startDate: item.startDate,
            startTime: startTime.format('HH:mm'),
            endDate: endTime.format('YYYY-MM-DD'),
            endTime: endTime.format('HH:mm'),
            controlCode:
                group.controlCodeAbs *
                (itemIndex === 0 && procedureType !== 'reset'
                    ? startOption === 'DSR +'
                        ? 1
                        : -1
                    : itemIndex === 1 || procedureType === 'reset'
                    ? 0
                    : startOption === 'DSR +'
                    ? -1
                    : 1),
            status: (isRunning || isRunningEmergency) && 'status' in item ? item.status : 'PLANNED',
        };
        initTime.add(itemIndex === 1 ? zeroDurationTime : durationTime, 'hours');
        time.lastEndTime = endTime;
        return newItem;
    };

    const handleSaveBtn = () => {
        if (id && editedCatalogProcedure && editedProcedure) {
            const catalogGroups = groupsParams
                .filter((group) => group.visibility)
                .map((group, index) => {
                    const editedGroup = editedProcedure.catalogGroups.find(
                        (editedGroup) => editedGroup.id === group.id,
                    );
                    const groupItemsId = editedGroup
                        ? editedGroup.catalogGroupItems.map((groupItems) => groupItems.id)
                        : [null, null, null];
                    const catalogGroupItems = [];

                    if (procedureType !== 'reset') {
                        catalogGroupItems.push({
                            id: groupItemsId[0] ? groupItemsId[0] : null,
                            controlCode: group.controlCodeAbs * (startOption === 'DSR +' ? 1 : -1),
                            duration: durationTime,
                        });
                    }

                    if (procedureType !== 'emergency') {
                        const i = procedureType !== 'reset' ? 1 : 0;
                        catalogGroupItems.push({
                            id: groupItemsId[i] ? groupItemsId[i] : null,
                            controlCode: 0,
                            duration: zeroDurationTime,
                        });
                        if (procedureType !== 'reset') {
                            catalogGroupItems.push({
                                id: groupItemsId[2] ? groupItemsId[2] : null,
                                controlCode: group.controlCodeAbs * (startOption === 'DSR +' ? -1 : 1),
                                duration: durationTime,
                            });
                        }
                    }

                    return {
                        id: editedGroup ? group.id : null,
                        name: group.name,
                        timeDelay: groupDelay * index,
                        catalogGroupItems,
                    };
                });
            const body = {
                name: procedureName,
                procedureType: procedureType.toUpperCase(),
                catalogGroups,
            };

            const totalManualDuration =
                2 * durationTime + zeroDurationTime + (groupDelay * (catalogGroups.length - 1)) / 60;
            if (procedureType !== 'emergency' && procedureType !== 'reset' && totalManualDuration > 22.75) {
                dispatch(
                    handleToast({
                        toastText: 'Procedura optymalizacyjna nie powinna trwać dłużej niż 22 godziny 45 minut',
                    }),
                );
            } else {
                onClose();
                dispatch(handleProcedureCatalogUpdate({ id, body }));
            }
        } else if (id && procedureManualFound && editedManualProcedure && editedProcedure) {
            const time = {
                lastEndTime: moment(`${procedureManualFound.startDate} ${procedureManualFound.startTime}`),
            };
            const manualSelectedDayProcedures =
                procedures &&
                procedures.filter((procedure) => {
                    return (
                        procedure.procedureType === 'MANUAL' &&
                        procedure.status !== 'REJECTED' &&
                        procedure.status !== 'TERMINATED' &&
                        procedure.status !== 'ERROR' &&
                        procedure.startDate === procedureManualFound.startDate &&
                        procedure.id !== id &&
                        ((procedure.status !== 'PLANNED' && procedure.status !== 'TO_BE_ACCEPTED') ||
                            ((procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                                moment().isBefore(
                                    moment(
                                        `${procedure.startDate} ${procedure.startTime}`,
                                        'YYYY-MM-DD HH:mm',
                                    ).subtract(1, 'hours'),
                                )))
                    );
                });
            const groups = groupsParams
                .filter((group) => group.visibility)
                .map((group, index) => {
                    const editedGroup = procedureManualFound.groups.find((editedGroup) => editedGroup.id === group.id);

                    const controlCode = group.controlCodeAbs * (startOption === 'DSR +' ? 1 : -1);
                    const startItem = editedGroup?.items.find((item) =>
                        startOption === 'DSR +' ? item.controlCode > 0 : item.controlCode < 0,
                    );
                    const zeroItem = editedGroup?.items.find((item) => item.controlCode === 0);

                    const validEditedItems =
                        procedureType === 'emergency'
                            ? startItem
                                ? [startItem]
                                : addFirstItem(editedGroup?.items, durationTime, false, controlCode)
                            : procedureType === 'reset'
                            ? zeroItem
                                ? [zeroItem]
                                : addFirstItem(editedGroup?.items, zeroDurationTime, true, 0)
                            : addAllItems(editedGroup?.items, zeroDurationTime, durationTime, controlCode);
                    const initTime = moment(`${procedureManualFound.startDate} ${startHour}`, 'YYYY-MM-DD H');
                    const templateItems = procedureManualFound.groups[0].items;
                    const startItemT = templateItems.find((item) =>
                        startOption === 'DSR +' ? item.controlCode > 0 : item.controlCode < 0,
                    );
                    const zeroItemT = templateItems.find((item) => item.controlCode === 0);
                    const validTemplateItems =
                        procedureType === 'emergency'
                            ? startItemT
                                ? [startItemT]
                                : addFirstItem(templateItems, durationTime, false, controlCode)
                            : procedureType === 'reset'
                            ? zeroItemT
                                ? [zeroItemT]
                                : addFirstItem(templateItems, zeroDurationTime, true, 0)
                            : addAllItems(templateItems, zeroDurationTime, durationTime, controlCode);
                    return {
                        id: editedGroup ? group.id : null,
                        name: group.name,
                        items: validEditedItems.length
                            ? validEditedItems.map((item, itemIndex) => {
                                  return createItems(initTime, group, index, item, itemIndex, time);
                              })
                            : validTemplateItems.map((item, itemIndex) => {
                                  return createItems(initTime, group, index, item, itemIndex, time, true);
                              }),
                    };
                });

            const body = {
                name: procedureName,
                startDate: procedureManualFound.startDate,
                endDate: time.lastEndTime.format('YYYY-MM-DD'),
                startTime: moment(startHour, 'H').format('HH:mm'),
                endTime: time.lastEndTime.format('HH:mm'),
                procedureType: procedureType.toUpperCase(),
                status: procedureManualFound.status,
                groups,
            };

            const isStartDateValid =
                !manualSelectedDayProcedures ||
                manualSelectedDayProcedures.every((procedure) => {
                    const startDateTime = moment(`${procedure.startDate} ${procedure.startTime}`);
                    const endDateTime = moment(`${procedure.endDate} ${procedure.endTime}`);
                    const startDateTimeFullHour = startDateTime.minute()
                        ? startDateTime.add(1, 'hour').startOf('hour')
                        : startDateTime.startOf('hour');
                    const endDateTimeFullHour = endDateTime.minute()
                        ? endDateTime.add(1, 'hour').startOf('hour')
                        : endDateTime.startOf('hour');
                    return (
                        moment(`${procedureManualFound.startDate} ${body.startTime}`).isAfter(
                            endDateTimeFullHour.add(1, 'hour').subtract(1, 'second'),
                        ) ||
                        moment(`${procedureManualFound.endDate} ${body.endTime}`).isBefore(
                            startDateTimeFullHour.subtract(1, 'hour').subtract(1, 'second'),
                        )
                    );
                });

            const isEndDateValid = time.lastEndTime.isBefore(
                moment(`${procedureManualFound.startDate} ${procedureManualFound.startTime}`)
                    .hours(23)
                    .minutes(45)
                    .seconds(1)
                    .milliseconds(0),
            );

            if (!isStartDateValid) {
                dispatch(
                    handleToast({
                        toastText:
                            'Procedura powinna zaczynać się co najmniej godzinę po poprzedzającej procedurze i kończyć godzinę przed następną procedurą',
                    }),
                );
            } else if (!isEndDateValid && procedureType !== 'emergency' && procedureType !== 'reset') {
                dispatch(
                    handleToast({
                        toastText: 'Procedura powinna skończyć się maksymalnie przed godziną 23:45',
                    }),
                );
            } else {
                onClose();
                dispatch(handleProcedureUpdate({ id, body }));
            }
        } else if (id && tempProcedure) {
            const time = {
                lastEndTime: moment(`${tempProcedure.startDate} ${tempProcedure.startTime}`),
            };
            const manualSelectedDayProcedures =
                procedures &&
                procedures.filter((procedure) => {
                    return (
                        procedure.procedureType === 'MANUAL' &&
                        procedure.status !== 'REJECTED' &&
                        procedure.status !== 'TERMINATED' &&
                        procedure.status != 'ERROR' &&
                        procedure.startDate === tempProcedure.startDate &&
                        ((procedure.status !== 'PLANNED' && procedure.status !== 'TO_BE_ACCEPTED') ||
                            ((procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                                moment().isBefore(
                                    moment(
                                        `${procedure.startDate} ${procedure.startTime}`,
                                        'YYYY-MM-DD HH:mm',
                                    ).subtract(1, 'hours'),
                                )))
                    );
                });
            const groups = groupsParams
                .filter((group) => group.visibility)
                .map((group, index) => {
                    const editedGroup = tempProcedure.groups.find((editedGroup) => editedGroup.name === group.name);

                    const controlCode = group.controlCodeAbs * (startOption === 'DSR +' ? 1 : -1);
                    const startItem = editedGroup?.items.find((item) =>
                        startOption === 'DSR +' ? item.controlCode > 0 : item.controlCode < 0,
                    );
                    const zeroItem = editedGroup?.items.find((item) => item.controlCode === 0);

                    const validEditedItems =
                        procedureType === 'emergency'
                            ? startItem
                                ? [startItem]
                                : addFirstBodyItem(editedGroup?.items, durationTime, false, controlCode)
                            : procedureType === 'reset'
                            ? zeroItem
                                ? [zeroItem]
                                : addFirstBodyItem(editedGroup?.items, zeroDurationTime, true, 0)
                            : addAllBodyItems(editedGroup?.items, zeroDurationTime, durationTime, controlCode);
                    const initTime = moment(`${tempProcedure.startDate} ${startHour}`, 'YYYY-MM-DD H');
                    const templateItems = tempProcedure.groups[0].items;
                    const startItemT = templateItems.find((item) =>
                        startOption === 'DSR +' ? item.controlCode > 0 : item.controlCode < 0,
                    );
                    const zeroItemT = templateItems.find((item) => item.controlCode === 0);
                    const validTemplateItems =
                        procedureType === 'emergency'
                            ? startItemT
                                ? [startItemT]
                                : addFirstBodyItem(templateItems, durationTime, false, controlCode)
                            : procedureType === 'reset'
                            ? zeroItemT
                                ? [zeroItemT]
                                : addFirstBodyItem(templateItems, zeroDurationTime, true, 0)
                            : addAllBodyItems(templateItems, zeroDurationTime, durationTime, controlCode);
                    return {
                        name: group.name, // group.name !== 'Raków' ? group.name.concat('_mock') : group.name,
                        items: validEditedItems.length
                            ? validEditedItems.map((item, itemIndex) => {
                                  return createItems(initTime, group, index, item, itemIndex, time);
                              })
                            : validTemplateItems.map((item, itemIndex) => {
                                  return createItems(initTime, group, index, item, itemIndex, time);
                              }),
                    };
                });

            const body = {
                name: procedureName,
                startDate: tempProcedure.startDate,
                endDate: time.lastEndTime.format('YYYY-MM-DD'),
                startTime: moment(startHour, 'H').format('HH:mm'),
                endTime: time.lastEndTime.format('HH:mm'),
                procedureType: procedureType.toUpperCase(),
                groups,
            };

            const isStartDateValid =
                !manualSelectedDayProcedures ||
                manualSelectedDayProcedures.every((procedure) => {
                    const startDateTime = moment(`${procedure.startDate} ${procedure.startTime}`);
                    const endDateTime = moment(`${procedure.endDate} ${procedure.endTime}`);
                    const startDateTimeFullHour = startDateTime.minute()
                        ? startDateTime.add(1, 'hour').startOf('hour')
                        : startDateTime.startOf('hour');
                    const endDateTimeFullHour = endDateTime.minute()
                        ? endDateTime.add(1, 'hour').startOf('hour')
                        : endDateTime.startOf('hour');
                    return (
                        moment(`${tempProcedure.startDate} ${body.startTime}`).isAfter(
                            endDateTimeFullHour.add(1, 'hour').subtract(1, 'second'),
                        ) ||
                        moment(`${tempProcedure.endDate} ${body.endTime}`).isBefore(
                            startDateTimeFullHour.subtract(1, 'hour').subtract(1, 'second'),
                        )
                    );
                });

            const isEndDateValid = time.lastEndTime.isBefore(
                moment(`${tempProcedure.startDate} ${tempProcedure.startTime}`)
                    .hours(23)
                    .minutes(45)
                    .seconds(1)
                    .milliseconds(0),
            );

            if (!isStartDateValid) {
                dispatch(
                    handleToast({
                        toastText:
                            'Procedura powinna zaczynać się co najmniej godzinę po poprzedzającej procedurze i kończyć godzinę przed następną procedurą',
                    }),
                );
            } else if (!isEndDateValid && procedureType !== 'emergency' && procedureType !== 'reset') {
                dispatch(
                    handleToast({
                        toastText: 'Procedura powinna skończyć się maksymalnie przed godziną 23:45',
                    }),
                );
            } else {
                onSaveTemp && onSaveTemp();
                onClose();
                dispatch(handleProcedureAdd(body));
            }
        } else {
            const catalogGroups = groupsParams
                .filter((group) => group.visibility)
                .map((group, index) => {
                    const catalogGroupItems = [];

                    if (procedureType !== 'reset') {
                        catalogGroupItems.push({
                            controlCode: group.controlCodeAbs * (startOption === 'DSR +' ? 1 : -1),
                            duration: durationTime,
                        });
                    }

                    if (procedureType !== 'emergency') {
                        catalogGroupItems.push({
                            controlCode: 0,
                            duration: zeroDurationTime,
                        });
                        if (procedureType !== 'reset') {
                            catalogGroupItems.push({
                                controlCode: group.controlCodeAbs * (startOption === 'DSR +' ? -1 : 1),
                                duration: durationTime,
                            });
                        }
                    }

                    return {
                        name: group.name,
                        timeDelay: groupDelay * index,
                        catalogGroupItems,
                    };
                });
            const body = {
                name: procedureName,
                procedureType: procedureType.toUpperCase(),
                catalogGroups,
            };

            const totalManualDuration =
                2 * durationTime + zeroDurationTime + (groupDelay * (catalogGroups.length - 1)) / 60;
            if (procedureType !== 'emergency' && procedureType !== 'reset' && totalManualDuration > 22.75) {
                dispatch(
                    handleToast({
                        toastText: 'Procedura optymalizacyjna nie powinna trwać dłużej niż 22 godziny 45 minut',
                    }),
                );
            } else {
                onClose();
                dispatch(handleProcedureCatalogAdd(body));
            }
        }
    };

    const handleGroupVisibilty = (groupId: number) => {
        const visibleLength = groupsParams.filter((group) => group.visibility).length;
        let tempGroupsParams = [...groupsParams];
        const index = tempGroupsParams.findIndex((group) => group.id === groupId);
        const visibilty = tempGroupsParams[index].visibility;
        if (visibilty) {
            tempGroupsParams = changeOrder(tempGroupsParams, index, 'r', visibleLength - 1);
            tempGroupsParams[visibleLength - 1].visibility = !visibilty;
        } else {
            tempGroupsParams[index].visibility = !visibilty;
        }
        setGroupsParams(tempGroupsParams);
    };

    const changeOrder = <T,>(inputArr: T[], groupIndex: number, direction: 'l' | 'r', position?: number) => {
        const tempGroupItem = inputArr.splice(groupIndex, 1);
        inputArr.splice(direction === 'l' ? groupIndex - 1 : position ? position : groupIndex + 1, 0, tempGroupItem[0]);
        return inputArr;
    };

    const changeGroupOrder = (groupIndex: number, direction: 'l' | 'r', position?: number) => {
        const tempGroupsParams = [...groupsParams];
        setGroupsParams(changeOrder(tempGroupsParams, groupIndex, direction, position));
    };

    const changeCodeControl = (groupId: number, ev: React.ChangeEvent<HTMLInputElement>) => {
        const tempGroupsParams = [...groupsParams];
        const index = tempGroupsParams.findIndex((group) => group.id === groupId);
        tempGroupsParams[index].controlCodeAbs = parseInt(ev.target.value);
        setGroupsParams(tempGroupsParams);
    };

    const renderProcedures = () => {
        return groupsParams.map((group, index) => {
            return renderProcedure(131 + index * 175, 143, index, group.id);
        });
    };

    const renderOverProcedures = () => {
        return groupsParams.map((group, index) => {
            return renderOverProcedure(131 + index * 175, 143, index, group.id);
        });
    };

    const renderGroups = () => {
        return (
            <Box className={styles.DSRConfigureGroupContainer}>
                <Gap size={spacing.s}>
                    {groupsParams.map((group, index) => {
                        if (group) {
                            return (
                                <div
                                    key={`controlCodeContainer${group.id}`}
                                    className={
                                        group.visibility
                                            ? styles.DSRRadioButtonGroup
                                            : styles.DSRRadioButtonGroupDisabled
                                    }
                                >
                                    <div className={styles.DSRConfigureOrderButtonContainer}>
                                        <IconButton
                                            status="plain"
                                            color={colors.inkGrey}
                                            size={24}
                                            icon={<IconChevronLeft />}
                                            onClick={() => changeGroupOrder(index, 'l')}
                                            disabled={
                                                index === 0 || !group.visibility || isRunning || isRunningEmergency
                                            }
                                        />
                                        <IconButton
                                            status="plain"
                                            color={colors.inkGrey}
                                            size={24}
                                            icon={<IconChevronRight />}
                                            onClick={() => changeGroupOrder(index, 'r')}
                                            disabled={
                                                index === groupsParams.filter((group) => group.visibility).length - 1 ||
                                                !group.visibility ||
                                                isRunning ||
                                                isRunningEmergency
                                            }
                                        />
                                    </div>
                                    {procedureType !== 'reset' && (
                                        <RadioButtonGroup
                                            items={[
                                                { label: `${startOption}1`, value: '1' },
                                                { label: `${startOption}2`, value: '2' },
                                            ]}
                                            disabled={!group.visibility || isRunning || isRunningEmergency}
                                            name={`controlCode${[group.id]}`}
                                            value={`${group.controlCodeAbs}`}
                                            onChange={(ev) => changeCodeControl(group.id, ev)}
                                            label={group.name.replace('_mock', '')}
                                        />
                                    )}
                                    {procedureType === 'reset' && (
                                        <ContentText>{group.name.replace('_mock', '')}</ContentText>
                                    )}
                                </div>
                            );
                        }
                    })}
                </Gap>
            </Box>
        );
    };

    const renderModalContent = () => {
        if (!groupNamesState.loading && groupNamesState.groupNamesList.length) {
            return (
                <Box className={styles.modalContent}>
                    <Heading className={styles.heading} level={5}>
                        {editedProcedure && !tempProcedure ? 'Edytuj procedurę DSR' : 'Skonfiguruj procedurę DSR'}
                    </Heading>
                    <Box pt={spacing.xs} pb={spacing.xxs} bb={`1px solid ${colors.silverGrey}`}>
                        <Gap size={26}>
                            <InputField
                                name="procedureName"
                                label="Nazwa procedury"
                                placeholder="Nowa procedura"
                                disabled={isRunning || isRunningEmergency}
                                value={procedureName}
                                onChange={(ev) => setProcedureName(ev.target.value)}
                                className={styles.inputField}
                            />
                            {(procedureManualFound || tempProcedure) && (
                                <div className={styles.inputField}>
                                    <Select
                                        name="startHour"
                                        label="Godzina rozpoczęcia"
                                        placeholder="wybierz"
                                        disabled={isRunning || isRunningEmergency}
                                        onChange={handleStartHourChange}
                                        selected={startHour}
                                        items={startHours}
                                    />
                                </div>
                            )}
                            {procedureType !== 'reset' && (
                                <div className={styles.inputField}>
                                    <Select
                                        name="durationTime"
                                        label="Czas trwania"
                                        placeholder="wybierz"
                                        disabled={isRunning}
                                        onChange={handleDurationTime}
                                        selected={durationTime}
                                        items={durationItems}
                                    />
                                </div>
                            )}
                            {procedureType !== 'emergency' && procedureType !== 'reset' && (
                                <div className={styles.inputField}>
                                    <Select
                                        name="zeroDurationTime"
                                        label="Czas trwania 0"
                                        placeholder="wybierz"
                                        onChange={handleZeroDurationTime}
                                        selected={zeroDurationTime}
                                        items={zeroDurationItems}
                                    />
                                </div>
                            )}
                            <div className={styles.inputField}>
                                <Select
                                    name="groupDelay"
                                    label="Kolejne grupy włączane co"
                                    placeholder="wybierz"
                                    disabled={isRunning || isRunningEmergency}
                                    onChange={handleGroupDelayChange}
                                    selected={groupDelay}
                                    marginBottom={spacing.xxs}
                                    items={mItems}
                                />
                            </div>
                        </Gap>
                    </Box>
                    <Box
                        pt={spacing.xs}
                        pb={spacing.xs}
                        bb={`1px solid ${colors.silverGrey}`}
                        className={styles.DSRHRadioButtonGroup}
                    >
                        <RadioButtonGroup
                            items={[
                                { label: 'Optymalizacyjna', value: 'manual' },
                                { label: 'Awaryjna', value: 'emergency' },
                                { label: 'Zerująca', value: 'reset' },
                            ]}
                            name="procedureType"
                            value={procedureType}
                            disabled={isRunning || isRunningEmergency}
                            onChange={changeProcedureType}
                            label="Typ procedury:"
                            wrapperStyle={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle' }}
                        />
                    </Box>
                    {procedureType !== 'reset' && (
                        <Box
                            pt={spacing.xs}
                            pb={spacing.xs}
                            bb={`1px solid ${colors.silverGrey}`}
                            className={styles.DSRHRadioButtonGroup}
                        >
                            <RadioButtonGroup
                                items={[
                                    { label: 'DSR +', value: 'DSR +' },
                                    { label: 'DSR -', value: 'DSR -' },
                                ]}
                                name="startOption"
                                value={startOption}
                                disabled={isRunning || isRunningEmergency}
                                onChange={handleStartOptionChange}
                                label="Scenariusz startowy:"
                                wrapperStyle={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle' }}
                            />
                        </Box>
                    )}
                    {renderGroups()}
                    <Box>
                        <svg className={styles.ProcedureGraph} viewBox={`0 0 ${width} ${height}`} height={height}>
                            {renderProcedures()}
                            <line className={styles.DSRInputTableGraphHorizontalLine} x1={0} y1={0} x2={width} y2={0} />
                            {renderLines()}
                            {renderOverProcedures()}
                        </svg>
                    </Box>
                    <Box className={styles.DSRConfigureSaveButton}>
                        <Button onClick={handleSaveBtn}>{'Zapisz'}</Button>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    return (
        <Modal
            className={procedures || tempProcedure ? styles.modalManual : styles.modal}
            alignContent="left"
            opened={true}
            onClose={onClose}
            noPadding={true}
        >
            {renderModalContent()}
        </Modal>
    );
};
