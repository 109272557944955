import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { logger } from 'src/utils';
import { store } from 'src/providers/store';
import { clearAuthToken } from 'src/providers/auth';
import { useNavigate } from 'react-router-dom';

const API_URL =
    process.env.REACT_APP_ENV === 'prod'
        ? 'https://vasprod.aws.fortum.com/dsr-service/v1/'
        : 'https://vastest.aws.fortum.com/dsr-service/v1/';

const parseBody = (response: AxiosResponse) => Promise.resolve(response.data);
const parseError = (message: string) => Promise.reject(message);

const requestInterceptor = (request: InternalAxiosRequestConfig) => {
    logger.info('[AXIOS] Starting request', request);
    logger.time('[AXIOS] Response from ' + request.url + ' took');

    const token = store.getState().auth.token;
    request.headers.Authorization = `Bearer ${token}`;
    return request;
};

const responseSuccessInterceptor = (response: AxiosResponse) => {
    logger.timeEnd('[AXIOS] Response from ' + response.config.url + ' took');
    logger.success('[AXIOS] Obtained response:', response);
    return parseBody(response);
};

const responseErrorInterceptor = (response: AxiosError) => {
    logger.error('[AXIOS] Obtained error:', response);
    const navigate = useNavigate();

    if (response.response && response.response.status === 401) {
        store.dispatch(clearAuthToken());
        navigate('/unauthorized');
    }

    return parseError(response.message);
};

const instance: AxiosInstance = axios.create({
    baseURL: API_URL,
});

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

const makeRequest = <T>(config: AxiosRequestConfig): Promise<T> => instance.request(config);

export default makeRequest;
