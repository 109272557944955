import React, { FunctionComponent, useState } from 'react';
import { useDrag } from 'react-dnd';
import {
    Box,
    colors,
    ContentText,
    IconButton,
    IconEdit,
    IconInfo,
    Popover,
    spacing,
    usePopover,
} from '@fortum/elemental-ui';
import moment, { Moment } from 'moment';

import styles from './Procedure.module.scss';
import { CatalogProcedureProps, DropResult, GroupBody, ItemBody } from './Procedure.types';

export const ProcedureComponent: FunctionComponent<CatalogProcedureProps> = (props: CatalogProcedureProps) => {
    const groupAmount = props.catalogGroups.length;
    const catalogGroupItems = props.catalogGroups[0].catalogGroupItems;
    const groupTimeDelay = props.catalogGroups[1] ? props.catalogGroups[1].timeDelay : 0;
    const dsrFirstItemControlCode = catalogGroupItems[0].controlCode;
    const controlCodeABS = Math.abs(dsrFirstItemControlCode);
    const dsrOrder = dsrFirstItemControlCode > 0 ? 'PM' : dsrFirstItemControlCode < 0 ? 'MP' : 'Z';

    if (!dsrOrder || groupTimeDelay === undefined) return null;

    const dsrPlusItem = catalogGroupItems.find((i) => i.controlCode > 0);
    const dsrMinusItem = catalogGroupItems.find((i) => i.controlCode < 0);
    const dsrZeroItem = catalogGroupItems.find((i) => i.controlCode === 0);

    const totalDelayTime = (groupAmount - 1) * (groupTimeDelay / 60);
    const dsrPlusDuration = dsrPlusItem ? dsrPlusItem.duration + totalDelayTime : 0;
    const dsrMinusDuration = dsrMinusItem ? dsrMinusItem.duration + totalDelayTime : 0;
    const dsrZeroDuration = dsrZeroItem
        ? props.procedureType === 'RESET'
            ? dsrZeroItem.duration + totalDelayTime
            : dsrZeroItem.duration - totalDelayTime
        : 0;
    const procedureDuration = dsrPlusDuration + dsrMinusDuration + dsrZeroDuration;

    const dsrWidth = 24;
    const hourHeigth = 8;
    const canvasWidth = 216;
    const canvasHeight = hourHeigth * procedureDuration;
    const canvasTop = 0;
    const canvasLeft = 0;
    const dsrPlusHeight = hourHeigth * dsrPlusDuration;
    const dsrMinusHeight = hourHeigth * dsrMinusDuration;
    const dsrZeroHeight = hourHeigth * dsrZeroDuration;
    const dsrPlusY = dsrOrder === 'PM' ? 0 : canvasHeight - dsrPlusHeight;
    const dsrMinusY = dsrOrder === 'PM' ? canvasHeight - dsrMinusHeight : 0;
    const xText = dsrWidth + 25;
    const yText1 = dsrOrder === 'PM' ? dsrPlusHeight / 2 : dsrMinusHeight / 2;
    const yText2 = dsrOrder === 'PM' ? dsrPlusHeight + dsrZeroHeight / 2 : dsrMinusHeight + dsrZeroHeight / 2;
    const yText3 = dsrOrder === 'PM' ? canvasHeight - dsrMinusHeight / 2 : canvasHeight - dsrPlusHeight / 2;
    const labelText1 =
        dsrOrder === 'PM'
            ? dsrPlusItem
                ? dsrPlusItem.duration
                : undefined
            : dsrMinusItem
            ? dsrMinusItem.duration
            : undefined;
    const labelText2 = dsrZeroItem ? dsrZeroItem.duration : undefined;
    const labelText3 =
        dsrOrder === 'PM'
            ? dsrMinusItem
                ? dsrMinusItem.duration
                : undefined
            : dsrPlusItem
            ? dsrPlusItem.duration
            : undefined;
    const xTextTime = dsrWidth + 152;
    const { open, anchor, handleOpen, handleClose } = usePopover();
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    const showMenu = () => {
        setIsMenuVisible(true);
    };

    const hideMenu = () => {
        setIsMenuVisible(false);
        handleClose();
    };

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'procedure',
        item: { id: props.id, procedureDuration, procedureType: props.procedureType },
        end: (item, monitor) => {
            const dropResult: DropResult | null = monitor.getDropResult();
            if (item && dropResult) {
                let end: Moment = moment();
                const groups: GroupBody[] = [];
                const bodyStart = moment(`${dropResult.date} ${dropResult.startHour}`, 'YYYY-MM-DD H');
                props.catalogGroups.forEach((group) => {
                    const items: ItemBody[] = [];
                    group.catalogGroupItems.forEach((item, index) => {
                        const start = index === 0 ? bodyStart.clone().add(group.timeDelay, 'minutes') : end;
                        end = start.clone().add(item.duration, 'hours');
                        items.push({
                            startDate: start.format('YYYY-MM-DD'),
                            startTime: start.format('HH:mm'),
                            endDate: end.format('YYYY-MM-DD'),
                            endTime: end.format('HH:mm'),
                            controlCode: item.controlCode,
                        });
                    });
                    groups.push({
                        name: group.name,
                        items,
                    });
                });
                const body = {
                    name: props.name,
                    startDate: bodyStart.format('YYYY-MM-DD'),
                    startTime: bodyStart.format('HH:mm'),
                    endDate: end.format('YYYY-MM-DD'),
                    endTime: end.format('HH:mm'),
                    procedureType: props.procedureType,
                    groups,
                };
                props.setTempProcedure(body);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    const opacity = isDragging ? 0.4 : 1;
    const height = canvasHeight;

    return (
        <Box marginBottom={spacing.xs}>
            <ContentText marginBottom={spacing.xxxs} className={styles.ProcedureName}>
                {props.name}
            </ContentText>
            <div
                className={styles.ProcedureContainer}
                style={{ opacity, height }}
                ref={drag}
                data-testid="procedure"
                onMouseOver={showMenu}
                onMouseLeave={hideMenu}
            >
                {isMenuVisible && (
                    <Box className={styles.ProcedureContainerMenu}>
                        <IconButton
                            color={colors.fullBlack}
                            style={{ marginRight: spacing.xxs }}
                            status="plain"
                            icon={<IconInfo size={20} />}
                            size={20}
                            onClick={handleOpen}
                        />
                        <IconButton
                            color={colors.fullBlack}
                            status="plain"
                            icon={<IconEdit size={20} />}
                            size={20}
                            onClick={props.handleEdit}
                        />
                    </Box>
                )}
                <Popover
                    backgroundColor={colors.snowWhite}
                    onClose={handleClose}
                    opened={open}
                    anchor={anchor}
                    anchorPos="bottom"
                    padding={spacing.xxs}
                >
                    <ContentText size={14}>Procedura uruchamiana w kolejności:</ContentText>
                    {props.catalogGroups.map((group, index) => (
                        <section className={styles.sectionRow} key={`s${index}`}>
                            <ContentText size={14}>
                                {`${index + 1}. ${group.name}`}
                                <span style={{ fontStyle: 'italic' }}>
                                    {group.timeDelay > 0 ? ` (+ ${group.timeDelay} min.)` : ''}
                                </span>
                            </ContentText>
                            <span className={styles.dotsBeetwen}></span>
                            <ContentText size={14} weight={700}>
                                {`DSR${group.catalogGroupItems[0].controlCode > 0 ? ' +' : ' '}${
                                    group.catalogGroupItems[0].controlCode
                                }`}
                            </ContentText>
                        </section>
                    ))}
                    <br />
                    {props.procedureType !== 'EMERGENCY' &&
                        props.procedureType !== 'RESET' &&
                        props.catalogGroups.map((group, index) => (
                            <section className={styles.sectionRow} key={`s${index}`}>
                                <ContentText size={14}>
                                    {`${index + 1}. ${group.name}`}
                                    <span style={{ fontStyle: 'italic' }}>
                                        {group.timeDelay > 0 ? ` (+ ${group.timeDelay} min.)` : ''}
                                    </span>
                                </ContentText>
                                <span className={styles.dotsBeetwen}></span>
                                <ContentText size={14} weight={700}>
                                    {`DSR${group.catalogGroupItems[2].controlCode > 0 ? ' +' : ' '}${
                                        group.catalogGroupItems[2].controlCode
                                    }`}
                                </ContentText>
                            </section>
                        ))}
                </Popover>
                <svg
                    className={styles.ProcedureGraph}
                    viewBox={`${canvasLeft} ${canvasTop} ${canvasWidth} ${canvasHeight}`}
                    height={canvasHeight}
                >
                    <g>
                        <rect
                            className={styles.ProcedureGraphProcedureItemPlanned}
                            x={0}
                            y={0}
                            width={canvasWidth}
                            height={canvasHeight}
                        />
                        <rect
                            className={styles.ProcedureGraphProcedureChangePositive}
                            x={0}
                            y={dsrPlusY}
                            width={dsrWidth}
                            height={dsrPlusHeight}
                        />
                        <rect
                            className={styles.ProcedureGraphProcedureChangeNegative}
                            x={0}
                            y={dsrMinusY}
                            width={dsrWidth}
                            height={dsrMinusHeight}
                        />
                        <text
                            className={styles.ProcedureGraphItemControlCode}
                            x={xText}
                            y={yText1}
                            dominantBaseline="middle"
                            textAnchor="start"
                        >
                            <tspan>{'DSR '}</tspan>
                            <tspan className={styles.ProcedureGraphItemControlCodeBold}>{`${
                                dsrOrder === 'PM' ? '+' : dsrOrder === 'MP' ? '-' : ''
                            }${controlCodeABS}`}</tspan>
                        </text>
                        {!!labelText2 && (
                            <text
                                className={styles.ProcedureGraphItemControlCode}
                                x={xText}
                                y={yText2}
                                dominantBaseline="middle"
                                textAnchor="start"
                            >
                                <tspan>{'DSR '}</tspan>
                                <tspan className={styles.ProcedureGraphItemControlCodeBold}>{'0'}</tspan>
                            </text>
                        )}
                        {!!labelText3 && (
                            <text
                                className={styles.ProcedureGraphItemControlCode}
                                x={xText}
                                y={yText3}
                                dominantBaseline="middle"
                                textAnchor="start"
                            >
                                <tspan>{'DSR '}</tspan>
                                <tspan className={styles.ProcedureGraphItemControlCodeBold}>{`${
                                    dsrOrder === 'PM' ? '-' : '+'
                                }${controlCodeABS}`}</tspan>
                            </text>
                        )}
                    </g>
                    {!!labelText1 && (
                        <text
                            className={styles.ProcedureGraphItemControlCode}
                            x={xTextTime}
                            y={yText1}
                            dominantBaseline="middle"
                            textAnchor="start"
                        >
                            {`${labelText1}h`}
                        </text>
                    )}
                    {!!labelText2 && (
                        <text
                            className={styles.ProcedureGraphItemControlCode}
                            x={xTextTime}
                            y={yText2}
                            dominantBaseline="middle"
                            textAnchor="start"
                        >
                            {`${labelText2}h`}
                        </text>
                    )}
                    {!!labelText3 && (
                        <text
                            className={styles.ProcedureGraphItemControlCode}
                            x={xTextTime}
                            y={yText3}
                            dominantBaseline="middle"
                            textAnchor="start"
                        >
                            {`${labelText3}h`}
                        </text>
                    )}
                </svg>
            </div>
        </Box>
    );
};
