import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '@fortum/elemental-ui/FortumSans.css';
import {
    Box,
    Button,
    DatePicker,
    Gap,
    IconButton,
    IconChevronLeft,
    IconChevronRight,
    Loader,
    spacing,
} from '@fortum/elemental-ui';
import { ArchiveIcon } from 'src/components/CustomIcon/CustomIcon';
import { DSROverviewTable } from 'src/components/DSROverviewTable/DSROverviewTable';
import { AppDispatch } from 'src/providers/store';

import * as day from 'src/utils/day';

import { Legend } from 'src/components/Legend/Legend';

import { ProceduresState, Procedures } from './ProceduresList.types';
import { fetchProcedures } from './ProceduresList.thunk';

const ProceduresList: FunctionComponent = () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    const dispatch = useDispatch<AppDispatch>();
    const proceduresState = useSelector<ProceduresState>((state) => state.procedures) as Procedures;
    const [selectedDate, setSelectedDate] = useState<Date>(todayDate);

    useEffect(() => {
        dispatch(fetchProcedures(selectedDate));
    }, [selectedDate]);

    const maxDate = day.add(todayDate, 2);

    const dayAfterSelectedDate = day.next(selectedDate);

    const canSelectNextDay = !(dayAfterSelectedDate <= maxDate);

    const changeSelectedDateToPreviousDay = () => {
        setSelectedDate(day.previous(selectedDate));
    };

    const changeSelectedDateToNextDay = () => {
        setSelectedDate(day.next(selectedDate));
    };

    const updateSelectedDate = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    return (
        <>
            <Box
                marginTop={spacing.s}
                marginBottom={spacing.xs}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={spacing.xs}
            >
                <Button status="secondary" leftIcon={ArchiveIcon} disabled>{`Archiwum procedur DSR`}</Button>
                <Gap size={spacing.xxs}>
                    <IconButton
                        invert
                        status="plain"
                        icon={<IconChevronLeft />}
                        size="64px"
                        aria-label="Wybierz wczesniejszy dzień"
                        onClick={changeSelectedDateToPreviousDay}
                    />
                    <DatePicker
                        label="Dzień"
                        name="date"
                        onChange={(selectedDate) => updateSelectedDate(selectedDate)}
                        maxDate={maxDate}
                        selected={selectedDate}
                        borderStyle="full"
                        locale="en"
                    />
                    <IconButton
                        invert
                        status="plain"
                        icon={<IconChevronRight />}
                        size="64px"
                        aria-label="Wybierz późniejszy dzień"
                        disabled={canSelectNextDay}
                        onClick={changeSelectedDateToNextDay}
                    />
                </Gap>
            </Box>
            <Box>
                <Legend onInitOpened={true} withoutTrigger={false} />
            </Box>
            {proceduresState.loading ? (
                <Box marginTop={spacing.xl} marginBottom={spacing.m} display="flex" justifyContent="center">
                    <Loader />
                </Box>
            ) : (
                <Box marginTop={spacing.xs} marginBottom={spacing.xxs}>
                    <DSROverviewTable procedures={proceduresState.proceduresList} date={selectedDate} />
                </Box>
            )}
        </>
    );
};

export default ProceduresList;
