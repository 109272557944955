import React from 'react';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import {baseURL, clientID, origin} from "src/auth";

export const authorizationConfiguration: TAuthConfig = {
    clientId: clientID,
    authorizationEndpoint: `${origin}/oauth2/authorize`,
    tokenEndpoint: `${origin}/oauth2/token`,
    redirectUri: `${baseURL}/authorizationRedirect`,
    scope: 'email openid profile',
};

type AuthorizationComponentProps = {
    children?: React.ReactNode;
};

export const AuthorizationComponent: React.FC<AuthorizationComponentProps> = ({ children }) => {
    return <AuthProvider authConfig={authorizationConfiguration}>{children}</AuthProvider>;
};
