import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProcedures } from 'src/api/requests';
import { logger } from 'src/utils';
import { ProceduresResponse } from 'src/api/model/ProceduresResponse';

const fetchPayloadCreator = async (date?: Date): Promise<ProceduresResponse | undefined> => {
    try {
        logger.log('[TODO] try to getProcedures');
        /* This is so bad, because of timezones. Using date is bad. */
        return await getProcedures(
            date
                ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`
                : undefined,
        );
    } catch {
        // handle error
        logger.error('[TODO] getProcedures failed');
    }
};

const fetchProcedures = createAsyncThunk('procedures/get', fetchPayloadCreator);

export { fetchProcedures, fetchPayloadCreator };
