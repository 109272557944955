import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Box, Loader, spacing } from '@fortum/elemental-ui';

import { ProductionPlanGraph } from 'src/components/ProductionPlanGraph/ProductionPlanGraph';

import { GraphState, GraphData } from './ProductionPlans.types';

const ProductionPlans: FunctionComponent = () => {
    const graphState = useSelector<GraphState>((state) => state.graphData) as GraphData;

    return (
        <>
            {graphState.loading ? (
                <Box marginTop={spacing.xl} marginBottom={spacing.m} display="flex" justifyContent="center">
                    <Loader />
                </Box>
            ) : (
                <Box>{graphState.graph && <ProductionPlanGraph graph={graphState.graph} />}</Box>
            )}
        </>
    );
};

export default ProductionPlans;
