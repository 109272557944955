import { Procedure } from 'src/api/model/Procedure';

export type ProcedureDetailsModalProps = {
    onClose: () => void;
    id: number;
    date: Date;
    reasonForced?: 'reject' | 'terminate' | null;
};

export type ModalSteps = 'details' | 'rejection' | 'terminationReason' | 'terminationConsequences';

export type ProcedureDetailsModalToast = {
    toastText: string | null;
    toastLinkCopy?: string | null;
    toastLinkTarget?: string | null;
};

export type ProcedureDetails = {
    details: Procedure | null;
    loading: boolean;
};

export type ProcedureDetailsState = {
    procedureDetails: ProcedureDetails;
};

export type ProcedureDetailsModalState = {
    toast: ProcedureDetailsModalToast;
};

export type StatusChangeParams = {
    id: number;
    type: 'accept' | 'reject' | 'terminate';
    message: string;
    date: Date;
};

export const toastDataAccept = {
    toastText: 'Procedura została zaakceptowana.',
    toastLinkCopy: '',
};

export const toastDataReject = {
    toastText: 'Procedura została odrzucona i przeniesiona do archiwum.',
    toastLinkCopy: 'Przejdź do archiwum procedur DSR',
    toastLinkTarget: 'testRoute',
};

export const toastDataTerminate = {
    toastText: 'Procedura została zatrzymana.',
    toastLinkCopy: null,
};
