import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Box, colors, ContentText, Modal } from '@fortum/elemental-ui';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';

import { Graph, ExternalParam, ExternalParamValue, ForecaParam, ForecaParamValue } from 'src/api/model/Graph';

import { ForecaIcon } from 'src/components/ForecaIcon/ForecaIcon';

import { Procedure, ProcedureStatus } from 'src/api/model/Procedure';
import { HistoryMessages } from 'src/components/ProcedureDetailsModal/HistoryTable.types';
import { ProcedureDetailsModal } from 'src/components/ProcedureDetailsModal/ProcedureDetailsModal';
import { clearDetails } from 'src/components/ProcedureDetailsModal/ProcedureDetailsModal.slice';

import { Legend } from 'src/components/Legend/Legend';

import styles from './ProductionPlanGraph.module.scss';

type ProductionPlanGraphProps = {
    graph: Graph;
};

type CompletedItem = {
    x: number;
    width: number;
    controlCode: number;
};

function timeToX(hourWidth: number, time?: string): number {
    const ratio = hourWidth / (60 * 60 * 1000);
    const momentTime = time ? moment(time, 'YYYY-MM-DD HH:mm') : moment();
    return (momentTime.toDate().getTime() - moment().startOf('day').toDate().getTime()) * ratio;
}

function procedureStyleClass(procedure: Procedure) {
    switch (procedure.status) {
        case 'PLANNED':
            return styles.ProductionPlanGraphProcedureItemPlanned;
        case 'COMPLETED':
            return styles.ProductionPlanGraphProcedureItemCompleted;
        case 'RUNNING':
            return styles.ProductionPlanGraphProcedureItemToBeRunning;
        case 'TO_BE_ACCEPTED':
            return styles.ProductionPlanGraphProcedureItemToBeAccepted;
        case 'ACCEPTED':
            return styles.ProductionPlanGraphProcedureItemAccepted;
        case 'ERROR':
            return styles.ProductionPlanGraphProcedureItemError;
        case 'REJECTED':
            return styles.ProductionPlanGraphProcedureItemRejected;
        case 'TERMINATED':
            return styles.ProductionPlanGraphProcedureItemTerminated;
        default:
            return styles.ProductionPlanGraphProcedureItemUndefined;
    }
}

export const ProductionPlanGraph: FunctionComponent<ProductionPlanGraphProps> = (props: ProductionPlanGraphProps) => {
    const yStart = 20;
    const columnAmount = 48;
    const columnWidth = 69;
    const width = columnAmount * columnWidth + 1;
    const height = 542 + yStart;
    const graphVisibleWidth = 621;
    const energyGraphStart = 24 + yStart;
    const energyGraphHeight = 189;
    const energyTopMargin = 12;
    const energyBottomMargin = 29;
    const tempMargin = 24;
    const dsrGraphStart = 398 + yStart;
    const dsrGraphHeight = 40;
    const dsrControlCodeHeight = 16;
    const airTempStart = 454 + yStart;
    const timeBubbleRadius = 4;
    const timeBubbleHeight = 26;
    const timeBubbleWidth = 39;
    const timeBubbleArrow = 5;

    const [procedureDetailsOpened, setProcedureDetailsOpened] = React.useState<null | number>(null);
    const [legendOpened, setLegendOpened] = React.useState<boolean>(false);

    const scrollableBox = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (scrollableBox.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            scrollableBox.current.scrollLeft = Math.floor(timeToX(columnWidth) / columnWidth) * columnWidth;
        }
    }, []);

    const { graph } = props;

    const energyMinValues: number[] = [];
    const energyMaxValues: number[] = [];
    const tempMinValues: number[] = [];
    const tempMaxValues: number[] = [];
    graph.externalParameters.forEach((el, index) => {
        const values = el.values.map((param: ExternalParamValue) => param.value);
        if (values.length) {
            const min = Math.min(...values);
            const max = Math.max(...values);
            if (index < 3) {
                energyMinValues.push(min);
                energyMaxValues.push(max);
            } else if (index < 6) {
                tempMinValues.push(min);
                tempMaxValues.push(max);
            }
        }
    });

    const energyMax = Math.max(...energyMaxValues);

    const energyValueRange = {
        min: Math.min(...energyMinValues),
        max: energyMax > 80 && energyMax < 120 ? 120 : energyMax,
    };

    const tempValueRange = {
        min: Math.min(...tempMinValues),
        max: Math.max(...tempMaxValues),
    };

    const energyValueHeight = energyValueRange.max - energyValueRange.min;
    const tempValueHeight = tempValueRange.max - tempValueRange.min;
    const tempGraphHeight = dsrGraphStart - (energyGraphHeight + energyGraphStart);

    const handleButtonTrigger = () => {
        setLegendOpened((prevState) => !prevState);
    };

    const handleModal = (procedureId: number | null) => {
        setProcedureDetailsOpened(procedureId);
        if (!procedureId) {
            dispatch(clearDetails());
        }
    };

    const renderDSR = (
        x: number,
        width: number,
        controlCode: number,
        className: string,
        procedureStatus: ProcedureStatus,
        historyMessages: HistoryMessages[],
        completedItems: CompletedItem[],
        showDSR0?: boolean,
    ) => {
        return (
            <g key={`DSR${x}`}>
                <rect x={x} y={dsrGraphStart} width={width} height={dsrGraphHeight} className={className} />
                {completedItems.map((item, index) =>
                    item !== undefined && item.controlCode === controlCode ? (
                        <rect
                            x={item.x}
                            y={dsrGraphStart}
                            width={item.width}
                            height={dsrGraphHeight}
                            className={styles.ProductionPlanGraphProcedureItemCompleted}
                            key={`DSR${x}-completed-${index}`}
                        />
                    ) : null,
                )}
                <rect
                    x={x}
                    y={dsrGraphStart + (dsrGraphHeight - dsrControlCodeHeight)}
                    width={width}
                    height={dsrControlCodeHeight}
                    fill={controlCode > 0 ? '#EB6437' : '#406AAD'}
                />
                <text
                    x={x + columnWidth / 2}
                    y={dsrGraphStart + (dsrGraphHeight - dsrControlCodeHeight / 2)}
                    className={styles.ProductionPlanGraphDSRText}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {`DSR ${controlCode > 0 ? `+${controlCode}` : controlCode}`}
                </text>
                {showDSR0 && renderDSR0(x + width, procedureStatus, historyMessages)}
            </g>
        );
    };

    const renderDSR0 = (x: number, procedureStatus: ProcedureStatus, historyMessages: HistoryMessages[]) => {
        return (
            <>
                <text
                    x={x + columnWidth / 2}
                    y={dsrGraphStart + (dsrGraphHeight - dsrControlCodeHeight / 2)}
                    className={styles.ProductionPlanGraphDSR0Text}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {`DSR 0`}
                </text>
                {historyMessages.some((message) => message.messageType === 'ERROR') && (
                    <use
                        xlinkHref="#ProductionPlanGraphErrorSymbol"
                        x={x + columnWidth / 2 - 7}
                        y={dsrGraphStart + (dsrGraphHeight - dsrControlCodeHeight) / 2 - 7}
                        className={styles.ProductionPlanGraphErrorIcon}
                    >
                        <title>
                            {historyMessages.map((message, index) => {
                                return (
                                    <React.Fragment key={`${x}-${index}`}>
                                        {message.messageType === 'ERROR' && (
                                            <>
                                                {message.text}
                                                {'\n'}
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </title>
                    </use>
                )}
            </>
        );
    };

    const renderDSRs = () => {
        return graph.procedureList.procedures
            .filter(
                (procedure) =>
                    procedure.status !== 'REJECTED' &&
                    procedure.status !== 'PLANNED' &&
                    procedure.status !== 'TO_BE_ACCEPTED',
            )
            .map((procedure, index) => {
                const x = timeToX(columnWidth, `${procedure.startDate} ${procedure.startTime}`);
                const x1 = timeToX(columnWidth, `${procedure.endDate} ${procedure.endTime}`);
                const width = x1 - x;
                const completedItems = procedure.groups
                    .flatMap((group) => group.items)
                    .filter((item) => item.status === 'COMPLETED' && item.controlCode !== 0)
                    .map((item) => {
                        const x = timeToX(columnWidth, `${item.startDate} ${item.startTime}`);
                        const x1 = timeToX(columnWidth, `${item.endDate} ${item.endTime}`);
                        const width = x1 - x;
                        return {
                            x,
                            width,
                            controlCode: item.controlCode,
                        };
                    });
                return (
                    <g
                        key={`procedure${index}`}
                        onClick={() => handleModal(procedure.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <rect x={x} y={dsrGraphStart} width={width} height={dsrGraphHeight} fill={'transparent'} />
                        {[...procedure.procedureChanges]
                            .sort((a, b) => moment(a.startTime, 'HH:mm').unix() - moment(b.startTime, 'HH:mm').unix())
                            .map((change, changeIndex) => {
                                const x = timeToX(columnWidth, `${change.startDate} ${change.startTime}`);
                                const x1 = timeToX(columnWidth, `${change.endDate} ${change.endTime}`);
                                const width = x1 - x;
                                return renderDSR(
                                    x,
                                    width,
                                    change.controlCode,
                                    procedureStyleClass(procedure),
                                    procedure.status,
                                    procedure.historyMessages,
                                    completedItems,
                                    !changeIndex,
                                );
                            })}
                    </g>
                );
            });
    };

    const renderTime = () => {
        const currentDateX = timeToX(columnWidth);
        return (
            <g>
                <line
                    className={styles.ProductionPlanGraphTime}
                    x1={currentDateX}
                    y1={0}
                    x2={currentDateX}
                    y2={height - yStart}
                />
                <rect
                    className={styles.ProductionPlanGraphTimeBubble}
                    x={currentDateX - 0.5 * timeBubbleWidth}
                    y={0}
                    width={timeBubbleWidth}
                    height={timeBubbleHeight - timeBubbleArrow}
                    rx={timeBubbleRadius}
                    ry={timeBubbleRadius}
                />
                <path
                    className={styles.ProductionPlanGraphTimeBubble}
                    d={`M${currentDateX} ${timeBubbleHeight} l ${-timeBubbleArrow} ${-timeBubbleArrow} h ${
                        2 * timeBubbleArrow
                    }`}
                />
                <text
                    className={styles.ProductionPlanGraphDSRText}
                    x={currentDateX}
                    y={0.5 * (timeBubbleHeight - timeBubbleArrow)}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {moment().format('HH:mm')}
                </text>
            </g>
        );
    };

    const mapValueToXYPoint = (ratio: number, bottom: boolean, value: ExternalParamValue, index: number) => {
        const x = Math.round(columnWidth / 2) + columnWidth * index;
        const y = bottom
            ? dsrGraphStart - tempMargin - (value.value - tempValueRange.min) * ratio
            : energyGraphStart + energyGraphHeight - energyBottomMargin - (value.value - energyValueRange.min) * ratio;
        return `${x},${y}`;
    };

    const renderChartLabels = (bottom = false) => {
        const index = bottom ? 3 : 0;
        const ratio = bottom
            ? (tempGraphHeight - 2 * tempMargin) / tempValueHeight
            : (energyGraphHeight - energyTopMargin - energyBottomMargin) / energyValueHeight;
        const pointsChart1 = graph.externalParameters[index + 0].values.map(
            mapValueToXYPoint.bind(null, ratio, bottom),
        );
        const pointsChart2 = graph.externalParameters[index + 1].values.map(
            mapValueToXYPoint.bind(null, ratio, bottom),
        );
        const pointsChart3 = graph.externalParameters[index + 2].values.map(
            mapValueToXYPoint.bind(null, ratio, bottom),
        );

        const values1 = graph.externalParameters[index + 0].values.map((value) => Math.round(value.value));
        const values2 = graph.externalParameters[index + 1].values.map((value) => Math.round(value.value));
        const values3 = graph.externalParameters[index + 2].values.map((value) => Math.round(value.value));

        return pointsChart2.map((value, i) => {
            const data2 = value.split(',');
            const data1 = pointsChart1[i] && pointsChart1[i].split(',');
            const data3 = pointsChart3[i] && pointsChart3[i].split(',');
            const d1 = (data1 && data2 && parseFloat(data1[1]) - parseFloat(data2[1])) || 0;
            const d2 = (data2 && data3 && parseFloat(data2[1]) - parseFloat(data3[1])) || 0;
            const y1 = data1 && parseFloat(data1[1]) + (d1 < -30 ? 15 : d1 > 0 ? 15 : -15);
            const y2 = data2 && parseFloat(data2[1]) + (d1 > 30 ? 15 : d1 < 0 ? 15 : -15);
            const y3 = data3 && parseFloat(data3[1]) + (d2 < -30 ? 15 : d2 < 15 ? 15 : -15);
            return (
                <React.Fragment key={`text-point-${i}`}>
                    {y1 && data1 && values1[i] && (
                        <text
                            x={parseFloat(data1[0])}
                            y={
                                data3 && y1 < parseFloat(data3[1]) + 15 && y1 >= parseFloat(data3[1])
                                    ? y1 + 13
                                    : data3 && y1 > parseFloat(data3[1]) - 15 && y1 < parseFloat(data3[1])
                                    ? y1 - 13
                                    : y1
                            }
                            className={styles.ProductionPlanGraphValue}
                            fill={'#EB6437'}
                            dominantBaseline="middle"
                            textAnchor="middle"
                        >
                            {`${values1[i]} ${graph.externalParameters[index + 0].unit}`}
                        </text>
                    )}
                    {y2 && data2 && values2[i] && (
                        <text
                            x={parseFloat(data2[0])}
                            y={
                                data3 && y2 < parseFloat(data3[1]) + 15 && y2 >= parseFloat(data3[1])
                                    ? y2 + 13
                                    : data3 && y2 > parseFloat(data3[1]) - 15 && y2 < parseFloat(data3[1])
                                    ? y2 - 13
                                    : y2
                            }
                            className={styles.ProductionPlanGraphValue}
                            fill={'#9C4122'}
                            dominantBaseline="middle"
                            textAnchor="middle"
                        >
                            {`${values2[i]} ${graph.externalParameters[index + 1].unit}`}
                        </text>
                    )}
                    {y3 && data3 && values3[i] && (
                        <text
                            x={parseFloat(data3[0])}
                            y={
                                data2 && y3 < parseFloat(data2[1]) + 15 && y3 >= parseFloat(data2[1])
                                    ? y3 + 13
                                    : data2 && y3 > parseFloat(data2[1]) - 15 && y3 < parseFloat(data2[1])
                                    ? y3 - 13
                                    : y3
                            }
                            className={styles.ProductionPlanGraphValue}
                            fill={bottom ? '#406AAD' : '#3E9458'}
                            dominantBaseline="middle"
                            textAnchor="middle"
                        >
                            {`${values3[i]} ${graph.externalParameters[index + 2].unit}`}
                        </text>
                    )}
                </React.Fragment>
            );
        });
    };

    const renderChartLine = () => {
        return graph.externalParameters.map((param: ExternalParam, paramIndex) => {
            if (paramIndex < 3) {
                const fill = paramIndex === 0 ? '#EB6437' : paramIndex === 1 ? '#9C4122' : '#3E9458';
                const ratio = (energyGraphHeight - energyTopMargin - energyBottomMargin) / energyValueHeight;
                const points = param.values.map((value: ExternalParamValue, index) => {
                    const x = Math.round(columnWidth / 2) + columnWidth * index;
                    const y =
                        energyGraphStart +
                        energyGraphHeight -
                        energyBottomMargin -
                        (value.value - energyValueRange.min) * ratio;
                    return `${x},${y}`;
                });

                let x1, x2, y1;

                if (!paramIndex && energyMax > 80) {
                    x1 = 0;
                    x2 = columnWidth * columnAmount;
                    y1 =
                        energyGraphStart +
                        energyGraphHeight -
                        energyBottomMargin -
                        (120 - energyValueRange.min) * ratio;
                }

                if (points.length) {
                    const firstValue = points[0].substr(points[0].indexOf(',') + 1);
                    return (
                        <g key={`group${paramIndex}`}>
                            {!paramIndex && energyMax > 80 && (
                                <line
                                    x1={x1}
                                    x2={x2}
                                    y1={y1}
                                    y2={y1}
                                    className={styles.ProductionPlanGraphLinePeakBoilers}
                                />
                            )}
                            <polyline
                                key={param.parameterName}
                                points={`0, ${firstValue} ` + points.join(' ')}
                                className={
                                    paramIndex === 1
                                        ? styles.ProductionPlanGraphLineDashed
                                        : styles.ProductionPlanGraphLineSolid
                                }
                            />
                            {points.map((value, i) => {
                                const data = value.split(',');
                                return (
                                    <React.Fragment key={`${param.parameterName}-point-${i}`}>
                                        <circle cx={data[0]} cy={data[1]} r={5} fill={fill} />
                                    </React.Fragment>
                                );
                            })}
                        </g>
                    );
                }
            } else if (paramIndex < 6) {
                const fill = paramIndex === 3 ? '#EB6437' : paramIndex === 4 ? '#9C4122' : '#406AAD';
                const ratio = (tempGraphHeight - 2 * tempMargin) / tempValueHeight;
                const points = param.values.map((value: ExternalParamValue, index) => {
                    const x = Math.round(columnWidth / 2) + columnWidth * index;
                    const y = dsrGraphStart - tempMargin - (value.value - tempValueRange.min) * ratio;
                    return `${x},${y}`;
                });

                if (points.length) {
                    const firstValue = points[0].substr(points[0].indexOf(',') + 1);
                    return (
                        <g key={`group${paramIndex}`}>
                            <polyline
                                key={param.parameterName}
                                points={`0, ${firstValue} ` + points.join(' ')}
                                className={
                                    paramIndex === 4
                                        ? styles.ProductionPlanGraphLineDashed
                                        : styles.ProductionPlanGraphLineSolid
                                }
                            />
                            {points.map((value, i) => {
                                const data = value.split(',');
                                return (
                                    <React.Fragment key={`${param.parameterName}-point-${i}`}>
                                        <rect
                                            x={parseFloat(data[0]) - 5}
                                            y={parseFloat(data[1]) - 5}
                                            width={10}
                                            height={10}
                                            fill={fill}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </g>
                    );
                }
            }
        });
    };

    const renderForecaData = () => {
        return graph.forecaParameters.map((param: ForecaParam, paramIndex) => {
            return param.values.map((value: ForecaParamValue, index) => {
                const x = columnWidth * index + 30;
                return (
                    <React.Fragment key={`${param.parameterName}-text-${index}`}>
                        {!paramIndex && value.symbol && (
                            <foreignObject
                                x={columnWidth * index + 5}
                                y={airTempStart + 2 + paramIndex * 24}
                                width={20}
                                height={20}
                                data-symbol={value.symbol}
                            >
                                <ForecaIcon symbol={value.symbol} />
                            </foreignObject>
                        )}
                        <text
                            className={styles.ProductionPlanGraphText}
                            x={x}
                            y={airTempStart + 12 + paramIndex * 24}
                            dominantBaseline="middle"
                            textAnchor={paramIndex || !value.symbol ? 'middle' : 'start'}
                        >
                            {value.value} {param.unit}
                        </text>
                    </React.Fragment>
                );
            });
        });
    };

    const renderVerticalLinesAndHours = () => {
        return [...Array(columnAmount + 1)].map((n, i) => {
            const x = columnWidth * i;
            const h = i % 24;
            const hour = h > 9 ? h : `0${h}`;
            return (
                <React.Fragment key={`hLine${i}`}>
                    <line
                        className={styles.ProductionPlanGraphHorizontalLine}
                        x1={x}
                        y1={yStart}
                        x2={x}
                        y2={height - yStart}
                    />
                    <text
                        x={x + 34}
                        y={12 + yStart}
                        className={styles.ProductionPlanGraphHour}
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {`${hour}:00`}
                    </text>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <ContentText
                textColor={colors.forestGreen}
                textAlign={'right'}
                className={styles.triggerText}
                onClick={handleButtonTrigger}
            >
                Pokaż legendę
            </ContentText>
            <Box display="flex" flexDirection="row" marginTop={'5px'}>
                <Box className={styles.ProductionPlanGraphDescriptionContainer}>
                    <Box
                        className={`${styles.ProductionPlanGraphDescription} ${styles.ProductionPlanGraphDescriptionF}`}
                    >
                        <ContentText color={'#a3a3a3'} size={12}>
                            Godzina
                        </ContentText>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="16px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Kotły szczytowe od 120 MWt
                        </ContentText>
                        <div
                            className={`${styles.ProductionPlanGraphLine} ${styles.ProductionPlanGraphPeakBoilers}`}
                        ></div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="16px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Wykonane zapotrz. na ciepło
                        </ContentText>
                        <div className={styles.ProductionPlanGraphLine}>
                            <div
                                className={`${styles.ProductionPlanGraphCircle} ${styles.ProductionPlanGraphOrange}`}
                            ></div>
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="16px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Prognozowane zapotrz. na ciepło
                        </ContentText>
                        <div className={styles.ProductionPlanGraphLine}>
                            <div
                                className={`${styles.ProductionPlanGraphCircle} ${styles.ProductionPlanGraphBrown}`}
                            ></div>
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="8px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Plan produkcji energii elektrycznej netto
                        </ContentText>
                        <div className={styles.ProductionPlanGraphLine}>
                            <div
                                className={`${styles.ProductionPlanGraphCircle} ${styles.ProductionPlanGraphGreen}`}
                            ></div>
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="30px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Temp. na zasilaniu do sieci DH (źródło KKS)
                        </ContentText>
                        <div className={styles.ProductionPlanGraphLine}>
                            <div
                                className={`${styles.ProductionPlanGraphSquare} ${styles.ProductionPlanGraphOrange}`}
                            ></div>
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="30px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Planowana temp. na zasilaniu do sieci DH
                        </ContentText>
                        <div className={styles.ProductionPlanGraphLine}>
                            <div
                                className={`${styles.ProductionPlanGraphSquare} ${styles.ProductionPlanGraphBrown}`}
                            ></div>
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" textAlign="right" marginBottom="38px">
                        <ContentText color={'#a3a3a3'} size={12} width={'100%'}>
                            Temp. powrotu
                        </ContentText>
                        <div className={`${styles.ProductionPlanGraphLine} ${styles.ProductionPlanGraphLineOne}`}>
                            <div
                                className={`${styles.ProductionPlanGraphSquare} ${styles.ProductionPlanGraphSquareOne} ${styles.ProductionPlanGraphBlue}`}
                            ></div>
                        </div>
                    </Box>
                    <Box className={styles.ProductionPlanGraphDescription} marginBottom="29px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Scenariusz DSR
                        </ContentText>
                    </Box>
                    <Box className={styles.ProductionPlanGraphDescription} marginBottom="6px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Temp. powietrza
                        </ContentText>
                    </Box>
                    <Box className={styles.ProductionPlanGraphDescription} marginBottom="6px">
                        <ContentText color={'#a3a3a3'} size={12}>
                            Prędkość wiatru
                        </ContentText>
                    </Box>
                    <Box className={styles.ProductionPlanGraphDescription}>
                        <ContentText color={'#a3a3a3'} size={12}>
                            Wysokość opadu
                        </ContentText>
                    </Box>
                </Box>
                <div
                    ref={scrollableBox}
                    style={{ width: graphVisibleWidth, maxWidth: graphVisibleWidth, overflowX: 'scroll' }}
                >
                    <svg viewBox={`0 0 ${width} ${height}`} height={height}>
                        <symbol id="ProductionPlanGraphErrorSymbol" width="14" height="14" viewBox="-1 -1 16 16">
                            <circle cx="7" cy="7" r="8" fill="#FFFFFF" />
                            <circle cx="7" cy="7" r="7" fill="#C9C9C9" />
                            <path
                                d="M6.96883 0.332031C3.31016 0.332031 0.333496 3.3227 0.333496 6.9987C0.333496 10.6747 3.32416 13.6654 7.00016 13.6654C10.6762 13.6654 13.6668 10.6747 13.6668 6.9987C13.6668 3.3227 10.6622 0.332031 6.96883 0.332031ZM7.66683 10.332H6.3335V8.9987H7.66683V10.332ZM7.66683 7.66536H6.3335V3.66536H7.66683V7.66536Z"
                                fill="#B83E4D"
                            />
                        </symbol>
                        <rect width={width} height={energyGraphHeight} y={energyGraphStart} x={0} fill="#eeeeee" />
                        <rect width={width} height={dsrGraphHeight} y={dsrGraphStart} x={0} fill="#eeeeee" />
                        {renderDSRs()}
                        {renderVerticalLinesAndHours()}
                        {renderChartLabels()}
                        {renderChartLabels(true)}
                        {renderChartLine()}
                        {renderForecaData()}
                        {renderTime()}
                    </svg>
                </div>
                {procedureDetailsOpened && (
                    <ProcedureDetailsModal
                        id={procedureDetailsOpened}
                        date={new Date()}
                        onClose={() => handleModal(null)}
                    />
                )}
                <Modal
                    alignContent="left"
                    opened={legendOpened}
                    onClose={handleButtonTrigger}
                    style={{ minWidth: 1080 }}
                >
                    <Legend onInitOpened={true} withoutTrigger={true} />
                </Modal>
            </Box>
        </>
    );
};
