import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthorizationPage } from 'src/pages/Login/AuthorizationPage';
import Unauthorized from 'src/pages/Unauthorized/Unauthorized';
import Home from 'src/pages/Home/Home';
import Dashboard from 'src/pages/Dashboard/Dashboard';
import HeatProductionPlans from 'src/pages/HeatProductionPlans/HeatProductionPlans';
import Weather from 'src/pages/Weather/Weather';
import {LoginPage, LogoutPage} from 'src/pages/Login/Login';
import { ProtectedRoute } from 'src/services/router/ProtectedRoute';
import { AuthorizationComponent } from 'src/services/authorization/Authorization';

export const Router = () => {
    return (
        <AuthorizationComponent>
            <BrowserRouter>
                <Routes>
                    <Route element={<AuthorizationPage />} path="/authorizationRedirect" />
                    <Route element={<LoginPage />} path="/login" />
                    <Route element={<LogoutPage />} path="/logout" />
                    <Route element={<Unauthorized />} path="/unauthorized" />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                        path="/list"
                    />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                        path="/"
                    />
                    <Route
                        element={
                            <ProtectedRoute>
                                <HeatProductionPlans />
                            </ProtectedRoute>
                        }
                        path="/plans"
                    />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Weather />
                            </ProtectedRoute>
                        }
                        path="/weather"
                    />
                </Routes>
            </BrowserRouter>
        </AuthorizationComponent>
    );
};
