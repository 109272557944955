import React, { FunctionComponent } from 'react';
import { ReactComponent as N000Icon } from 'src/assets/weather/n000.svg';
import { ReactComponent as D000Icon } from 'src/assets/weather/d000.svg';
import { ReactComponent as N100Icon } from 'src/assets/weather/n100.svg';
import { ReactComponent as D100Icon } from 'src/assets/weather/d100.svg';
import { ReactComponent as N200Icon } from 'src/assets/weather/n200-300.svg';
import { ReactComponent as D200Icon } from 'src/assets/weather/d200-300.svg';
import { ReactComponent as N210Icon } from 'src/assets/weather/n210-310.svg';
import { ReactComponent as D210Icon } from 'src/assets/weather/d210-310.svg';
import { ReactComponent as N211Icon } from 'src/assets/weather/n211-311.svg';
import { ReactComponent as D211Icon } from 'src/assets/weather/d211-311.svg';
import { ReactComponent as N212Icon } from 'src/assets/weather/n212-312.svg';
import { ReactComponent as D212Icon } from 'src/assets/weather/d212-312.svg';
import { ReactComponent as N220Icon } from 'src/assets/weather/n220-320.svg';
import { ReactComponent as D220Icon } from 'src/assets/weather/d220-320.svg';
import { ReactComponent as N222Icon } from 'src/assets/weather/n222-322.svg';
import { ReactComponent as D222Icon } from 'src/assets/weather/d222-322.svg';
import { ReactComponent as N240Icon } from 'src/assets/weather/n240-340.svg';
import { ReactComponent as D240Icon } from 'src/assets/weather/d240-340.svg';
import { ReactComponent as DN400Icon } from 'src/assets/weather/dn400.svg';
import { ReactComponent as DN420Icon } from 'src/assets/weather/dn420.svg';
import { ReactComponent as DN411Icon } from 'src/assets/weather/dn411.svg';
import { ReactComponent as DN412Icon } from 'src/assets/weather/dn412.svg';
import { ReactComponent as DN421Icon } from 'src/assets/weather/dn421.svg';
import { ReactComponent as DN422Icon } from 'src/assets/weather/dn422.svg';
import { ReactComponent as DN430Icon } from 'src/assets/weather/dn430.svg';
import { ReactComponent as DN431Icon } from 'src/assets/weather/dn431.svg';
import { ReactComponent as DN432Icon } from 'src/assets/weather/dn432.svg';
import { ReactComponent as DN440Icon } from 'src/assets/weather/dn440.svg';
import { ReactComponent as D500Icon } from 'src/assets/weather/d500.svg';
import { ReactComponent as N500Icon } from 'src/assets/weather/n500.svg';
import { ReactComponent as DN600Icon } from 'src/assets/weather/dn600.svg';

type ForecaIconProps = {
    symbol: string;
};

export const ForecaIcon: FunctionComponent<ForecaIconProps> = ({ symbol }: ForecaIconProps) => {
    switch (symbol) {
        case '0000':
            return <N000Icon />;
        case '1000':
            return <D000Icon />;
        case '0100':
            return <N100Icon />;
        case '1100':
            return <D100Icon />;
        case '0200':
        case '0300':
            return <N200Icon />;
        case '1200':
        case '1300':
            return <D200Icon />;
        case '0210':
        case '0310':
            return <N210Icon />;
        case '1210':
        case '1310':
            return <D210Icon />;
        case '0211':
        case '0311':
            return <N211Icon />;
        case '1211':
        case '1311':
            return <D211Icon />;
        case '0212':
        case '0312':
            return <N212Icon />;
        case '1212':
        case '1312':
            return <D212Icon />;
        case '0220':
        case '0320':
            return <N220Icon />;
        case '1220':
        case '1320':
            return <D220Icon />;
        case '0221':
        case '0321':
            return <N211Icon />;
        case '1221':
        case '1321':
            return <D211Icon />;
        case '0222':
        case '0322':
            return <N222Icon />;
        case '1222':
        case '1322':
            return <D222Icon />;
        case '0240':
        case '0340':
            return <N240Icon />;
        case '1240':
        case '1340':
            return <D240Icon />;
        case '0400':
        case '1400':
            return <DN400Icon />;
        case '0410':
        case '0420':
        case '1410':
        case '1420':
            return <DN420Icon />;
        case '0411':
        case '1411':
            return <DN411Icon />;
        case '0412':
        case '1412':
            return <DN412Icon />;
        case '0421':
        case '1421':
            return <DN421Icon />;
        case '0422':
        case '1422':
            return <DN422Icon />;
        case '0430':
        case '1430':
            return <DN430Icon />;
        case '0431':
        case '1431':
            return <DN431Icon />;
        case '0432':
        case '1432':
            return <DN432Icon />;
        case '0440':
        case '1440':
            return <DN440Icon />;
        case '0500':
            return <D500Icon />;
        case '1500':
            return <N500Icon />;
        case '0600':
        case '1600':
            return <DN600Icon />;
        default:
            return <D000Icon />;
    }
};
