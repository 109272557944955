import { createSlice } from '@reduxjs/toolkit';

import { GraphData } from './ProductionPlans.types';
import { fetchDashboardGraph } from './ProductionPlans.thunk';

const initialState: GraphData = {
    graph: null,
    loading: false,
};

const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDashboardGraph.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchDashboardGraph.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.graph = action.payload;
            }
        });
    },
});

export default graphSlice.reducer;
