import React, { FunctionComponent } from 'react';
import { ProcedureElement } from 'src/api/model/ProcedureElement';

import styles from './DSRDetailsTable.module.scss';

type DSRDetailsTableGraphProps = {
    procedure: ProcedureElement;
};

/* eslint react/prop-types: 0 */
export const DSRDetailsTableGraph: FunctionComponent<DSRDetailsTableGraphProps> = ({ procedure }) => {
    const width = 772;
    const itemWidth = 144;
    const controlWidth = 24;
    const rowHeight = 24;
    const headerHeight = rowHeight;

    if (procedure) {
        const subtractHours = (date: Date, hours: number): Date => {
            const newDate = new Date(date);
            newDate.setHours(newDate.getHours() - hours);
            return newDate;
        };

        const startTime = new Date(`${procedure.startDate} ${procedure.startTime}`);
        const endTime = new Date(`${procedure.endDate} ${procedure.endTime}`);

        const hoursAmount = Math.round((endTime.getTime() - startTime.getTime()) / 36e5) + 4;

        const height = hoursAmount * rowHeight + headerHeight;

        const tableStartTime = subtractHours(startTime, 2);
        const tableStartHour = tableStartTime.getHours();

        return (
            <svg className={styles.DSRDetailsTableGraph} viewBox={`0 0 ${width} ${height}`}>
                <symbol id="DSRDetailsTableGraphErrorSymbol" width="14" height="14" viewBox="-1 -1 16 16">
                    <circle cx="7" cy="7" r="8" fill="#FFFFFF" />
                    <circle cx="7" cy="7" r="7" fill="#C9C9C9" />
                    <path
                        d="M6.96883 0.332031C3.31016 0.332031 0.333496 3.3227 0.333496 6.9987C0.333496 10.6747 3.32416 13.6654 7.00016 13.6654C10.6762 13.6654 13.6668 10.6747 13.6668 6.9987C13.6668 3.3227 10.6622 0.332031 6.96883 0.332031ZM7.66683 10.332H6.3335V8.9987H7.66683V10.332ZM7.66683 7.66536H6.3335V3.66536H7.66683V7.66536Z"
                        fill="#B83E4D"
                    />
                </symbol>
                <rect
                    className={styles.DSRDetailsTableGraphHeaderBackground}
                    x="0"
                    y="0"
                    width={width}
                    height={headerHeight}
                />
                {procedure.groups.map((group, groupIndex) => {
                    return group.items.map((item, itemIndex) => {
                        const x = 116 + 168 * groupIndex;
                        const itemStartTime = new Date(`${item.startDate} ${item.startTime}`);
                        const itemEndTime = new Date(`${item.endDate} ${item.endTime}`);
                        const itemHeight = ((itemEndTime.getTime() - itemStartTime.getTime()) / 36e5) * 24;
                        const y = headerHeight + ((itemStartTime.getTime() - tableStartTime.getTime()) / 36e5) * 24;
                        let style: string;
                        switch (item.status) {
                            case 'PLANNED':
                                style = styles.DSRDetailsTableGraphProcedureItemPlanned;
                                break;
                            case 'COMPLETED':
                                style = styles.DSRDetailsTableGraphProcedureItemCompleted;
                                break;
                            case 'RUNNING':
                                style = styles.DSRDetailsTableGraphProcedureItemToBeRunning;
                                break;
                            case 'TO_BE_ACCEPTED':
                                style = styles.DSRDetailsTableGraphProcedureItemToBeAccepted;
                                break;
                            case 'ACCEPTED':
                                style = styles.DSRDetailsTableGraphProcedureItemAccepted;
                                break;
                            case 'ERROR':
                                style = styles.DSRDetailsTableGraphProcedureItemError;
                                break;
                            case 'REJECTED':
                                style = styles.DSRDetailsTableGraphProcedureItemRejected;
                                break;
                            case 'TERMINATED':
                                style = styles.DSRDetailsTableGraphProcedureItemTerminated;
                                break;
                            default:
                                style = styles.DSRDetailsTableGraphProcedureItemPlanned;
                                break;
                        }
                        return (
                            <>
                                <rect
                                    className={style}
                                    key={`dItem${groupIndex}${itemIndex}`}
                                    x={x}
                                    y={y}
                                    width={itemWidth}
                                    height={itemHeight}
                                />
                                {item.controlCode !== 0 && (
                                    <rect
                                        className={
                                            item.controlCode > 0
                                                ? styles.DSRDetailsTableGraphProcedureChangePositive
                                                : styles.DSRDetailsTableGraphProcedureChangeNegative
                                        }
                                        key={`dControlCode${groupIndex}${itemIndex}`}
                                        x={x}
                                        y={y}
                                        width={controlWidth}
                                        height={itemHeight}
                                    />
                                )}
                            </>
                        );
                    });
                })}
                <text
                    className={styles.DSRDetailsTableGraphLabel}
                    x={54}
                    y={13}
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    Godzina
                </text>
                {Array(hoursAmount)
                    .fill(0)
                    .map((_el, index) => {
                        const y = headerHeight + rowHeight * (index + 1) - 0.5;
                        const yText = 13 + rowHeight * (index + 1) - 0.5;
                        const hourFrom =
                            tableStartHour + index > 23 ? tableStartHour - 24 + index : tableStartHour + index;
                        const hourTo = hourFrom + 1 > 23 ? 0 : hourFrom + 1;
                        return (
                            <>
                                <line
                                    key={`dRowLine${index}`}
                                    className={styles.DSRDetailsTableGraphHorizontalLine}
                                    x1="0"
                                    y1={y}
                                    x2={width}
                                    y2={y}
                                />
                                <text
                                    key={`dHour${index}`}
                                    className={styles.DSRDetailsTableGraphHour}
                                    x={50}
                                    y={yText}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {`${hourFrom}:00 - ${hourTo}:00`}
                                </text>
                            </>
                        );
                    })}
                {procedure.groups.map((group, groupIndex) => {
                    return group.items.map((item, itemIndex) => {
                        const x = 116 + 168 * groupIndex;
                        const itemStartTime = new Date(`${item.startDate} ${item.startTime}`);
                        const itemEndTime = new Date(`${item.endDate} ${item.endTime}`);
                        const itemHeight = ((itemEndTime.getTime() - itemStartTime.getTime()) / 36e5) * 24;
                        const y = headerHeight + ((itemStartTime.getTime() - tableStartTime.getTime()) / 36e5) * 24;
                        const xText = x + 72;
                        const yText = y + itemHeight / 2 + 1;
                        return (
                            <>
                                <text
                                    className={styles.DSRDetailsTableGraphItemControlCode}
                                    key={`dControlCodeText${groupIndex}${itemIndex}`}
                                    x={xText}
                                    y={yText}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {`DSR ${item.controlCode > 0 ? '+' + item.controlCode : item.controlCode}`}
                                </text>
                                {item.status === 'ERROR' && (
                                    <use
                                        xlinkHref="#DSRDetailsTableGraphErrorSymbol"
                                        x={x + controlWidth + 9}
                                        y={yText - 9}
                                        className={styles.DSRDetailsTableErrorIcon}
                                    >
                                        {!!item.historyMessage && <title>{item.historyMessage.text}</title>}
                                    </use>
                                )}
                            </>
                        );
                    });
                })}
                {procedure.groups.map((el, index) => {
                    const x = 20 + 168 * (index + 1);
                    return (
                        <text
                            key={`dGroup${index}`}
                            className={styles.DSRDetailsTableGraphLabel}
                            x={x}
                            y={13}
                            dominantBaseline="middle"
                            textAnchor="middle"
                        >
                            {el.name}
                        </text>
                    );
                })}
            </svg>
        );
    }

    return null;
};
