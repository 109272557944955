import { Box, Col, Grid, Row, Tabs, spacing, Breadcrumb } from '@fortum/elemental-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserInfo, UserInfoState } from 'src/providers/user';
import ProceduresList from 'src/containers/ProceduresList/ProceduresList';
import ProceduresManagement from 'src/containers/ProceduresManagement/ProceduresManagement';
import { Footer } from 'src/components/Footer/Footer';
import { Toast } from 'src/components/Toast/Toast';
import Header from 'src/components/Header/Header';

const Home: React.FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState('list');
    const user = useSelector<{ userInfo: UserInfoState }, UserInfo | null>((state) => state.userInfo.user);
    const tabItems = [{ id: 'list', name: 'Lista procedur DSR' }];

    if (user && (user.roles.includes('DYSPOZYTOR') || user.roles.includes('DIR'))) {
        tabItems.push({ id: 'management', name: 'Zarządzanie procedurami' });
    }

    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Header activePage={'DSR'} />
                <Grid flex="1 0 auto">
                    <Row marginTop={spacing.xxs} marginBottom={spacing.xxxs}>
                        <Col l={12}>
                            <Breadcrumb
                                aria-label="Nawigacja"
                                items={[{ title: 'Pulpit', params: { href: '/' } }, { title: 'Procedury DSR' }]}
                            />
                        </Col>
                    </Row>
                    <Row marginTop={spacing.xxxs} marginBottom={spacing.xxs}>
                        <Col l={12}>
                            <Box>
                                <Tabs
                                    items={tabItems}
                                    active={activeTab}
                                    onTabChange={(id: string) => {
                                        setActiveTab(id);
                                    }}
                                    mb={spacing.xxs}
                                    withBorder
                                />
                                <section
                                    aria-labelledby="list-tab"
                                    hidden={activeTab !== 'list'}
                                    id="list"
                                    role="tabpanel"
                                >
                                    <ProceduresList />
                                </section>
                                <section
                                    aria-labelledby="management-tab"
                                    hidden={activeTab !== 'management'}
                                    id="management"
                                    role="tabpanel"
                                >
                                    <ProceduresManagement />
                                </section>
                            </Box>
                        </Col>
                    </Row>
                </Grid>
                <Footer />
            </Box>
            <Toast />
        </>
    );
};

export default Home;
