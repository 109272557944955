import { ContentText, colors } from '@fortum/elemental-ui';
import React, { FunctionComponent } from 'react';

import { LegendItem } from './LegendItem';

import styles from './Legend.module.scss';

type LegendProps = {
    onInitOpened: boolean;
    withoutTrigger: boolean;
};

// eslint-disable-next-line react/prop-types
export const Legend: FunctionComponent<LegendProps> = ({ onInitOpened, withoutTrigger }) => {
    const [opened, setOpened] = React.useState(onInitOpened);
    const [buttonText, setButtonText] = React.useState(onInitOpened ? 'Ukryj legendę' : 'Pokaż legendę');

    const handleButtonTrigger = () => {
        setOpened(!opened);
        setButtonText(opened ? 'Pokaż legendę' : 'Ukryj legendę');
    };

    const renderLegendContent = () => {
        return (
            <div className={styles.root}>
                <ContentText textColor={colors.emperorGrey} className={styles.legendText}>
                    Status scenariusza DSR:
                </ContentText>
                <div className={styles.items}>
                    <LegendItem type="PLANNED" />
                    <LegendItem type="IN_ACCEPTATION" />
                    <LegendItem type="ACCEPTED" />
                    <LegendItem type="DONE" />
                    <LegendItem type="REJECTED" />
                </div>
                <ContentText textColor={colors.emperorGrey} className={styles.legendText}>
                    Rodzaj scenariusza DSR:
                </ContentText>
                <div className={styles.items}>
                    <LegendItem type="DSR-" />
                    <LegendItem type="DSR+" />
                </div>
            </div>
        );
    };

    return (
        <div>
            {!withoutTrigger && (
                <div className={styles.flexContainer}>
                    <ContentText
                        textColor={colors.forestGreen}
                        className={styles.triggerText}
                        onClick={handleButtonTrigger}
                    >
                        {buttonText}
                    </ContentText>
                </div>
            )}
            {opened && renderLegendContent()}
        </div>
    );
};
