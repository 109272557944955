import { configureStore } from '@reduxjs/toolkit';

import proceduresReducer from 'src/containers/ProceduresList/ProceduresList.slice';
import graphReducer from 'src/containers/ProductionPlans/ProductionPlans.slice';
import mapReducer from 'src/containers/NetworkStatus/NetworkStatus.slice';
import {
    proceduresCatalogReducer,
    groupNamesReducer,
    forecaReducer,
} from 'src/containers/ProceduresManagement/ProceduresManagement.slice';
import procedureDetailsReducer from 'src/components/ProcedureDetailsModal/ProcedureDetailsModal.slice';
import heatProductionReducer from 'src/containers/HeatProductionPlansContainer/HeatProductionPlansContainer.slice';
import weatherReducer from 'src/containers/WeatherContainer/WeatherContainer.slice';
import toastReducer from 'src/components/Toast/Toast.slice';
import authReducer from 'src/providers/auth';
import userInfoReducer from 'src/providers/user';

const reducer = {
    auth: authReducer,
    foreca: forecaReducer,
    groupNames: groupNamesReducer,
    graphData: graphReducer,
    mapData: mapReducer,
    procedures: proceduresReducer,
    procedureDetails: procedureDetailsReducer,
    proceduresCatalog: proceduresCatalogReducer,
    heatProductionData: heatProductionReducer,
    weatherData: weatherReducer,
    toast: toastReducer,
    userInfo: userInfoReducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
