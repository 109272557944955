/* eslint-disable react/display-name */
import React from 'react';
import parse, { domToReact, DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import { ContentText, tableColumn } from '@fortum/elemental-ui';

import WarningFilled from '../../assets/warningFilled.svg';

import styles from './HistoryTable.module.scss';
import { HistoryElement } from './HistoryTable.types';

const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
        if ('attribs' in domNode) {
            if (domNode.attribs.class === 'old-value') {
                return <span className={styles.oldValue}>{domToReact(domNode.children)}</span>;
            }
            if (domNode.attribs.class === 'new-value') {
                return <span className={styles.newValue}>{domToReact(domNode.children)}</span>;
            }
        }
    },
};

export const historyTableColumns = [
    tableColumn((row: HistoryElement) => row.dateTime)({
        key: 'dateTime',
        name: 'Data',
    }),
    tableColumn((row: HistoryElement) => row.message)({
        key: 'message',
        name: 'Zmiana',
        renderCell: (text: string, record: HistoryElement) => {
            if (record.messageType === 'ERROR') {
                return (
                    <div>
                        <div className={styles.actionTableCellRow}>
                            <img src={WarningFilled} className={styles.actionTableCellIcon} alt="warning_icon" />
                            <ContentText>{record.message}</ContentText>
                        </div>
                        {/* TODO: link below is to be implemented in future */}
                        {/* <div className={styles.actionTableCellLinkContainer}>
                            <Link color={colors.forestGreen}>Pokaż szczegóły</Link>
                        </div> */}
                    </div>
                );
            } else {
                return <div className={styles.message}>{parse(record.message, options)}</div>;
            }
        },
    }),
    tableColumn((row: HistoryElement) => row.performer)({
        key: 'performer',
        name: 'Wykonujący',
    }),
];
