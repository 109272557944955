export const baseURL = `${window.location.protocol}//${window.location.host}`;

export const clientID = process.env.REACT_APP_ENV === 'prod' ? '58dhf2lrg53910njosdmb1nugo' : '54o1bnf3rjifp87unarb2assfu';

export const origin =
    process.env.REACT_APP_ENV === 'prod'
        ? 'https://demand-side-response-prod.auth.eu-west-1.amazoncognito.com'
        : 'https://demand-side-response.auth.eu-west-1.amazoncognito.com';

export const logoutPath = '/logout';

export const logoutURL = `${baseURL}${logoutPath}`

export const loginURL = baseURL;

export const ciamLogoutURL = `${origin}/logout?client_id=${encodeURIComponent(
    clientID,
)}&response_type=token&logout_uri=${encodeURIComponent(logoutURL)}`;
