import React, { memo, FunctionComponent, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Box,
    Button,
    colors,
    ContentText,
    Gap,
    IconCross,
    Link,
    Loader,
    Radio,
    Select,
    spacing,
    useEventTargetValue,
} from '@fortum/elemental-ui';
import * as day from 'src/utils/day';
import { SliderIcon, ChevronsLeftIcon, ChevronsRightIcon } from 'src/components/CustomIcon/CustomIcon';
import styles from 'src/components/Legend/Legend.module.scss';
import { Legend } from 'src/components/Legend/Legend';
import { Chip } from 'src/components/Chip/Chip';
import { ProcedureComponent } from 'src/components/Procedure/Procedure';
import { DSRInputTable } from 'src/components/DSRInputTable/DSRInputTable';
import { ConfigureProcedureModal } from 'src/components/ConfigureProcedureModal/ConfigureProcedureModal';
import { AppDispatch } from 'src/providers/store';

import { useDispatch, useSelector } from 'react-redux';

import { ProcedureCatalog, ProcedureBody } from 'src/api/model/Procedure';

import { ProceduresState, Procedures } from '../ProceduresList/ProceduresList.types';

import { ForecaState, Foreca, ProceduresCatalogState, ProceduresCatalog } from './ProceduresManagement.types';

import { fetchProceduresCatalog, fetchForeca } from './ProceduresManagement.thunk';

/* DD.MM.YYYY */
function dateToDayString(date: Date) {
    const day = date.getDate();
    const month = date.getMonth(); /* Month is indexed from 0! */
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}.${(month + 1).toString().padStart(2, '0')}.${year}`;
}

const ProceduresManagement: FunctionComponent = () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const nextDayDate = day.next(todayDate);
    const todayDateString = dateToDayString(todayDate);
    const nextDayDateString = dateToDayString(nextDayDate);
    const [selectedDate, handleDateChange] = useEventTargetValue(dateToDayString(todayDate));
    const [isCatalogVisible, setIsCatalogVisible] = useState<boolean>(true);
    const [newProcedure, setNewProcedure] = useState<boolean>(false);
    const catalogVisibilityIcon = isCatalogVisible ? ChevronsLeftIcon : ChevronsRightIcon;
    const catalogVisibilityButtonText = isCatalogVisible ? 'Ukryj katalog procedur' : 'Pokaż katalog procedur';

    const handleCatalogVisibilty = () => {
        setIsCatalogVisible(!isCatalogVisible);
    };

    const handleNewProcedure = () => {
        setNewProcedure(true);
    };

    const handleNewProcedureClose = () => {
        setNewProcedure(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box
                marginTop={spacing.s}
                marginBottom={spacing.xs}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={spacing.xs}
            >
                <Gap size={spacing.xs}>
                    <Button status="secondary" onClick={handleCatalogVisibilty} leftIcon={catalogVisibilityIcon}>
                        {catalogVisibilityButtonText}
                    </Button>
                    <Button
                        status="primary"
                        onClick={handleNewProcedure}
                        leftIcon={SliderIcon}
                    >{`Skonfiguruj własną procedurę`}</Button>
                </Gap>
                <Gap size={spacing.xs}>
                    <ContentText textColor={colors.emperorGrey} className={styles.legendText}>
                        Data wykonania procedury:
                    </ContentText>
                    <Radio
                        name="date"
                        checked={selectedDate === todayDateString}
                        value={todayDateString}
                        label={todayDateString}
                        onChange={handleDateChange}
                    />
                    <Radio
                        name="date"
                        checked={selectedDate === nextDayDateString}
                        value={nextDayDateString}
                        label={nextDayDateString}
                        onChange={handleDateChange}
                    />
                </Gap>
            </Box>
            <ProcedureManagementContainer
                date={selectedDate}
                isCatalogVisible={isCatalogVisible}
                newProcedure={newProcedure}
                onNewProcedureClose={handleNewProcedureClose}
            />
        </DndProvider>
    );
};

type ProcedureManagementContainerProps = {
    isCatalogVisible: boolean;
    date: string;
    newProcedure: boolean;
    onNewProcedureClose: () => void;
};

const ProcedureManagementContainer: FunctionComponent<ProcedureManagementContainerProps> = memo(
    ({ isCatalogVisible, date, newProcedure, onNewProcedureClose }: ProcedureManagementContainerProps) => {
        const dispatch = useDispatch<AppDispatch>();
        const proceduresState = useSelector<ProceduresState>((state) => state.procedures) as Procedures;
        const proceduresCatalogState = useSelector<ProceduresCatalogState>(
            (state) => state.proceduresCatalog,
        ) as ProceduresCatalog;
        const forecaState = useSelector<ForecaState>((state) => state.foreca) as Foreca;
        const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
        const [editCatalogId, setEditCatalogId] = useState<number | null>(null);
        const [editId, setEditId] = useState<number | null>(null);
        const t = localStorage.getItem('tempProcedure');
        const [tempProcedure, setTempProcedure] = useState<ProcedureBody>(t ? JSON.parse(t) : {});
        const [startOption, handleStartOptionChange] = useEventTargetValue(null);
        const [groupDelay, handleGroupDelayChange] = useEventTargetValue(null);

        useEffect(() => {
            dispatch(fetchProceduresCatalog());
            dispatch(fetchForeca());
        }, []);

        useEffect(() => {
            if (Object.keys(tempProcedure).length) {
                localStorage.setItem('tempProcedure', JSON.stringify(tempProcedure));
            } else {
                localStorage.removeItem('tempProcedure');
            }
        }, [tempProcedure]);

        const handleFilterVisibilty = () => {
            setIsFilterVisible(!isFilterVisible);
        };

        const catalogMaxHeight = isFilterVisible ? 456 : 624;

        const filteredCatalogData = proceduresCatalogState.procedureCatalogList.filter((item) => {
            let filter = true;
            if (startOption) filter = filter && item.catalogGroups[0].catalogGroupItems[0].controlCode === startOption;
            if (groupDelay) {
                filter = filter && item.catalogGroups[1].timeDelay == 60 * groupDelay;
            }
            return filter;
        });

        const handleCatalogEdit = (id: number) => {
            setEditCatalogId(id);
        };

        const handleEdit = (id: number) => {
            setEditId(id);
        };

        const handleNewProcedure = (body: ProcedureBody) => {
            setTempProcedure(body);
        };

        const handleTempProcedureRemove = () => {
            setTempProcedure({} as ProcedureBody);
        };

        return (
            <Box
                marginTop={spacing.s}
                marginBottom={spacing.xs}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                gap={spacing.xs}
            >
                {editCatalogId && (
                    <ConfigureProcedureModal
                        action={'edit'}
                        proceduresCatalog={proceduresCatalogState.procedureCatalogList}
                        onClose={() => setEditCatalogId(null)}
                        id={editCatalogId}
                    />
                )}
                {editId &&
                    (editId === -1 ? (
                        <ConfigureProcedureModal
                            action={'edit'}
                            tempProcedure={tempProcedure}
                            procedures={proceduresState.proceduresList}
                            onSaveTemp={() => handleTempProcedureRemove()}
                            onClose={() => setEditId(null)}
                            id={editId}
                        />
                    ) : (
                        <ConfigureProcedureModal
                            action={'edit'}
                            procedures={proceduresState.proceduresList}
                            onClose={() => setEditId(null)}
                            id={editId}
                        />
                    ))}
                {newProcedure && <ConfigureProcedureModal action={'add'} onClose={() => onNewProcedureClose()} />}

                {isCatalogVisible && (
                    <Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            paddingRight={spacing.xxs}
                        >
                            <ContentText marginBottom={spacing.xs} size={16}>
                                Katalog procedur:
                            </ContentText>
                            <Link noUnderline={true} color={colors.forestGreen} onClick={handleFilterVisibilty}>
                                {isFilterVisible ? <IconCross /> : 'Filtruj'}
                            </Link>
                        </Box>
                        {isFilterVisible && (
                            <Box marginBottom={spacing.xs}>
                                {!startOption ? (
                                    <Box paddingRight={spacing.xxs} maxWidth="218px" width="218px">
                                        <Select
                                            name="startOption"
                                            label="Scenariusz startowy"
                                            placeholder="wybierz"
                                            onChange={handleStartOptionChange}
                                            selected={startOption}
                                            marginBottom={spacing.xxs}
                                            items={[
                                                { name: 'DSR+1', value: 1 },
                                                { name: 'DSR-1', value: -1 },
                                            ]}
                                        />
                                    </Box>
                                ) : (
                                    <Chip
                                        label={`Scenariusz startowy: DSR${
                                            startOption > 0 ? `+${startOption}` : startOption
                                        }`}
                                        onClick={() => {
                                            handleStartOptionChange({ target: { value: null } });
                                        }}
                                    />
                                )}
                                {!groupDelay ? (
                                    <Box paddingRight={spacing.xxs} maxWidth="218px" width="218px">
                                        <Select
                                            name="groupDelay"
                                            label="Kolejne grupy włączane co"
                                            placeholder="wybierz"
                                            onChange={handleGroupDelayChange}
                                            selected={groupDelay}
                                            marginBottom={spacing.xxs}
                                            items={[
                                                { name: '15 minut', value: 0.25 },
                                                { name: '30 minut', value: 0.5 },
                                                { name: '45 minut', value: 0.75 },
                                                { name: '60 minut', value: 1.0 },
                                            ]}
                                        />
                                    </Box>
                                ) : (
                                    <Chip
                                        label={`Kolejne grupy włączane co: ${groupDelay * 60} minut`}
                                        onClick={() => {
                                            handleGroupDelayChange({ target: { value: null } });
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                        {proceduresCatalogState.loading ? (
                            <Box
                                marginTop={spacing.xl}
                                marginBottom={spacing.m}
                                paddingRight={spacing.xxs}
                                display="flex"
                                justifyContent="center"
                                maxWidth="218px"
                                width="218px"
                            >
                                <Loader />
                            </Box>
                        ) : (
                            <Box overflowY="scroll" maxHeight={`${catalogMaxHeight}px`} paddingRight={spacing.xxs}>
                                {filteredCatalogData.map((props: ProcedureCatalog, i) => (
                                    <ProcedureComponent
                                        key={`procedure${i}`}
                                        {...props}
                                        setTempProcedure={handleNewProcedure}
                                        handleEdit={() => handleCatalogEdit(props.id)}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
                <Box>
                    <Box marginLeft={spacing.m} paddingLeft={spacing.xxxs} paddingBottom={spacing.xs}>
                        <Legend onInitOpened={false} withoutTrigger={false} />
                    </Box>
                    {proceduresState.loading && forecaState.loading ? (
                        <Box
                            marginTop={spacing.xl}
                            marginBottom={spacing.m}
                            marginLeft={spacing.m}
                            paddingLeft={spacing.xxxs}
                            display="flex"
                            justifyContent="center"
                            width="986px"
                        >
                            <Loader />
                        </Box>
                    ) : (
                        <DSRInputTable
                            procedures={proceduresState}
                            foreca={forecaState}
                            date={date}
                            tempProcedure={tempProcedure}
                            handleEdit={handleEdit}
                            onTempProcedureRemove={handleTempProcedureRemove}
                        />
                    )}
                </Box>
            </Box>
        );
    },
);

ProcedureManagementContainer.displayName = 'ProcedureManagementContainer';

export default ProceduresManagement;
