import React, { FunctionComponent } from 'react';
import {
    Grid,
    ContentText,
    LogoWidget,
    spacing,
    Link,
    colors,
    Box,
    IconProfile,
    usePopover,
    Popover,
} from '@fortum/elemental-ui';
import { useSelector } from 'react-redux';
import { UserInfo, UserInfoState } from 'src/providers/user';
import { LogoutIcon } from 'src/components/CustomIcon/CustomIcon';
import { ciamLogoutURL } from 'src/auth';

import styles from './Header.module.scss';

type HeaderProps = {
    activePage: string;
};

const Header: FunctionComponent<HeaderProps> = ({ activePage }: HeaderProps) => {
    const { open, anchor, handleOpen, handleClose } = usePopover();
    const user = useSelector<{ userInfo: UserInfoState }, UserInfo | null>((state) => state.userInfo.user);

    return (
        <div className={styles.HeaderContainer}>
            <Grid>
                <div className={styles.HeaderContent}>
                    <div className={styles.HeaderLeft}>
                        <LogoWidget />
                    </div>
                    <div className={styles.HeaderRight}>
                        <Box mt={spacing.xs}>
                            <Link
                                className={`${styles.HeaderMenuLink} ${
                                    activePage === 'Pulpit' && styles.HeaderMenuLinkActive
                                }`}
                                color={colors.snowWhite}
                                noUnderline={true}
                                href={'/'}
                            >
                                Pulpit
                            </Link>
                            <Link
                                className={`${styles.HeaderMenuLink} ${
                                    activePage === 'Pogoda' && styles.HeaderMenuLinkActive
                                }`}
                                noUnderline={true}
                                color={colors.snowWhite}
                                href={'/weather'}
                            >
                                Pogoda
                            </Link>
                            <Link
                                className={`${styles.HeaderMenuLink} ${
                                    activePage === 'Plany produkcji ciepła' && styles.HeaderMenuLinkActive
                                }`}
                                color={colors.snowWhite}
                                noUnderline={true}
                                href={'/plans'}
                            >
                                Plany produkcji ciepła
                            </Link>
                            <Link
                                className={`${styles.HeaderMenuLink} ${
                                    activePage === 'DSR' && styles.HeaderMenuLinkActive
                                }`}
                                color={colors.snowWhite}
                                noUnderline={true}
                                href={'/list'}
                            >
                                DSR
                            </Link>
                        </Box>
                        <div className={styles.AuthWidget}>
                            <button className={styles.AuthButtonWrapper} onClick={handleOpen}>
                                <IconProfile className={styles.AuthIcon} />
                            </button>
                            <button
                                className={styles.AuthButtonWrapper}
                                onClick={() => {
                                    window.location.href = ciamLogoutURL;
                                }}
                            >
                                <LogoutIcon className={styles.AuthIcon} />
                            </button>
                            <Popover
                                opened={open}
                                onClose={handleClose}
                                anchor={anchor}
                                anchorPos="topRight"
                                showArrow={false}
                                backgroundColor={'#f7f7f7'}
                            >
                                <Box m={spacing.xs}>
                                    {user && <ContentText>{`${user.firstName} ${user.lastName}`}</ContentText>}
                                </Box>
                            </Popover>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    );
};

export default Header;
