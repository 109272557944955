import React, { FunctionComponent } from 'react';
import { IconCross, colors } from '@fortum/elemental-ui';

import styles from './Chip.module.scss';
import { ChipProps } from './Chip.types';

export const Chip: FunctionComponent<ChipProps> = ({ label, onClick }: ChipProps) => {
    return (
        <button className={styles.button} onClick={onClick}>
            <p className={styles.p}>{label}</p>
            <div className={styles.iconContainer}>
                <IconCross color={colors.snowWhite} />
            </div>
        </button>
    );
};
