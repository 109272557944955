import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProcedureBody, ProcedureCatalogBody, ProcedureCatalogUpdateBody } from 'src/api/model/Procedure';
import {
    createProcedure,
    createCatalogProcedure,
    getProcedureCatalog,
    getGroupNames,
    updateProcedure,
    updateCatalogProcedure,
    getForeca,
} from 'src/api/requests';
import { handleToast } from 'src/components/Toast/Toast.slice';
import { fetchProcedures } from 'src/containers/ProceduresList/ProceduresList.thunk';
import { logger } from 'src/utils';
import { ProceduresCatalogResponse } from 'src/api/model/ProceduresResponse';
import { ForecaParam } from 'src/api/model/Graph';

const fetchProceduresCatalog = createAsyncThunk(
    'proceduresCatalog/get',
    async (): Promise<ProceduresCatalogResponse | undefined> => {
        try {
            logger.log('[TODO] try to getProcedureCatalog');
            return await getProcedureCatalog();
        } catch {
            logger.error('[TODO] getProcedureCatalog failed');
        }
    },
);

const fetchGroupNames = createAsyncThunk('groupNames/get', async (): Promise<string[] | undefined> => {
    try {
        logger.log('[TODO] try to getGroupNames');
        return await getGroupNames();
    } catch {
        logger.error('[TODO] getGroupNames failed');
    }
});

const fetchForeca = createAsyncThunk('foreca/get', async (): Promise<ForecaParam[] | undefined> => {
    try {
        logger.log('[TODO] try to getForeca');
        return await getForeca();
    } catch {
        logger.error('[TODO] getForeca failed');
    }
});

const handleProcedureAdd = createAsyncThunk('procedures/add', async (body: ProcedureBody, { dispatch }) => {
    try {
        return await createProcedure(body).then((response) => {
            if (response.succeed) {
                dispatch(fetchProcedures());
            } else {
                if (response.message) {
                    dispatch(
                        handleToast({
                            toastText: response.message,
                        }),
                    );
                }
            }
        });
    } catch {
        console.error('[TODO] procedureAdd failed');
    }
});

const handleProcedureUpdate = createAsyncThunk(
    'procedures/update',
    async (arg: { id: number; body: ProcedureBody }, { dispatch }) => {
        try {
            return await updateProcedure(arg.id, arg.body).then((response) => {
                if (response.succeed) {
                    dispatch(fetchProcedures());
                } else {
                    if (response.message) {
                        dispatch(
                            handleToast({
                                toastText: response.message,
                            }),
                        );
                    }
                }
            });
        } catch {
            console.error('[TODO] procedureUpdate failed');
        }
    },
);

const handleProcedureCatalogAdd = createAsyncThunk(
    'proceduresCatalog/add',
    async (body: ProcedureCatalogBody, { dispatch }) => {
        try {
            return await createCatalogProcedure(body).then((response) => {
                if (response.succeed) {
                    dispatch(fetchProceduresCatalog());
                } else {
                    if (response.message) {
                        dispatch(
                            handleToast({
                                toastText: response.message,
                            }),
                        );
                    }
                }
            });
        } catch {
            console.error('[TODO] procedureCatalogAdd failed');
        }
    },
);

const handleProcedureCatalogUpdate = createAsyncThunk(
    'proceduresCatalog/update',
    async (arg: { id: number; body: ProcedureCatalogUpdateBody }, { dispatch }) => {
        try {
            return await updateCatalogProcedure(arg.id, arg.body).then((response) => {
                if (response.succeed) {
                    dispatch(fetchProceduresCatalog());
                } else {
                    if (response.message) {
                        dispatch(
                            handleToast({
                                toastText: response.message,
                            }),
                        );
                    }
                }
            });
        } catch {
            console.error('[TODO] procedureCatalogUpdate failed');
        }
    },
);

export {
    handleProcedureAdd,
    handleProcedureCatalogAdd,
    fetchProceduresCatalog,
    fetchGroupNames,
    fetchForeca,
    handleProcedureUpdate,
    handleProcedureCatalogUpdate,
};
