import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    DatePicker,
    Gap,
    Heading,
    IconButton,
    IconChevronLeft,
    IconChevronRight,
    Loader,
    spacing,
} from '@fortum/elemental-ui';
import moment from 'moment/moment';
import { ExportIcon } from 'src/components/CustomIcon/CustomIcon';
import WeatherTable from 'src/components/WeatherTable/WeatherTable';
import { AppDispatch } from 'src/providers/store';

import { fetchWeather, exportWeather } from './WeatherContainer.thunk';
import { WeatherState, WeatherData } from './WeatherContainer.types';

const WeatherContainer: FunctionComponent = () => {
    const weatherState = useSelector<WeatherState>((state) => state.weatherData) as WeatherData;

    const dispatch = useDispatch<AppDispatch>();
    const todayDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const [selectedDate, setSelectedDate] = useState<Date>(todayDate.toDate());

    useEffect(() => {
        dispatch(fetchWeather(selectedDate));
    }, [selectedDate]);

    const maxDate = todayDate.add(2, 'day');

    const dayAfterSelectedDate = moment(selectedDate).add(1, 'day');

    const canSelectNextDay = dayAfterSelectedDate.isAfter(maxDate);

    const changeSelectedDateToPreviousDay = () => {
        setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate());
    };

    const changeSelectedDateToNextDay = () => {
        setSelectedDate(dayAfterSelectedDate.toDate());
    };

    const isSameYear = moment(selectedDate).isSame(dayAfterSelectedDate, 'year');
    const isSameMonth = moment(selectedDate).isSame(dayAfterSelectedDate, 'month');

    const handleExport = () => {
        dispatch(exportWeather(selectedDate));
    };

    const updateSelectedDate = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    return (
        <>
            <Heading level={2} weight={700} style={{ fontSize: 24 }}>
                {`Pogoda ${moment(selectedDate).format(
                    isSameYear ? (isSameMonth ? 'DD' : 'DD.MM') : 'DD.MM.YYYY',
                )}-${dayAfterSelectedDate.format('DD.MM.YYYY')}`}
            </Heading>
            <Box
                marginTop={spacing.s}
                marginBottom={spacing.xs}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={spacing.xs}
            >
                <Button status="primary" leftIcon={ExportIcon} onClick={handleExport}>{`Eksport XLS`}</Button>
                <Gap size={spacing.xxs}>
                    <IconButton
                        invert
                        status="plain"
                        icon={<IconChevronLeft />}
                        size="64px"
                        aria-label="Wybierz wczesniejszy dzień"
                        onClick={changeSelectedDateToPreviousDay}
                    />
                    <DatePicker
                        label="Dzień"
                        name="date"
                        onChange={(selectedDate) => updateSelectedDate(selectedDate)}
                        maxDate={maxDate.toDate()}
                        selected={selectedDate}
                        borderStyle="full"
                        locale="en"
                    />
                    <IconButton
                        invert
                        status="plain"
                        icon={<IconChevronRight />}
                        size="64px"
                        aria-label="Wybierz późniejszy dzień"
                        disabled={canSelectNextDay}
                        onClick={changeSelectedDateToNextDay}
                    />
                </Gap>
            </Box>
            {weatherState.loading ? (
                <Box marginTop={spacing.xl} marginBottom={spacing.m} display="flex" justifyContent="center">
                    <Loader />
                </Box>
            ) : (
                weatherState.weather && <WeatherTable data={weatherState.weather} />
            )}
        </>
    );
};

export default WeatherContainer;
