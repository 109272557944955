import { createAsyncThunk } from '@reduxjs/toolkit';
import { Procedure } from 'src/api/model/Procedure';
import { acceptProcedure, getProcedureById, rejectProcedure, terminateProcedure } from 'src/api/requests';
import { handleToast } from 'src/components/Toast/Toast.slice';
import { fetchProcedures } from 'src/containers/ProceduresList/ProceduresList.thunk';
import { logger } from 'src/utils';

import { Toast } from '../Toast/Toast.types';

import {
    StatusChangeParams,
    toastDataAccept,
    toastDataReject,
    toastDataTerminate,
} from './ProcedureDetailsModal.types';

const fetchProcedureById = createAsyncThunk('get/procedure/id', async (id: number): Promise<Procedure | undefined> => {
    try {
        logger.log('[TODO] try to getProcedures by id');
        return await getProcedureById(id);
    } catch {
        // handle error
        logger.error('[TODO] getProcedureById failed');
    }
});

const handleProcedureStatusChange = createAsyncThunk(
    'get/procedures/statusChange',
    async (changeParams: StatusChangeParams, { dispatch }) => {
        const { id, type, message, date } = changeParams;
        let targetFunction;
        let toastCopy: Toast;
        switch (type) {
            case 'accept':
                targetFunction = acceptProcedure;
                toastCopy = toastDataAccept;
                break;
            case 'reject':
                targetFunction = rejectProcedure;
                toastCopy = toastDataReject;
                break;
            case 'terminate':
                targetFunction = terminateProcedure;
                toastCopy = toastDataTerminate;
                break;
        }
        try {
            return await targetFunction(id, message).then(() => {
                dispatch(handleToast(toastCopy));
                dispatch(fetchProcedures(date));
            });
        } catch {
            console.error('[TODO] procedureStatusChange failed');
        }
    },
);

export { handleProcedureStatusChange, fetchProcedureById };
