import {
    ContentText,
    IconEdit,
    Modal,
    Heading,
    Tabs,
    spacing,
    Button,
    colors,
    IconCheck,
    IconTrash,
    Textarea,
    useEventTargetValue,
    Box,
    Loader,
} from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableActions } from 'src/api/model/ProcedureElement';
import { AppDispatch } from 'src/providers/store';

import { DSRDetailsTableGraph } from 'src/components/DSRDetailsTable/DSRDetailsTable';

import { ReactComponent as ControlImage } from '../../assets/control.svg';
import { ReactComponent as PowerOffImage } from '../../assets/powerOff.svg';

import { HistoryTable } from './HistoryTable';

import styles from './ProcedureDetailsModal.module.scss';

import { fetchProcedureById, handleProcedureStatusChange } from './ProcedureDetailsModal.thunk';
import {
    ModalSteps,
    ProcedureDetails,
    ProcedureDetailsModalProps,
    ProcedureDetailsState,
} from './ProcedureDetailsModal.types';

const TABS = [
    { id: 'details', name: 'Szczegóły procedury' },
    { id: 'history', name: 'Historia' },
];

const MAX_REASON_LENGTH = 200;

export const ProcedureDetailsModal: FunctionComponent<ProcedureDetailsModalProps> = ({
    onClose,
    id,
    date,
    reasonForced,
}: ProcedureDetailsModalProps) => {
    const [modalStep, setModalStep] = React.useState<ModalSteps>('details');
    const [activeTab, setActiveTab] = React.useState('details');

    const dispatch = useDispatch<AppDispatch>();

    const procedureState = useSelector<ProcedureDetailsState>((state) => state.procedureDetails) as ProcedureDetails;

    const [rejectionReason, setRejectionReason] = useEventTargetValue('');
    const [terminationReason, setTerminationReason] = useEventTargetValue('');

    useEffect(() => {
        !procedureState.details && dispatch(fetchProcedureById(id));

        if (reasonForced) {
            switch (reasonForced) {
                case 'reject':
                    setModalStep('rejection');
                    break;
                case 'terminate':
                    setModalStep('terminationReason');
            }
        }
    });

    const handleRequest = (type: 'accept' | 'reject' | 'terminate', id: number) => {
        onClose();
        dispatch(
            handleProcedureStatusChange({
                id,
                type,
                date,
                message: type === 'reject' ? rejectionReason : terminationReason,
            }),
        );
    };

    const checkIsActionAvailable = (type: AvailableActions) => {
        const procedure = procedureState.details;
        return !procedureState.loading && procedure ? procedure.availableActions.includes(type) : false;
    };

    const renderDetailsButtons = () => {
        const acceptAvailable = checkIsActionAvailable('accept');
        const rejectAvailable = checkIsActionAvailable('reject');
        const terminateAvailable = checkIsActionAvailable('terminate');
        const editAvailable = checkIsActionAvailable('edit'); // TODO: confirm when BE model is expanded
        const configAvailable = checkIsActionAvailable('config'); // TODO: confirm when BE model is expanded

        const procedure = procedureState.details;

        return (
            <>
                {editAvailable ? (
                    <Button className={styles.buttonWithCustomIcon} color={colors.forestGreen} status="plain">
                        <ControlImage className={styles.buttonCustomIcon} />
                        Edytuj procedurę
                    </Button>
                ) : null}
                {configAvailable ? (
                    <Button leftIcon={IconEdit} color={colors.forestGreen} status="plain">
                        Zmień konfigurację
                    </Button>
                ) : null}
                {acceptAvailable ? (
                    <Button
                        leftIcon={IconCheck}
                        color={colors.forestGreen}
                        status="plain"
                        onClick={() => procedure && handleRequest('accept', procedure.id)}
                    >
                        Zaakceptuj procedurę
                    </Button>
                ) : null}
                {rejectAvailable ? (
                    <Button
                        leftIcon={IconTrash}
                        color={colors.forestGreen}
                        status="plain"
                        onClick={() => setModalStep('rejection')}
                    >
                        Odrzuć procedurę
                    </Button>
                ) : null}
                {terminateAvailable ? (
                    <Button
                        className={styles.buttonWithCustomIcon}
                        color={colors.forestGreen}
                        status="plain"
                        onClick={() => setModalStep('terminationReason')}
                    >
                        <PowerOffImage className={styles.buttonCustomIcon} />
                        Zatrzymaj procedurę
                    </Button>
                ) : null}
            </>
        );
    };

    const renderModalContent = () => {
        const procedure = procedureState.details;

        switch (modalStep) {
            case 'details':
                return (
                    <>
                        <div>
                            <Heading pb={spacing.xs} className={styles.heading} level={5}>
                                Szczegóły procedury
                            </Heading>
                            <ContentText>
                                Procedura optymalizacyjna <b>{procedure && procedure.name}</b>
                            </ContentText>
                            <ContentText>Standardowa konfiguracja grup węzłów.</ContentText>
                            <ContentText>
                                Data wykonania procedury: <b>{procedure && procedure.startDate}</b>
                            </ContentText>
                        </div>
                        <Tabs
                            items={TABS}
                            textSize="L"
                            active={activeTab}
                            onTabChange={setActiveTab}
                            mt={spacing.xs}
                            withBorder
                        />
                        <section
                            aria-labelledby="details-tab"
                            hidden={activeTab !== 'details'}
                            id="details"
                            role="tabpanel"
                        >
                            <Box marginTop={spacing.m}>
                                {procedure && <DSRDetailsTableGraph procedure={procedure} />}
                            </Box>
                            <div className={styles.detailsButtonsContainer}>{renderDetailsButtons()}</div>
                        </section>
                        <section
                            aria-labelledby="history-tab"
                            hidden={activeTab !== 'history'}
                            id="history"
                            role="tabpanel"
                        >
                            {procedure && <HistoryTable tableData={procedure.history} />}
                        </section>
                    </>
                );
            case 'rejection':
                return (
                    <>
                        <div>
                            <Heading pb={spacing.xs} className={styles.heading} level={5}>
                                Odrzucenie procedury
                            </Heading>
                            <ContentText pb={spacing.xxs}>
                                Procedura po odrzuceniu trafi do “Archiwum procedur DSR” i nie będzie widoczna na
                                “Liście procedur DSR” ani w innych miejscach systemu.
                            </ContentText>
                            <ContentText>
                                Jeżeli chcesz odrzucić procedurę, wpisz w polu poniard powód odrzucenia - komentarz
                                obowiązkowy.
                            </ContentText>
                        </div>
                        <Textarea
                            name="rejectionReason"
                            label="Powód odrzucenia procedury"
                            onChange={setRejectionReason}
                            value={rejectionReason}
                            maxLength={MAX_REASON_LENGTH}
                            mt={spacing.s}
                        />
                        <div className={`${styles.detailsButtonsContainer} ${styles.rejectionButtonsContainer}`}>
                            <Button onClick={onClose} color={colors.forestGreen} status="secondary">
                                Anuluj odrzucenie
                            </Button>
                            <Button
                                onClick={() => procedure && handleRequest('reject', procedure.id)}
                                color={colors.forestGreen}
                                status="primary"
                                disabled={rejectionReason.length > MAX_REASON_LENGTH || !rejectionReason}
                            >
                                Odrzuć procedurę
                            </Button>
                        </div>
                    </>
                );
            case 'terminationReason':
                return (
                    <>
                        <div>
                            <Heading pb={spacing.xs} className={styles.heading} level={5}>
                                Zatrzymanie procedury - krok 1 z 2
                            </Heading>
                            <ContentText pb={spacing.xxs}>
                                Po przerwaniu procedury do wszystkich grup wysłane zostaną sterowania przywracające
                                domyślne ustawienia regulatorów.
                            </ContentText>
                            <ContentText>
                                Jeżeli chcesz zatrzymać obecnie wykonywaną procedure wpisz w polu poniżej powód
                                zatrzymania - komentarz obowiązkowy.
                            </ContentText>
                        </div>
                        <Textarea
                            name="terminationReason"
                            label="Powód zatrzymania procedury"
                            onChange={setTerminationReason}
                            value={terminationReason}
                            maxLength={MAX_REASON_LENGTH}
                            mt={spacing.s}
                        />
                        <div className={`${styles.detailsButtonsContainer} ${styles.rejectionButtonsContainer}`}>
                            <Button onClick={onClose} color={colors.forestGreen} status="secondary">
                                Anuluj zatrzymanie
                            </Button>
                            <Button
                                onClick={() => setModalStep('terminationConsequences')}
                                color={colors.forestGreen}
                                status="primary"
                                disabled={terminationReason.length > MAX_REASON_LENGTH || !terminationReason}
                            >
                                Potwierdź zatrzymanie
                            </Button>
                        </div>
                    </>
                );
            case 'terminationConsequences':
                return (
                    <>
                        <div>
                            <Heading pb={spacing.xs} className={styles.heading} level={5}>
                                Zatrzymanie procedury - krok 2 z 2
                            </Heading>
                            <ContentText pb={spacing.xxs}>
                                Zrealizowane sterowanie nie będzie zbalansowane - czy na pewno chcesz przerwać
                                procedurę?
                            </ContentText>
                        </div>
                        <div className={`${styles.detailsButtonsContainer} ${styles.rejectionButtonsContainer}`}>
                            <Button onClick={onClose} color={colors.forestGreen} status="secondary">
                                Anuluj zatrzymanie
                            </Button>
                            <Button
                                onClick={() => procedure && handleRequest('terminate', procedure.id)}
                                color={colors.forestGreen}
                                status="primary"
                            >
                                Potwierdź zatrzymanie
                            </Button>
                        </div>
                    </>
                );
        }
    };

    return (
        <Modal className={styles[modalStep]} alignContent="left" opened={true} onClose={onClose}>
            {!procedureState.loading && procedureState.details ? (
                renderModalContent()
            ) : (
                <Box marginTop={spacing.xl} marginBottom={spacing.m} display="flex" justifyContent="center">
                    <Loader />
                </Box>
            )}
        </Modal>
    );
};
