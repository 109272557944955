import React, { FunctionComponent, useState } from 'react';
import { Button, colors, Box, IconCheck, IconTrash, Popover, spacing, usePopover } from '@fortum/elemental-ui';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfo, UserInfoState } from 'src/providers/user';
import { Procedure } from 'src/api/model/Procedure';
import { AppDispatch } from 'src/providers/store';

import { Item } from 'src/api/model/Item';

import * as day from 'src/utils/day';

import { ProcedureDetailsModal } from '../ProcedureDetailsModal/ProcedureDetailsModal';
import { handleProcedureStatusChange } from '../ProcedureDetailsModal/ProcedureDetailsModal.thunk';

import { clearDetails } from '../ProcedureDetailsModal/ProcedureDetailsModal.slice';

import styles from './DSROverviewTable.module.scss';
import { DSROverviewTableProps } from './DSROverviewTable.types';

/* eslint react/prop-types: 0 */

function range(l: number) {
    return Array.from({ length: l }, (_, index) => index);
}

type DSROverviewTableGraphProps = {
    procedures: Procedure[];
    date: Date;
    isFolded: (procedure: Procedure) => boolean;
};

/* HH:MM */
function dateToTimeString(date: Date) {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return `${hour}:${minutes.toString().padStart(2, '0')}`;
}

/* DD.MM.YYYY */
function dateToDayString(date: Date) {
    const day = date.getDate();
    const month = date.getMonth(); /* Month is indexed from 0! */
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}.${(month + 1).toString().padStart(2, '0')}.${year}`;
}

function parseDateTime(date: string, time: string) {
    const value = Date.parse(`${date} ${time}`);
    if (isNaN(value)) {
        return undefined;
    }
    return new Date(value);
}

function dateToXFunction(hourWidth: number, startDate: Date) {
    const ratio = hourWidth / (60 * 60 * 1000);
    return (date: Date) => (date.getTime() - startDate.getTime()) * ratio;
}

interface DateTimeRangeable {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
}

function dateTimeRangeFunction(dateToX: (date: Date) => number) {
    return (value: DateTimeRangeable): undefined | { start: number; end: number; width: number } => {
        const startDate = parseDateTime(value.startDate, value.startTime);
        if (startDate === undefined) {
            return undefined;
        }
        const endDate = parseDateTime(value.endDate, value.endTime);
        if (endDate === undefined) {
            return undefined;
        }
        const start = dateToX(startDate);
        const end = dateToX(endDate);
        const width = end - start;
        return {
            start,
            end,
            width,
        };
    };
}

function procedureStyleClass(procedure: Procedure) {
    switch (procedure.status) {
        case 'PLANNED':
            return styles.DSROverviewTableGraphProcedureItemPlanned;
        case 'COMPLETED':
            return styles.DSROverviewTableGraphProcedureItemCompleted;
        case 'RUNNING':
            return styles.DSROverviewTableGraphProcedureItemToBeRunning;
        case 'TO_BE_ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemToBeAccepted;
        case 'ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemAccepted;
        case 'ERROR':
            return styles.DSROverviewTableGraphProcedureItemError;
        case 'REJECTED':
            return styles.DSROverviewTableGraphProcedureItemRejected;
        case 'TERMINATED':
            return styles.DSROverviewTableGraphProcedureItemTerminated;
        default:
            return styles.DSROverviewTableGraphProcedureItemUndefined;
    }
}

function procedureItemStyleClass(item: Item) {
    switch (item.status) {
        case 'PLANNED':
            return styles.DSROverviewTableGraphProcedureItemPlanned;
        case 'COMPLETED':
            return styles.DSROverviewTableGraphProcedureItemCompleted;
        case 'RUNNING':
            return styles.DSROverviewTableGraphProcedureItemToBeRunning;
        case 'TO_BE_ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemToBeAccepted;
        case 'ACCEPTED':
            return styles.DSROverviewTableGraphProcedureItemAccepted;
        case 'REJECTED':
            return styles.DSROverviewTableGraphProcedureItemRejected;
        case 'ERROR':
            return styles.DSROverviewTableGraphProcedureItemError;
        case 'TERMINATED':
            return styles.DSROverviewTableGraphProcedureItemTerminated;
        default:
            return styles.DSROverviewTableGraphProcedureItemUndefined;
    }
}

function controlCodeToLabel(item: Item) {
    switch (item.controlCode) {
        case 2:
            return '+2';
        case -2:
            return '-2';
        case 1:
            return '+1';
        case -1:
            return '-1';
        case 0:
            return '0';
        default:
            return '?';
    }
}

const DSROverviewTableGraph: FunctionComponent<DSROverviewTableGraphProps> = ({ procedures, date, isFolded }) => {
    const daysCount = 2;
    const rowHeight = 24;
    const hourColumnWidth = 20;
    const changeHeight = 9;
    const timeBubbleRadius = 4;
    const timeBubbleHeight = 26;
    const timeBubbleWidth = 39;
    const timeBubbleArrow = 5;
    const dateRowHeight = rowHeight;
    const timeRowHeight = rowHeight;
    const dayWidth = 24 * hourColumnWidth; /* TODO: Implement daylight saving time */
    const rowsCount = procedures
        .map((procedure) => {
            return 1 + (isFolded(procedure) ? 0 : procedure.groups.length);
        })
        .reduce((accumulator, element) => accumulator + element, 0);
    const headerHeight = dateRowHeight + timeRowHeight;

    const canvasWidth = daysCount * dayWidth;
    const canvasHeight = rowsCount * rowHeight + headerHeight + timeBubbleHeight;
    const canvasTop = 0;
    const canvasBottom = canvasHeight;
    const canvasLeft = 0;
    const canvasRight = canvasWidth;

    const baseDate = new Date(date);
    baseDate.setHours(0, 0, 0, 0);

    const dateToX = dateToXFunction(hourColumnWidth, baseDate);
    const xRange = dateTimeRangeFunction(dateToX);

    const currentDate = new Date();
    const currentDateX = dateToX(currentDate);
    const currentDateXInDisplayRange = currentDateX > 0 && currentDateX < canvasWidth;

    return (
        <svg
            className={styles.DSROverviewTableGraph}
            viewBox={`${canvasLeft} ${canvasTop} ${canvasWidth} ${canvasHeight}`}
            height={canvasHeight}
        >
            <symbol id="DSROverviewTableGraphErrorSymbol" width="14" height="14" viewBox="-1 -1 16 16">
                <circle cx="7" cy="7" r="8" fill="#FFFFFF" />
                <circle cx="7" cy="7" r="7" fill="#C9C9C9" />
                <path
                    d="M6.96883 0.332031C3.31016 0.332031 0.333496 3.3227 0.333496 6.9987C0.333496 10.6747 3.32416 13.6654 7.00016 13.6654C10.6762 13.6654 13.6668 10.6747 13.6668 6.9987C13.6668 3.3227 10.6622 0.332031 6.96883 0.332031ZM7.66683 10.332H6.3335V8.9987H7.66683V10.332ZM7.66683 7.66536H6.3335V3.66536H7.66683V7.66536Z"
                    fill="#B83E4D"
                />
            </symbol>
            <rect
                className={styles.DSROverviewTableGraphDatesBackground}
                x={canvasLeft}
                y={timeBubbleHeight}
                width={canvasWidth}
                height={dateRowHeight}
            />
            <rect
                className={styles.DSROverviewTableGraphHoursBackground}
                x={canvasLeft}
                y={timeBubbleHeight + dateRowHeight}
                width={canvasWidth}
                height={timeRowHeight}
            />
            {procedures.map((procedure, procedureIndex, procedures) => {
                const procedureXRange = xRange(procedure);
                if (procedureXRange === undefined) {
                    return;
                }

                const row = procedures
                    .slice(0, procedureIndex)
                    .map((procedure) => {
                        return 1 + (isFolded(procedure) ? 0 : procedure.groups.length);
                    })
                    .reduce((a, b) => a + b, 0);

                const completedItems = procedure.groups
                    .flatMap((group) => group.items)
                    .filter((item) => item.status === 'COMPLETED')
                    .map(xRange);

                return (
                    <g key={procedure.id}>
                        <rect
                            className={procedureStyleClass(procedure)}
                            x={procedureXRange.start}
                            y={timeBubbleHeight + headerHeight + rowHeight * row}
                            width={procedureXRange.width}
                            height={rowHeight}
                        />
                        {completedItems.map((range, index) =>
                            range !== undefined ? (
                                <rect
                                    className={
                                        procedure.status === 'TERMINATED' ||
                                        ((procedure.status === 'PLANNED' || procedure.status === 'TO_BE_ACCEPTED') &&
                                            procedure.procedureType === 'EMERGENCY')
                                            ? procedureStyleClass(procedure)
                                            : styles.DSROverviewTableGraphProcedurePartialyCompleted
                                    }
                                    key={index}
                                    x={range.start}
                                    y={timeBubbleHeight + headerHeight + rowHeight * row}
                                    width={range.width}
                                    height={rowHeight}
                                />
                            ) : null,
                        )}
                        {procedure.procedureChanges.map((change, index) => {
                            const procedureChangeXRange = xRange(change);
                            if (procedureChangeXRange === undefined) {
                                return;
                            }
                            return (
                                <rect
                                    className={
                                        change.controlCode > 0
                                            ? styles.DSROverviewTableGraphProcedureChangePositive
                                            : styles.DSROverviewTableGraphProcedureChangeNegative
                                    }
                                    key={index}
                                    x={procedureChangeXRange.start}
                                    y={timeBubbleHeight + headerHeight + rowHeight * row + rowHeight - changeHeight}
                                    width={procedureChangeXRange.width}
                                    height={changeHeight}
                                />
                            );
                        })}
                        {!isFolded(procedure) &&
                            procedure.groups.map((group, groupIndex) => (
                                <React.Fragment key={`Group${group.id}`}>
                                    {group.items.map((item, itemIndex, items) => {
                                        const itemXRange = xRange(item);
                                        if (itemXRange === undefined) {
                                            return;
                                        }
                                        const itemRow = row + groupIndex + 1;
                                        return (
                                            <React.Fragment key={`Item${item.id}`}>
                                                <rect
                                                    className={procedureItemStyleClass(item)}
                                                    rx="2"
                                                    ry="2"
                                                    x={itemXRange.start}
                                                    y={timeBubbleHeight + headerHeight + rowHeight * itemRow + 2}
                                                    width={itemXRange.width + (items.length == itemIndex + 1 ? 0 : -2)}
                                                    height={rowHeight - 4}
                                                />
                                                <text
                                                    className={styles.DSROverviewTableGraphItemControlCode}
                                                    x={itemXRange.start + itemXRange.width * 0.5}
                                                    y={
                                                        timeBubbleHeight +
                                                        headerHeight +
                                                        rowHeight * itemRow +
                                                        rowHeight * 0.5
                                                    }
                                                    dominantBaseline="middle"
                                                    textAnchor="middle"
                                                >
                                                    {controlCodeToLabel(item)}
                                                </text>
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                    </g>
                );
            })}
            {range(daysCount).map((dayIndex) => {
                const dayXOffset = dayIndex * dayWidth;
                const dayLabel = dateToDayString(day.add(baseDate, dayIndex));
                return (
                    <React.Fragment key={`DayIndex${dayIndex}`}>
                        <text
                            className={styles.DSROverviewTableGraphDate}
                            x={dayXOffset + 0.5 * dayWidth}
                            y={timeBubbleHeight + dateRowHeight * 0.5}
                            dominantBaseline="middle"
                            textAnchor="middle"
                        >
                            {dayLabel}
                        </text>
                        <line
                            className={styles.DSROverviewTableGraphVerticaDateLine}
                            x1={dayXOffset}
                            y1={timeBubbleHeight}
                            x2={dayXOffset}
                            y2={canvasBottom}
                        />
                        {range(24).map((hour) => (
                            <React.Fragment key={`Hour${hour}`}>
                                <text
                                    className={styles.DSROverviewTableGraphHour}
                                    x={dayXOffset + hourColumnWidth * 0.5 + hour * hourColumnWidth}
                                    y={timeBubbleHeight + dateRowHeight + timeRowHeight * 0.5}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {hour}
                                </text>
                                <line
                                    className={styles.DSROverviewTableGraphVerticalLine}
                                    x1={dayXOffset + hourColumnWidth * hour}
                                    y1={timeBubbleHeight + dateRowHeight}
                                    x2={dayXOffset + hourColumnWidth * hour}
                                    y2={canvasBottom}
                                />
                                {rowsCount > 0 && (
                                    <rect
                                        className={styles.DSROverviewTableGraphDayHover}
                                        x={dayXOffset + hour * hourColumnWidth}
                                        y={timeBubbleHeight + dateRowHeight + timeRowHeight}
                                        width={hourColumnWidth}
                                        height={canvasHeight - headerHeight - timeBubbleHeight}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            })}
            {currentDateXInDisplayRange && (
                <g>
                    <line
                        className={styles.DSROverviewTableGraphTime}
                        x1={currentDateX}
                        y1={timeBubbleHeight}
                        x2={currentDateX}
                        y2={canvasBottom}
                    />
                    <rect
                        className={styles.DSROverviewTableGraphTimeBubble}
                        x={currentDateX - 0.5 * timeBubbleWidth}
                        y={0}
                        width={timeBubbleWidth}
                        height={timeBubbleHeight - timeBubbleArrow}
                        rx={timeBubbleRadius}
                        ry={timeBubbleRadius}
                    />
                    <path
                        className={styles.DSROverviewTableGraphTimeBubble}
                        d={`M${currentDateX} ${timeBubbleHeight} l ${-timeBubbleArrow} ${-timeBubbleArrow} h ${
                            2 * timeBubbleArrow
                        }`}
                    />
                    <text
                        className={styles.DSROverviewTableGraphTimeText}
                        x={currentDateX}
                        y={0.5 * (timeBubbleHeight - timeBubbleArrow)}
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {dateToTimeString(currentDate)}
                    </text>
                </g>
            )}
            {range(rowsCount).map((index) => {
                const y = timeBubbleHeight + headerHeight + rowHeight * (index + 1) - 0.5;
                return (
                    <line
                        className={styles.DSROverviewTableGraphHorizontalLine}
                        key={index}
                        x1={canvasLeft}
                        y1={y}
                        x2={canvasRight}
                        y2={y}
                    />
                );
            })}
            {procedures.map((procedure, procedureIndex, procedures) => {
                const procedureXRange = xRange(procedure);
                if (procedureXRange === undefined) {
                    return;
                }

                const row = procedures
                    .slice(0, procedureIndex)
                    .map((procedure) => {
                        return 1 + (isFolded(procedure) ? 0 : procedure.groups.length);
                    })
                    .reduce((a, b) => a + b, 0);

                return (
                    <React.Fragment key={`error-${procedure.id}`}>
                        {procedure.historyMessages.some((message) => message.messageType === 'ERROR') && (
                            <use
                                xlinkHref="#DSROverviewTableGraphErrorSymbol"
                                x={procedureXRange.start + 0.5 * procedureXRange.width - 7}
                                y={timeBubbleHeight + headerHeight + rowHeight * row + 1.33}
                                className={styles.DSROverviewTableErrorIcon}
                            >
                                <title>
                                    {procedure.historyMessages.map((message, index) => {
                                        return (
                                            <React.Fragment key={`${procedure.id}-${index}`}>
                                                {message.messageType === 'ERROR' && (
                                                    <>
                                                        {message.text}
                                                        {'\n'}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </title>
                            </use>
                        )}
                    </React.Fragment>
                );
            })}
        </svg>
    );
};

type DSROverviewTableActionsProps = {
    procedure: Procedure;
    date: Date;
    triggerModal: (action: 'reject' | 'terminate') => void;
};

const DSROverviewTableActions: FunctionComponent<DSROverviewTableActionsProps> = ({
    procedure,
    date,
    triggerModal,
}) => {
    const { open, anchor, handleOpen, handleClose } = usePopover();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector<{ userInfo: UserInfoState }, UserInfo | null>((state) => state.userInfo.user);

    const hasCredential = user && (user.roles.includes('DYSPOZYTOR') || user.roles.includes('DIR'));

    const handleSelection = (action: 'accept' | 'reject' | 'terminate') => {
        handleClose();
        switch (action) {
            case 'accept':
                dispatch(
                    handleProcedureStatusChange({
                        id: procedure.id,
                        message: '',
                        type: 'accept',
                        date: date,
                    }),
                );
                break;
            case 'reject':
            case 'terminate':
                triggerModal(action);
        }
    };

    return hasCredential && procedure.availableActions.length > 0 ? (
        <>
            <button
                className={styles.DSROverviewTableProcedureHeaderMenu}
                type="button"
                aria-describedby={`procedureActions${procedure.id}`}
                onClick={handleOpen}
            >
                Zarządzaj
            </button>
            <Popover
                id={`procedureActions${procedure.id}`}
                opened={open}
                anchor={anchor}
                onClose={handleClose}
                anchorPos="bottom"
                backgroundColor={colors.snowWhite}
            >
                <Box display="flex" flexDirection="column" padding={spacing.xxxxs}>
                    {procedure.availableActions.includes('accept') ? (
                        <Button
                            size="s"
                            justifyContent="start"
                            status="plain"
                            leftIcon={IconCheck}
                            onClick={() => handleSelection('accept')}
                        >
                            {'Zaakceptuj procedurę'}
                        </Button>
                    ) : null}
                    {procedure.availableActions.includes('reject') ? (
                        <Button
                            size="s"
                            justifyContent="start"
                            status="plain"
                            leftIcon={IconTrash}
                            onClick={() => handleSelection('reject')}
                        >
                            {'Odrzuć procedurę'}
                        </Button>
                    ) : null}
                    {procedure.availableActions.includes('terminate') ? (
                        <Button
                            size="s"
                            justifyContent="start"
                            status="plain"
                            leftIcon={IconTrash}
                            onClick={() => handleSelection('terminate')}
                        >
                            {'Zatrzymaj procedurę'}
                        </Button>
                    ) : null}
                </Box>
            </Popover>
        </>
    ) : null;
};

export const DSROverviewTable: FunctionComponent<DSROverviewTableProps> = (props: DSROverviewTableProps) => {
    const [folded, setFolded] = useState<Array<number>>([]);
    const [procedureDetailsOpened, setProcedureDetailsOpened] = React.useState<null | number>(null);
    const [modalStepForced, setModalStepForced] = React.useState<'terminate' | 'reject' | null>(null);

    const dispatch = useDispatch<AppDispatch>();

    const isFolded = (procedure: Procedure) => {
        return folded.includes(procedure.id);
    };

    const toggleFold = (procedure: Procedure) => {
        if (isFolded(procedure)) {
            setFolded(folded.filter((value) => value !== procedure.id));
        } else {
            setFolded([...folded, procedure.id]);
        }
    };

    const handleModal = (procedureId: number | null, stepForced?: 'terminate' | 'reject') => {
        setProcedureDetailsOpened(procedureId);
        if (stepForced) {
            setModalStepForced(stepForced);
            // Clearing forcedStep immediatelly after modal is triggered for terminationConsequences to be shown after submit
            setTimeout(() => {
                setModalStepForced(null);
            }, 200);
        }
        if (!procedureId) {
            setModalStepForced(null);
            dispatch(clearDetails());
        }
    };

    const allowedProcedures = props.procedures.filter((procedure) => {
        const startDate = new Date(props.date);
        startDate.setHours(0, 0, 0, 0);

        const endDate = day.add(props.date, 2);

        const procedureStartDate = new Date(`${procedure.startDate} ${procedure.startTime}`);
        const procedureEndDate = new Date(`${procedure.endDate} ${procedure.endTime}`);

        const startWithin = procedureStartDate > startDate && procedureStartDate < endDate;
        const endWithin = procedureEndDate > startDate && procedureEndDate < endDate;

        return startWithin || endWithin;
        // return true;
    });

    return (
        <div className={styles.DSROverviewTable}>
            <div className={styles.DSROverviewTableProcedures}>
                {allowedProcedures.map((procedure) => (
                    <div key={procedure.id} className={styles.DSROverviewTableProcedure}>
                        <div className={styles.DSROverviewTableProcedureHeader}>
                            <div className={styles.DSROverviewTableProcedureHeaderNameContainer}>
                                <button
                                    className={styles.DSROverviewTableProcedureHeaderName}
                                    type="button"
                                    onClick={() => toggleFold(procedure)}
                                >
                                    {isFolded(procedure) ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={styles.DSROverviewTableProcedureHeaderNameChervon}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M12 7.59998L10.6 8.99998L5.3 14.3L6.7 15.7L12 10.4L17.3 15.7L18.7 14.3L13.4 8.99998L12 7.59998Z"
                                                fill="#41414A"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={styles.DSROverviewTableProcedureHeaderNameChervon}
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M17.3 8.29999L12 13.6L6.69999 8.29999L5.29999 9.69999L12 16.4L18.7 9.69999L17.3 8.29999Z"
                                                fill="#41414A"
                                            />
                                        </svg>
                                    )}
                                </button>
                                <button
                                    onClick={() => handleModal(procedure.id)}
                                    className={styles.DSROverviewTableProcedureHeaderName}
                                    color={colors.forestGreen}
                                >
                                    {procedure.name}
                                </button>
                            </div>
                            <DSROverviewTableActions
                                procedure={procedure}
                                date={props.date}
                                triggerModal={(action) => handleModal(procedure.id, action)}
                            />
                        </div>
                        {!isFolded(procedure) &&
                            procedure.groups.map((group) => (
                                <div key={group.id} className={styles.DSROverviewTableProcedureGroup}>
                                    {group.name}
                                </div>
                            ))}
                        {procedureDetailsOpened && procedureDetailsOpened === procedure.id && (
                            <ProcedureDetailsModal
                                id={procedure.id}
                                date={props.date}
                                onClose={() => handleModal(null)}
                                reasonForced={modalStepForced}
                            />
                        )}
                    </div>
                ))}
            </div>
            <DSROverviewTableGraph procedures={allowedProcedures} isFolded={isFolded} date={props.date} />
        </div>
    );
};
