import React, { FunctionComponent } from 'react';
import moment from 'moment/moment';

import { ForecaIcon } from 'src/components/ForecaIcon/ForecaIcon';
import { Weather } from 'src/api/model/Weather';

import styles from './WeatherTable.module.scss';

type WeatherTableProps = {
    data: Weather[];
};

const formatNumber = (number: number, fractionDigits: number) => {
    return new Intl.NumberFormat('pl-PL', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number);
};

const WeatherTable: FunctionComponent<WeatherTableProps> = ({ data }: WeatherTableProps) => {
    const dates: string[] = [];
    const filteredData: Weather[][] = [];
    data.forEach((el) => {
        if (!dates.includes(el.date)) {
            dates.push(el.date);
        }
    });
    dates.forEach((date) => {
        filteredData.push(data.filter((el) => el.date === date));
    });

    return (
        <>
            <div className={styles.WT_container}>
                <table className={styles.WT}>
                    <thead>
                        <tr>
                            <th className={styles.WTColFirst} rowSpan={2}>
                                Godzina
                            </th>
                            <th className={styles.WTColCity} colSpan={4}>
                                <div className={styles.WTColBrake}></div>
                                <span>Częstochowa</span>
                            </th>
                            <th className={styles.WTColCity} colSpan={4}>
                                <div className={styles.WTColBrake}></div>
                                <span>Płock</span>
                            </th>
                            <th className={styles.WTColCity} colSpan={4}>
                                <div className={styles.WTColBrake}></div>
                                <span>Wrocław</span>
                            </th>
                        </tr>
                        <tr>
                            <th className={styles.WTColSmallWithBrake}>
                                <div className={styles.WTColBrake}></div>
                                <div className={`${styles.WTHeadSource} ${styles.content}`}>Prognoza</div>
                                <div className={styles.content}>Temperatura powietrza</div>
                                <div className={`${styles.WTHeadAddDesc} ${styles.content}`}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Prognoza</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>Temperatura powietrza</div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmallWithBrake}>
                                <div className={styles.WTColBrake}></div>
                                <div className={`${styles.WTHeadSource} ${styles.content}`}>Prognoza</div>
                                <div className={styles.content}>Temperatura powietrza</div>
                                <div className={`${styles.WTHeadAddDesc} ${styles.content}`}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Prognoza</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>Temperatura powietrza</div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmallWithBrake}>
                                <div className={styles.WTColBrake}></div>
                                <div className={`${styles.WTHeadSource} ${styles.content}`}>Prognoza</div>
                                <div className={styles.content}>Temperatura powietrza</div>
                                <div className={`${styles.WTHeadAddDesc} ${styles.content}`}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Prognoza</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>Temperatura powietrza</div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                            <th className={styles.WTColSmall}>
                                <div className={styles.WTHeadSource}>Zmierzona</div>
                                <div>
                                    Prędkość
                                    <br />
                                    wiatru
                                </div>
                                <div className={styles.WTHeadAddDesc}>(Foreca)</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((d) => {
                            const date = d[0].date;
                            return (
                                <React.Fragment key={date}>
                                    <tr className={styles.WTDayHead}>
                                        <td>{moment(date).format('DD.MM.YYYY')}</td>
                                        {d.map((n) => {
                                            const actual = n.weather.temperatureActual;
                                            const forecasted = n.weather.temperatureForecasted;
                                            return (
                                                <React.Fragment key={`${n.date} ${n.name}`}>
                                                    <td
                                                        colSpan={2}
                                                        className={`${styles.WTColLeft} ${styles.WTColWithBrake}`}
                                                    >
                                                        <div className={styles.WTColBrake}></div>
                                                        <span>{`średnia ${
                                                            forecasted.average && forecasted.unit
                                                                ? `${formatNumber(forecasted.average, 1)} ${
                                                                      forecasted.unit
                                                                  }`
                                                                : 'brak'
                                                        }`}</span>
                                                    </td>
                                                    <td colSpan={2} className={styles.WTColLeft}>
                                                        <span>{`średnia ${
                                                            actual.average && actual.unit
                                                                ? `${formatNumber(actual.average, 1)} ${actual.unit}`
                                                                : 'brak'
                                                        }`}</span>
                                                    </td>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                    {[...Array(24)].map((n, index) => {
                                        return (
                                            <tr key={`${date}_${index}`}>
                                                <td>{`${index}:00 - ${index + 1}:00`}</td>
                                                {d.map((n) => {
                                                    const tempActual = n.weather.temperatureActual;
                                                    const tempActualValueObj = tempActual.values.find(
                                                        (el) => moment(el.dateTime).hour() === index,
                                                    );
                                                    const symbolActual = tempActualValueObj
                                                        ? tempActualValueObj.symbol
                                                        : null;
                                                    const tempActualValue = tempActualValueObj
                                                        ? tempActualValueObj.value
                                                        : null;
                                                    const tempActualUnit = tempActual.unit;
                                                    const windActual = n.weather.windSpeedActual;
                                                    const windActualValueObj = windActual.values.find(
                                                        (el) => moment(el.dateTime).hour() === index,
                                                    );
                                                    const windActualValue = windActualValueObj
                                                        ? windActualValueObj.value
                                                        : null;
                                                    const windActualUnit = windActual.unit;
                                                    const tempForecasted = n.weather.temperatureForecasted;
                                                    const symbolForecasted =
                                                        tempForecasted.values[index].symbol !== undefined
                                                            ? tempForecasted.values[index].symbol
                                                            : null;
                                                    const tempForecastedValue =
                                                        tempForecasted.values[index].value !== undefined
                                                            ? tempForecasted.values[index].value
                                                            : null;
                                                    const tempForecastedUnit = tempForecasted.unit;
                                                    const windForecasted = n.weather.windSpeedForecasted;
                                                    const windForecastedValue =
                                                        windForecasted.values[index].value !== undefined
                                                            ? windForecasted.values[index].value
                                                            : null;
                                                    const windForecastedUnit = windForecasted.unit;
                                                    return (
                                                        <React.Fragment key={`${n.date} ${n.name}`}>
                                                            <td>
                                                                <div className={styles.WTColBrake}></div>
                                                                <div className={styles.WTWeatherContainer}>
                                                                    {symbolForecasted && (
                                                                        <ForecaIcon symbol={symbolForecasted} />
                                                                    )}
                                                                    <div>
                                                                        {tempForecastedValue !== null &&
                                                                        tempForecastedUnit
                                                                            ? `${formatNumber(
                                                                                  tempForecastedValue,
                                                                                  1,
                                                                              )} ${tempForecastedUnit}`
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {windForecastedValue !== null && windForecastedUnit
                                                                    ? `${windForecastedValue} ${windForecastedUnit}`
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                <div className={styles.WTWeatherContainer}>
                                                                    {symbolActual && (
                                                                        <ForecaIcon symbol={symbolActual} />
                                                                    )}
                                                                    <div>
                                                                        {tempActualValue !== null && tempActualUnit
                                                                            ? `${formatNumber(
                                                                                  tempActualValue,
                                                                                  1,
                                                                              )} ${tempActualUnit}`
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {windActualValue !== null && windActualUnit
                                                                    ? `${formatNumber(
                                                                          windActualValue,
                                                                          0,
                                                                      )} ${windActualUnit}`
                                                                    : ''}
                                                            </td>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default WeatherTable;
