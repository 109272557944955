import { Table } from '@fortum/elemental-ui';
import React, { FunctionComponent } from 'react';

import styles from './HistoryTable.module.scss';
import { HistoryTableProps } from './HistoryTable.types';
import { historyTableColumns } from './HistoryTableColumns';

export const HistoryTable: FunctionComponent<HistoryTableProps> = ({ tableData }: HistoryTableProps) => {
    return (
        <Table
            className={styles.historyTable}
            columns={historyTableColumns}
            rows={tableData}
            getRowKey={(row) => row.id}
        />
    );
};
