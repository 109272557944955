import { ContentText, colors, Float, Notification, IconInfo, Link } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/providers/store';

import { clearToast } from './Toast.slice';

import { ToastState } from './Toast.types';

const TOAST_VISIBILITY_SECONDS = '4';

export const Toast: FunctionComponent = () => {
    const dispatch = useDispatch<AppDispatch>();

    const toastText = useSelector<ToastState>((state) => (state.toast ? state.toast.toastText : null)) as string | null;
    const linkText = useSelector<ToastState>((state) => (state.toast ? state.toast.toastLinkCopy : null)) as
        | string
        | null;
    const linkRoute = useSelector<ToastState>((state) => (state.toast ? state.toast.toastLinkTarget : null)) as
        | string
        | null;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (toastText) {
                dispatch(clearToast());
            }
        }, parseInt(TOAST_VISIBILITY_SECONDS, 10) * 1000 || 3000);

        return () => clearTimeout(timeout);
    }, [toastText]);

    const closeToast = () => {
        dispatch(clearToast());
    };

    const handleLinkClick = () => {
        console.log(linkRoute); // TODO: handle routing
    };

    return (
        <>
            <Float opened={!!toastText} anchorPos={'topRight'}>
                <Notification
                    backgroundColor={colors.cushyBlue}
                    icon={IconInfo}
                    onClose={closeToast}
                    opened={!!toastText}
                >
                    <ContentText color={colors.snowWhite}>{toastText}</ContentText>
                    {linkText && linkRoute && (
                        <Link color={colors.snowWhite} onClick={handleLinkClick}>
                            {linkText}
                        </Link>
                    )}
                    {linkText && !linkRoute && <ContentText color={colors.snowWhite}>{linkText}</ContentText>}
                </Notification>
            </Float>
        </>
    );
};
