import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from 'src/api/requests';
import { logger } from 'src/utils';

export type UserInfoState = {
    user: UserInfo | null;
};

export type UserInfo = {
    firstName: string;
    lastName: string;
    roles: string[];
};

const user = localStorage.getItem('userInfo');

const initialState: UserInfoState = {
    user: user && JSON.parse(user),
};

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (): Promise<UserInfo | undefined> => {
    try {
        logger.log('[TODO] try to getUserInfo');
        return await getUserInfo();
    } catch {
        // handle error
        logger.error('[TODO] getUserInfo failed');
    }
});

const userInfoSlice = createSlice({
    name: 'userinfo',
    initialState,
    reducers: {
        clearUserInfo(state) {
            state.user = null;
            localStorage.removeItem('userInfo');
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            if (action.payload) {
                state.user = action.payload;
                localStorage.setItem('userInfo', JSON.stringify(action.payload));
            }
        });
    },
});

export const { clearUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
