import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardMap } from 'src/api/requests';
import { logger } from 'src/utils';
import { Map } from 'src/api/model/Map';

const fetchMapPayloadCreator = async (): Promise<Map | undefined> => {
    try {
        logger.log('[TODO] try to getDashboardMap');
        return await getDashboardMap();
    } catch {
        // handle error
        logger.error('[TODO] getDashboardMap failed');
    }
};

const fetchDashboardMap = createAsyncThunk('map/get', fetchMapPayloadCreator);
export { fetchDashboardMap };
