import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardGraph } from 'src/api/requests';
import { logger } from 'src/utils';
import { Graph } from 'src/api/model/Graph';

const fetchGraphPayloadCreator = async (): Promise<Graph | undefined> => {
    try {
        logger.log('[TODO] try to getDashboardGraph');
        return await getDashboardGraph();
    } catch {
        // handle error
        logger.error('[TODO] getDashboardGraph failed');
    }
};

const fetchDashboardGraph = createAsyncThunk('graph/get', fetchGraphPayloadCreator);
export { fetchDashboardGraph };
