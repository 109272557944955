import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Gap, Loader, Radio, spacing, useEventTargetValue } from '@fortum/elemental-ui';
// import { NetworkStatusMap } from 'src/components/NetworkStatusMap/NetworkStatusMap';

import { AppDispatch } from 'src/providers/store';

import { GraphData, GraphState } from 'src/containers/ProductionPlans/ProductionPlans.types';
import { MapData, MapState } from 'src/containers/NetworkStatus/NetworkStatus.types';

import { fetchDashboardMap } from 'src/containers/NetworkStatus/NetworkStatus.thunk';
import { NetworkStatusGoogleMap } from 'src/components/NetworkStatusGoogleMap/NetworkStatusGoogleMap';

const NetworkStatus: FunctionComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const graphState = useSelector<GraphState>((state) => state.graphData) as GraphData;
    const mapState = useSelector<MapState>((state) => state.mapData) as MapData;
    const [selectedMap, handleMapChange] = useEventTargetValue('network');

    useEffect(() => {
        getMapData();
        const intervalId = setInterval(getMapData, 900000);
        return () => clearInterval(intervalId);
    }, []);

    const getMapData = () => {
        dispatch(fetchDashboardMap());
    };

    return (
        <Box>
            <Box marginTop={spacing.xs}>
                <Gap size={spacing.xs}>
                    <Radio
                        name="map"
                        checked={selectedMap === 'network'}
                        value="network"
                        label="Stan sieci"
                        onChange={handleMapChange}
                    />
                    <Radio
                        name="date"
                        checked={selectedMap === 'dsr'}
                        value="dsr"
                        label="DSR"
                        onChange={handleMapChange}
                    />
                </Gap>
            </Box>
            {graphState.loading || mapState.loading ? (
                <Box marginTop={spacing.xl} marginBottom={spacing.m} display="flex" justifyContent="center">
                    <Loader />
                </Box>
            ) : (
                <Box marginTop={spacing.m}>
                    {graphState.graph && mapState.map && (
                        <NetworkStatusGoogleMap
                            selectedMap={selectedMap}
                            graph={graphState.graph}
                            mapData={mapState.map}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default NetworkStatus;
