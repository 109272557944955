import { Box, Col, Grid, Heading, Row, spacing } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ProductionPlans from 'src/containers/ProductionPlans/ProductionPlans';
import NetworkStatus from 'src/containers/NetworkStatus/NetworkStatus';
import { Footer } from 'src/components/Footer/Footer';
import { Toast } from 'src/components/Toast/Toast';
import Header from 'src/components/Header/Header';
import { fetchDashboardGraph } from 'src/containers/ProductionPlans/ProductionPlans.thunk';
import { AppDispatch } from 'src/providers/store';

const Dashboard: FunctionComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        getGraphData();
        const intervalId = setInterval(getGraphData, 900000);
        return () => clearInterval(intervalId);
    }, []);

    const getGraphData = () => {
        dispatch(fetchDashboardGraph());
    };

    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Header activePage={'Pulpit'} />
                <Grid flex="1 0 auto">
                    <Row marginTop={spacing.xxs} marginBottom={spacing.xxs}>
                        <Col l={4.5}>
                            <Box>
                                <Heading level={2} weight={700} style={{ fontSize: 24 }}>
                                    Aktualny stan sieci
                                </Heading>
                                <NetworkStatus />
                            </Box>
                        </Col>
                        <Col l={7.5}>
                            <Box>
                                <Heading level={2} weight={700} style={{ fontSize: 24 }}>
                                    Plany produkcji i realizacja
                                </Heading>
                                <ProductionPlans />
                            </Box>
                        </Col>
                    </Row>
                </Grid>
                <Footer />
            </Box>
            <Toast />
        </>
    );
};

export default Dashboard;
