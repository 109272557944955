import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { getWeather, downloadWeather } from 'src/api/requests';
import { logger } from 'src/utils';
import { Weather } from 'src/api/model/Weather';

const fetchWeather = createAsyncThunk('weather/get', async (date?: Date): Promise<Weather[] | undefined> => {
    try {
        logger.log('[TODO] try to getWeather');
        return await getWeather(date ? moment(date).format('YYYY-MM-DD') : undefined);
    } catch {
        // handle error
        logger.error('[TODO] getWeather failed');
    }
});

const exportWeather = createAsyncThunk('weather/download', async (date: Date): Promise<void | undefined> => {
    try {
        logger.log('[TODO] try to downloadWeather');
        await downloadWeather(moment(date).format('YYYY-MM-DD')).then((response: BlobPart) => {
            const blob = new Blob([response], { type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64' });
            const fileURL = window.URL.createObjectURL(blob);
            const alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = `weather_${moment().format('YYYY-MM-DD_HH_mm')}.xlsx`;
            alink.click();
        });
    } catch {
        // handle error
        logger.error('[TODO] downloadWeather failed');
    }
});

export { fetchWeather, exportWeather };
