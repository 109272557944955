import { IconChevronUp } from '@fortum/elemental-ui';
import React, { FunctionComponent } from 'react';

import styles from './Footer.module.scss';

export const Footer: FunctionComponent = () => (
    <footer className={styles.root}>
        <div className={styles.container}>
            <span className={styles.info}>© 2021 Fortum. All Rights Reserved.</span>
            <nav className={styles.navigation}>
                <button
                    className={styles.up}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    Do góry
                    <IconChevronUp className={styles.upIcon} />
                </button>
            </nav>
        </div>
    </footer>
);
