import React, { FunctionComponent } from 'react';
import { Box, Breadcrumb, Col, Grid, Row, spacing } from '@fortum/elemental-ui';
import HeatProductionPlansContainer from 'src/containers/HeatProductionPlansContainer/HeatProductionPlansContainer';
import { Footer } from 'src/components/Footer/Footer';
import { Toast } from 'src/components/Toast/Toast';
import Header from 'src/components/Header/Header';

const HeatProductionPlans: FunctionComponent = () => {
    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Header activePage={'Plany produkcji ciepła'} />
                <Grid flex="1 0 auto">
                    <Row marginTop={spacing.xxs} marginBottom={spacing.xxxs}>
                        <Col l={12}>
                            <Breadcrumb
                                aria-label="Nawigacja"
                                items={[
                                    { title: 'Pulpit', params: { href: '/' } },
                                    { title: 'Plan produkcji ciepła' },
                                ]}
                            />
                        </Col>
                    </Row>
                    <HeatProductionPlansContainer />
                </Grid>
                <Footer />
            </Box>
            <Toast />
        </>
    );
};

export default HeatProductionPlans;
