import React, { FunctionComponent } from 'react';
import { ReactComponent as ArrowUp } from 'src/assets/arrows/arrowUp.svg';
import { ReactComponent as ArrowDown } from 'src/assets/arrows/arrowDown.svg';
import { ReactComponent as DoubleArrowUp } from 'src/assets/arrows/doubleArrowUp.svg';
import { ReactComponent as DoubleArrowDown } from 'src/assets/arrows/doubleArrowDown.svg';

type VariationIconProps = {
    value: number;
};

export const VariationIcon: FunctionComponent<VariationIconProps> = ({ value }: VariationIconProps) => {
    if (value > 0) {
        if (value > 5) {
            return <DoubleArrowUp />;
        } else {
            return <ArrowUp />;
        }
    } else if (value < 0) {
        if (value < -5) {
            return <DoubleArrowDown />;
        } else {
            return <ArrowDown />;
        }
    }

    return null;
};
