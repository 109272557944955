export function add(date: Date, days: number): Date {
    const value = new Date(date);
    value.setHours(4, 0, 0, 0);
    value.setUTCDate(value.getUTCDate() + days);
    value.setHours(0, 0, 0, 0);
    return value;
}

export function previous(date: Date): Date {
    return add(date, -1);
}

export function next(date: Date): Date {
    return add(date, 1);
}
