import { Box, Col, Grid, Row, Button } from '@fortum/elemental-ui';
import React from 'react';
import { loginURL } from 'src/auth';

const Unauthorized: React.FunctionComponent = () => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" minHeight="100vh">
            <Grid>
                <Row>
                    <Col xxs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <Button tag="a" href={loginURL}>
                                Zaloguj
                            </Button>
                        </Box>
                    </Col>
                </Row>
            </Grid>
        </Box>
    );
};

export default Unauthorized;
